import React from "react"

import Cookies from "components/Cookies"
import Header from "components/NewUI/Header"

const AuthLayout: React.FC<{
	children?: React.ReactNode
}> = ({ children }) => (
	<>
		<Header />

		<div className="layout--auth">{children}</div>

		<Cookies />
	</>
)

export default AuthLayout

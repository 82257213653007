import React, { memo } from "react";

const User = () => (
	<svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M5.834 7.917a4.167 4.167 0 118.333 0 4.167 4.167 0 01-8.333 0z"
			fill="#fff"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M10 .833a9.167 9.167 0 100 18.334A9.167 9.167 0 0010 .833zM2.5 10a7.5 7.5 0 1113.219 4.853 4.159 4.159 0 00-3.218-1.52h-5a4.159 4.159 0 00-3.218 1.52A7.47 7.47 0 012.5 10z"
			fill="#fff"
		/>
	</svg>
);

export default memo(User);

import React from "react";
import { observer } from "mobx-react-lite";
import { useIntl } from "react-intl";

import InternalLink from "components/InternalLink";
import { routes } from "constants/routing";
import commonMessages from "messages/common";
import styles from "components/NewUI/Terminal/FunctionalPanel/AuthenticateButtons/index.module.scss";

const AuthenticateButtons: React.FC = () => {
	const { formatMessage } = useIntl();

	return (
		<div className={styles.authenticateButtons}>
			{/* Registration button */}
			<InternalLink className={styles.authenticateButtons__button} to={routes.register.root}>
				{formatMessage(commonMessages.register)}
			</InternalLink>

			{/* Login button */}
			<InternalLink
				className={`${styles.authenticateButtons__button} ${styles.authenticateButtons__button___signIn}`}
				to={routes.login.root}
			>
				{formatMessage(commonMessages.login)}
			</InternalLink>
		</div>
	);
};

export default observer(AuthenticateButtons);

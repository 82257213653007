import React, { FC, memo } from "react"

import styles from "components/NewUI/Header/QRCode/index.module.scss"
import QRCodeIcon from "assets/icons/header/QRCode"
import config from "../../../../helpers/config"
import AppleBtnImg from "../../../../assets/images/common/app-store.svg"
import GoogleBtnImg from "../../../../assets/images/common/google-play.svg"
import { useMst } from "../../../../models/Root"
import ExternalLink from "../../../ExternalLink"

const QRCode: FC<{ disabled?: boolean }> = ({ disabled = false }) => {
	const { render } = useMst()

	return (
		<div className={`${styles.QRCode} ${disabled ? styles.QRCode___disabled : ""}`}>
			<button
				type="button"
				className={styles.QRCode__button}
				onClick={() => {
					console.log("QRCode")
				}}
				disabled
				aria-label="QR code"
			>
				<QRCodeIcon />
			</button>

			<div className={styles.QRCode__modal}>
				<div className={styles.QRCode__field} />

				<p className={styles.QRCode__modalText}>Scan to Download App</p>

				{render.mobileApp ? (
					<div className={styles.QRCode__apps}>
						<ExternalLink className={styles.QRCode__appLink} to={config.mobileAppAppStore}>
							<img src={AppleBtnImg} alt="logo" width="140" height="50" />
						</ExternalLink>

						<ExternalLink className={styles.QRCode__appLink} to={config.mobileAppGooglePlay}>
							<img src={GoogleBtnImg} alt="logo" width="140" height="50" />
						</ExternalLink>
					</div>
				) : null}
			</div>
		</div>
	)
}

export default memo(QRCode)

import React, { memo } from "react";

const TradingHistory = () => (
	<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
		<path
			stroke="#fff"
			strokeLinecap="round"
			strokeWidth="1.5"
			d="M17 16.572c-.932 0-1.398 0-1.765-.153a2 2 0 01-1.083-1.082C14 14.969 14 14.503 14 13.572v-3.143c0-.932 0-1.398.152-1.766a2 2 0 011.083-1.082c.367-.152.833-.152 1.765-.152m0 9.143c.932 0 1.398 0 1.765-.153a2 2 0 001.083-1.082c.152-.368.152-.834.152-1.765v-3.143c0-.932 0-1.398-.152-1.766a2 2 0 00-1.083-1.082c-.367-.152-.833-.152-1.765-.152m0 9.143V19.5m0-12.071V4.5M7 18.857c-.932 0-1.398 0-1.765-.152a2 2 0 01-1.083-1.082C4 17.255 4 16.789 4 15.857V8.143c0-.932 0-1.398.152-1.765a2 2 0 011.083-1.083c.367-.152.833-.152 1.765-.152m0 13.714c.932 0 1.398 0 1.765-.152a2 2 0 001.083-1.082c.152-.368.152-.834.152-1.766V8.143c0-.932 0-1.398-.152-1.765a2 2 0 00-1.083-1.083c-.367-.152-.833-.152-1.765-.152m0 13.714V22M7 5.143V2"
		/>
	</svg>
);

export default memo(TradingHistory);

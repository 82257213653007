import ApiClient from "helpers/ApiClient";
import { ISubmitSocialActivityBody } from "components/WelcomeBonusAward/WelcomeBonusAwardForm";
import { ILaunchStatus } from "types/launch";
import { useQuery } from "react-query";

const PromoService = {
	getPromoStatus: (): Promise<void> => ApiClient.get("web/promo/ny22/status"),
	promoPayOut: (): Promise<void> => ApiClient.post("web/promo/ny22/pay-out"),
	submitSocialActivity: (body: ISubmitSocialActivityBody): Promise<void> =>
		ApiClient.post("web/promo/ny22/submit-social-activity", body),
	getLaunchStatus: (): Promise<ILaunchStatus> => ApiClient.get("web/promo/launch-bonus/status"),
	claimLaunchBonus: () => ApiClient.post("web/promo/launch-bonus/claim"),
};

export default PromoService;

export const useLaunchStatus = () =>
	useQuery(["launch-status"], async () => {
		const data = await PromoService.getLaunchStatus();
		return data;
	});

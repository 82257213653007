export enum AuthenticatorStepEnum {
	Loading = -1,
	Download = 1,
	QrScan = 2,
	Completed = 3,
}

export enum AuthenticatorVariantEnum {
	Alp = "alp",
	Google = "google",
}

export interface IEventArgs {
	category: string;
	action: string;
	label: string;
	nonInteraction: boolean;
}

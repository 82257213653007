import Breadcrumbs from "components/Breadcrumbs";
import React from "react";
import { useIntl } from "react-intl";
import styles from "styles/pages/ProfileVerification.module.scss";
import { IShareDiiaDocumentEnum } from "types/verification";
import verificationMessages from "messages/verification";
import useParamQuery from "hooks/useSearchQuery";
import DiiaForm from "./Form/DiiaForm";

const VerificationWithDiia: React.FC = () => {
	const { formatMessage } = useIntl();
	const query = useParamQuery();
	const link = query.get("link") ?? "";
	const document = query.get("document") ?? "";

	return (
		<div className={styles.verification_dia_page_container_outer}>
			<Breadcrumbs
				links={[
					{
						link: "/profile/verification",
						label: formatMessage(verificationMessages.verification),
					},
					{
						link: "/profile/verification/identity",
						label: formatMessage(verificationMessages.identity),
					},
				]}
				current={formatMessage(verificationMessages.verification_with_the_diia)}
			/>
			<div className={styles.header_title}>
				{formatMessage(verificationMessages.verification_with_the_diia)}
			</div>
			<DiiaForm diiaLink={link} document={document as IShareDiiaDocumentEnum} />
		</div>
	);
};

export default VerificationWithDiia;

import React, { memo } from "react"

const Apple = () => (
	<svg xmlns="http://www.w3.org/2000/svg" width="17" height="20" viewBox="0 0 17 20" fill="none">
		<g clipPath="url(#clip0_31_660)">
			<path
				d="M15.9909 6.818C15.8769 6.908 13.8641 8.062 13.8641 10.628C13.8641 13.596 16.4253 14.646 16.502 14.672C16.4902 14.736 16.0951 16.11 15.1516 17.51C14.3103 18.742 13.4317 19.972 12.0951 19.972C10.7585 19.972 10.4145 19.182 8.8715 19.182C7.36781 19.182 6.83317 19.998 5.61057 19.998C4.38796 19.998 3.53489 18.858 2.55405 17.458C1.41794 15.814 0.5 13.26 0.5 10.836C0.5 6.948 2.98452 4.886 5.42973 4.886C6.72899 4.886 7.81204 5.754 8.62776 5.754C9.40418 5.754 10.615 4.834 12.0931 4.834C12.6533 4.834 14.6661 4.886 15.9909 6.818ZM11.3914 3.188C12.0027 2.45 12.4351 1.426 12.4351 0.402C12.4351 0.26 12.4233 0.116 12.3978 0C11.4032 0.038 10.2199 0.674 9.50639 1.516C8.94619 2.164 8.42334 3.188 8.42334 4.226C8.42334 4.382 8.44889 4.538 8.46069 4.588C8.52359 4.6 8.6258 4.614 8.72801 4.614C9.62039 4.614 10.7428 4.006 11.3914 3.188Z"
				fill="black"
			/>
		</g>
		<defs>
			<clipPath id="clip0_31_660">
				<rect width="16" height="20" fill="white" transform="translate(0.5)" />
			</clipPath>
		</defs>
	</svg>
)

export default memo(Apple)

import React, { ChangeEvent } from "react";
import { observer } from "mobx-react-lite";
import { useIntl } from "react-intl";
import Skeleton from "react-loading-skeleton";

import Input from "components/NewUI/Input";
import commonMessages from "messages/common";
import styles from "components/NewUI/Terminal/FunctionalPanel/OrderValue/index.module.scss";
import { TradeActionsEnum } from "types/exchange";

const OrderValue: React.FC<{
	quoteCurrencyCode: string;
	tradeAction: TradeActionsEnum;
	changeOrderValue: (value: string) => void;
	blurOrderValue: (value: string) => void;
	total: string;
	quoteQtyError?: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	handleSubmit?: any;
	isLoaded?: boolean;
}> = ({
	quoteCurrencyCode,
	tradeAction,
	total,
	changeOrderValue,
	blurOrderValue,
	handleSubmit,
	quoteQtyError,
	isLoaded,
}) => {
	const { formatMessage } = useIntl();

	const onChangeOrderValue = (ev: ChangeEvent<HTMLInputElement>) =>
		changeOrderValue(ev.target.value);

	const onBlurOrderValue = (ev: ChangeEvent<HTMLInputElement>) => blurOrderValue(ev.target.value);

	return (
		<div className={styles.orderValue}>
			{/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
			<label className={styles.orderValue__price}>
				<span className={styles.orderValue__labelText}>
					{formatMessage(commonMessages.order_value)}
				</span>

				{isLoaded ? (
					<Input
						name={`${tradeAction}-total`}
						type="text"
						postfixText={quoteCurrencyCode}
						placeholder={Number(total) === 0 ? "0" : ""}
						value={total}
						onChange={onChangeOrderValue}
						onBlur={onBlurOrderValue}
						onEnter={handleSubmit}
						error={quoteQtyError}
					/>
				) : (
					<Skeleton height={40} borderRadius={8} />
				)}
			</label>
		</div>
	);
};

export default observer(OrderValue);

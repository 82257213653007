import React from "react"
import { observer } from "mobx-react-lite"

import styles from "components/NewUI/Terminal/Bottom/Positions/index.module.scss"
import SignInSignUp from "../SignInSignUp"
import ListHeadline from "../ListHeadline"
import List from "./List"
import useBorrows from "../../../../../hooks/newUI/terminal/useBorrows"
import MarginOperationModal from "../../../../Terminal/modals/MarginOperationModal"

const Borrows: React.FC = () => {
	const {
		isAuthenticated,
		listHeadline,
		listScheme,
		list,
		openedMarginModal,
		handleCloseModal,
		handleMarginRepay,
	} = useBorrows()

	return (
		<div className={styles.positions}>
			{isAuthenticated ? (
				<>
					<List list={list} listScheme={listScheme} handleMarginRepay={handleMarginRepay} />

					<MarginOperationModal
						modal={openedMarginModal.type}
						onClose={handleCloseModal}
						pair={openedMarginModal.pair}
						code={openedMarginModal.code}
					/>
				</>
			) : (
				<>
					<ListHeadline list={listHeadline} listScheme={listScheme} />

					<SignInSignUp />
				</>
			)}
		</div>
	)
}

export default observer(Borrows)

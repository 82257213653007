import JsPDF from "jspdf";
import html2canvas from "html2canvas";
import {
	IAddressInfoData,
	IScoreDataSourceExt,
	ITransactionInfoData,
	ResourceTypeEnum,
} from "types/amlReport";
import { RISK_POINT } from "components/TransactionsMonitoring/AddressReport";
import { formatDate } from "./formatDate";
import {
	addressIsOwnerByHighRisk,
	checkMultiple,
	findMinMaxFields,
	formatBtcAmount,
	formatFunds,
	formatShare,
	formatter,
	getOwnerByType,
	hasDirectlyMixing,
	hasTagMoreRiskPoint,
	ownerLabelFormatter,
} from "./reportUtils";

interface IScoreDataSourceExport {
	amount: string;
	directTx: string;
	owner?: string;
	"type/tag"?: string;
	"address/cluster"?: string;
	depth: string;
	share: string;
	tx_hash: string;
}

export const exportToPdfOffline = async (id: string) => {
	window.scrollTo(0, 0);
	// const svgElem = document.getElementsByTagName("svg");
	// @ts-ignore
	// for (const node of svgElem) {
	// 	node.setAttribute(
	// 		"font-family",
	// 		"monospace",
	// 		// window.getComputedStyle(node, null).getPropertyValue("font-family")
	// 	);
	// 	node.replaceWith(node);
	// }
	const element = document.getElementById(id);
	if (element) {
		const canvas = await html2canvas(element, { scale: 1, scrollX: 0, scrollY: 0 });

		const imgData = canvas.toDataURL("image/png");
		const pdf = new JsPDF({
			orientation: "landscape",
			unit: "pt", // points, pixels won't work properly
			format: [canvas.width, canvas.height], // set needed dimensions for any element
		});
		pdf.addImage(imgData, "JPEG", 0, 0, canvas.width, canvas.height, "someAlias", "FAST");
		pdf.save("report.pdf");
	}
};

export const csvExportReport = (
	reportType: ResourceTypeEnum,
	riskyData: IScoreDataSourceExt[] = [],
	knownData: IScoreDataSourceExt[] = [],
	unknownData: IScoreDataSourceExt[] = [],
	baseData: IAddressInfoData | ITransactionInfoData,
	totalFunds = 0,
	percent = 0,
	csvName = "report",
) => {
	const addressAreUnidentified = () => {
		const sum = unknownData.reduce((acc, { share }) => acc + share, 0);

		return sum * 100 >= 75 ? "More than 75% of sources for the address are unidentified" : "";
	};

	const risky: IScoreDataSourceExport[] = riskyData.map((item) => ({
		amount: formatBtcAmount(item.amount),
		directTx: item.directTx,
		owner: item.owner,
		"type/tag": `${item.funds.type}`,
		depth: findMinMaxFields(item.depth),
		share: formatShare(item.share),
		tx_hash: checkMultiple(item.tx_hash) ? "Multiple" : item.tx_hash ?? "",
	}));

	const known: IScoreDataSourceExport[] = knownData.map((item) => ({
		amount: formatBtcAmount(item.amount),
		directTx: item.directTx,
		owner: item.owner,
		"type/tag": `${item.funds.type}`,
		depth: findMinMaxFields(item.depth),
		share: formatShare(item.share),
		tx_hash: checkMultiple(item.tx_hash) ? "Multiple" : item.tx_hash ?? "",
	}));

	const unknown: IScoreDataSourceExport[] = unknownData.map((item) => ({
		amount: formatBtcAmount(item.amount),
		directTx: item.directTx,
		"address/cluster":
			(checkMultiple(item.cluster) || checkMultiple(item.address)) &&
			item.typeData.name !== "unidentified service / exchange"
				? "Multiple"
				: getOwnerByType(item).isLink
				? getOwnerByType(item).value
				: getOwnerByType(item).owner,
		"type/tag": `${item.funds.type}`,
		depth: findMinMaxFields(item.depth),
		share: formatShare(item.share),
		tx_hash: checkMultiple(item.tx_hash) ? "Multiple" : item.tx_hash ?? "",
	}));

	const supportArr = (arr: IScoreDataSourceExport[]) => {
		if (!arr || (Array.isArray(arr) && arr.length === 0)) return [];
		return Array.from({ length: Object.keys(arr[0]).length - 1 }, () => " ");
	};

	const grouppedTitle = (key: string) => `GROUPED BY ${key}`;

	const riskyTitle = risky.length > 0 ? ["Risky Sources", supportArr(risky)] : "";
	const knownTitle = ["Known Sources", supportArr(known)];
	const unknownTitle = ["Unknown Sources", supportArr(unknown)];
	const txCountLabel = `Report generated by ${baseData.inMonitoring ? "1000" : "10"} tx,`;

	const selectionReport = (type: ResourceTypeEnum) => {
		if (type === ResourceTypeEnum.ADDRESS) {
			const data = baseData as IAddressInfoData;
			return (
				`${txCountLabel}\n` +
				`ADDRESS INFORMATION,\n` +
				`WALLET ADDRESS,${data.address}\n` +
				`OWNER,"${ownerLabelFormatter(data)}"\n` +
				`TAGS,"${data.assumedMeta.map((v) => `${v.name}`).join(",")}"\n` +
				`,\n` +
				`BALANCE,${formatBtcAmount(data.balance)}\n` +
				`TOTAL SENT,"${formatBtcAmount(data.amountSent)}"\n` +
				`TOTAL RECEIVED,"${formatBtcAmount(data.amountReceived)}"\n` +
				`NUMBER OF TXS,${data.txCount}\n` +
				`TOTAL TX SENT,${data.txSentCount}\n` +
				`TOTAL TX RECEIVED,${data.txReceivedCount}\n` +
				`FIRST SEEN,${formatDate(data.firstSeen * 1000, "dd.MM.yyyy hh:mm a")}\n` +
				`LAST SEEN,${formatDate(data.lastSeen * 1000, "dd.MM.yyyy hh:mm a")}\n` +
				`,\n` +
				`RISK SCORE,${formatFunds(Number(totalFunds), false)}\n,\n${
					addressIsOwnerByHighRisk(data, RISK_POINT) ? "Address is owned by a high risk entity" : ""
				}\n,\n${
					hasDirectlyMixing(data) ? "Address has directly participated in mixing" : ""
				}\n,\n${addressAreUnidentified()}\n,\n${
					hasTagMoreRiskPoint(data, RISK_POINT)
						? "Address has directly participated in high risk activities"
						: ""
				}\n,\n`
			);
		}

		if (type === ResourceTypeEnum.HASH) {
			const data = baseData as ITransactionInfoData;
			return (
				`TRANSACTION INFORMATION,\n` +
				`TX HASH,${data.tx_hash}\n` +
				`TOTAL AMOUNT,"${formatBtcAmount(data.inputsAmount)}"\n` +
				`TOTAL INPUTS,${data.totalInputs}\n` +
				`TOTAL OUTPUTS,${data.totalOutputs}\n` +
				`TIMESTAMP,${formatDate(data.timestamp * 1000, "dd.MM.yyyy hh:mm a")}\n` +
				`,\n` +
				`RISK SCORE,${formatFunds(Number(totalFunds), false)}\n,\n`
			);
		}

		return "";
	};

	const grouppedByType = formatter([...riskyData, ...knownData, ...unknownData], "type")
		.map((item) => ({
			type: item.funds.type,
			amount: formatBtcAmount(item.amount),
			share: formatShare(item.share),
		}))
		.sort((a, b) => (a.share < b.share ? 1 : -1));

	const grouppedByOwner = formatter([...riskyData, ...knownData, ...unknownData], "owner")
		.map((item) => ({
			owner: item.owner,
			amount: formatBtcAmount(item.amount),
			share: formatShare(item.share),
		}))
		.sort((a, b) => (a.share < b.share ? 1 : -1));

	let csvContent =
		`data:text/csv;charset=utf-8, ` +
		`This report is generated using Global Ledger proprietary technology, research capabilities, and community reports.` +
		`\n,\n ${selectionReport(reportType)}`;
	csvContent += [
		`${formatShare(percent)} of funds comes from risky sources \n`,
		grouppedTitle("TYPE"),
		grouppedByType.length > 0 ? Object.keys(grouppedByType[0]).join(",") : "",
		...(grouppedByType.length > 0
			? grouppedByType.map((item) => Object.values(item).join(","))
			: []),
		grouppedTitle("OWNER"),
		grouppedByOwner.length > 0 ? Object.keys(grouppedByOwner[0]).join(",") : "",
		...(grouppedByOwner.length > 0
			? grouppedByOwner.map((item) => Object.values(item).join(","))
			: []),
		risky.length > 0 ? riskyTitle : "",
		risky.length > 0 ? Object.keys(risky[0]).join(",") : "",
		...(risky.length > 0 ? risky.map((item) => Object.values(item).join(",")) : []),
		unknown.length > 0 ? unknownTitle : "",
		unknown.length > 0 ? Object.keys(unknown[0]).join(",") : "",
		...(unknown.length > 0 ? unknown.map((item) => Object.values(item).join(",")) : []),
		known.length > 0 ? knownTitle : "",
		known.length > 0 ? Object.keys(known[0]).join(",") : "",
		...(known.length > 0 ? known.map((item) => Object.values(item).join(",")) : []),
	]
		.join("\n")
		.replace(/(^\[)|(\]$)/gm, "");

	const data = encodeURI(csvContent);
	const link = document.createElement("a");

	link.setAttribute("href", data);
	link.setAttribute("download", `${csvName}_${formatDate(new Date())}.csv`);
	link.click();
};

// export const csvExport = (exportData: any[], csvName = "logs") => {
// 	let csvContent = "data:text/csv;charset=utf-8,";
// 	csvContent += [
// 		Object.keys(exportData[0]).join(","),
// 		...exportData.map((item) =>
// 			Object.values(item)
// 				.map((item: any) => `"${item ? item.replace("#", encodeURIComponent("#")) : ""}"`)
// 				.join(","),
// 		),
// 	]
// 		.join("\n")
// 		.replace(/(^\[)|(\]$)/gm, "");
//
// 	const data = encodeURI(csvContent);
// 	const link = document.createElement("a");
//
// 	link.setAttribute("href", data);
// 	link.setAttribute("download", `${csvName}_${formatDate(new Date())}.csv`);
// 	link.click();
// };

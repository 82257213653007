import React from "react";
import cn from "classnames";
import Footer from "components/Footer";
import Cookies from "components/Cookies";
import Header from "components/NewUI/Header";
// import MarketBanner from "components/MarketBanner";

interface IProps {
	isExchange?: boolean;
	colorPrimary?: boolean;
	children?: React.ReactNode;
}

const MainLayout: React.FC<IProps> = ({ children, colorPrimary, isExchange }) => (
	<>
		{/* <MarketBanner /> */}
		{/* <Header isExchange={isExchange} /> */}
		<Header />
		<div className={cn("layout", colorPrimary && "primary")}>{children}</div>
		<Footer />
		<Cookies />
	</>
);

export default MainLayout;

import React from "react";
import { observer } from "mobx-react-lite";
import { useIntl } from "react-intl";

import styles from "components/NewUI/Terminal/FunctionalPanel/MaxBuyingAmount/index.module.scss";
import messages from "messages/exchange";
import { TradeActionsEnum } from "types/exchange";
import { TooltipArrowEnum, TooltipModal } from "components/NewUI/TooltipModal";

const MaxBuyingAmount: React.FC<{
	tradeAction: TradeActionsEnum;
	maxBuyingAmount: string | null;
	maxSellingAmount: string | null;
	getCurrencyCodeBySide?: (tradeAction: TradeActionsEnum, isSpot?: boolean) => string;
}> = ({ tradeAction, maxSellingAmount, maxBuyingAmount, getCurrencyCodeBySide }) => {
	const { formatMessage } = useIntl();

	return (
		<div className={styles.maxBuyingAmount}>
			<TooltipModal
				arrowDirection={TooltipArrowEnum.BOTTOM_LEFT}
				content={
					<span>
						{tradeAction === TradeActionsEnum.BUY
							? "Max. Buying Amount indicates the largest quantity of an asset you can purchase on this platform. This amount is calculated based on your available balance and the current price of the trading pair. When planning your trading operations, pay attention to this parameter to allocate your funds correctly."
							: "Max. Selling Amount indicates the largest quantity of an asset you can sell on this platform. This amount is determined based on the volume of the asset you hold and the current price of the trading pair. When planning your trading operations, consider this parameter to ensure efficient utilization of your assets."}
					</span>
				}
				width={268}
			>
				<p className={styles.maxBuyingAmount__text}>
					{formatMessage(
						tradeAction === TradeActionsEnum.BUY
							? messages.max_buying_amount
							: messages.max_selling_amount,
					)}
				</p>
			</TooltipModal>

			<p className={styles.maxBuyingAmount__amount}>
				<span>
					≈ {tradeAction === TradeActionsEnum.BUY ? maxBuyingAmount : maxSellingAmount}{" "}
					{getCurrencyCodeBySide ? getCurrencyCodeBySide(tradeAction, true) : ""}
				</span>
			</p>
		</div>
	);
};

export default observer(MaxBuyingAmount);

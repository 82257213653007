import { useMst } from "models/Root"
import { useMemo, useState } from "react"
import { MarginModalEnum } from "../../../types/exchange"

const useBorrows = () => {
	const {
		terminal: { loanConditions },
		global: { isAuthenticated },
		account: { balancesCrossBorrows },
	} = useMst()

	const [openedMarginModal, setOpenedMarginModal] = useState<{
		type: MarginModalEnum | ""
		pair: string
		code: string
	}>({ type: "", pair: "", code: "" })

	const handleCloseModal = () => {
		setOpenedMarginModal({ type: "", code: "", pair: "" })
	}

	const handleMarginRepay = (code: string, pair = ""): void => {
		setOpenedMarginModal({ type: MarginModalEnum.REPAY, pair: pair, code: code })
	}

	const updatedBalancesCrossBorrows = useMemo(
		() =>
			balancesCrossBorrows.map(borrow => {
				const matchingLoanCondition = loanConditions.find(
					condition => condition.currency?.code === borrow.code,
				)

				return matchingLoanCondition
					? {
							...borrow,
							max_loan_amount: matchingLoanCondition.max_loan_amount,
							interest_rate: matchingLoanCondition.interest_rate,
					  }
					: borrow
			}),
		[balancesCrossBorrows, loanConditions],
	)

	const listScheme = [
		{
			arrCount: 1,
			id: 123,
			arrNames: [
				{
					headline: "Currency",
					width: 240,
					asociate: "currency",
				},
			],
			isScroll: false,
		},
		{
			arrCount: 11,
			id: 223,
			isScroll: true,
			arrNames: [
				{
					headline: "Borrowed Amount",
					width: 148,
					asociate: "borrowedAmount",
				},
				{
					headline: "Hourly Borrow Rate",
					width: 168,
					asociate: "hourlyBorrowRate",
				},
				{
					headline: "Max. Borrowing Amount",
					width: 168,
					asociate: "maxBorrowingAmount",
				},
				{
					headline: "Action",
					width: 150,
					asociate: "action",
				},
			],
		},
	]

	const listHeadline = listScheme.flatMap(item => item.arrNames)

	return {
		isAuthenticated,
		listScheme,
		listHeadline,
		list: updatedBalancesCrossBorrows,
		openedMarginModal,
		handleCloseModal,
		handleMarginRepay,
	}
}

export default useBorrows

import React, { memo } from "react";

const Search = () => (
	<svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M14 14l-2.333-2.333m1.666-4A5.667 5.667 0 112 7.667a5.667 5.667 0 0111.333 0z"
			stroke="#EBEBF5"
			strokeOpacity={0.6}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);

export default memo(Search);

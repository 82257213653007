import React from "react";
import { observer } from "mobx-react-lite";
import { useIntl } from "react-intl";
import Skeleton from "react-loading-skeleton";

import messages from "messages/exchange";
import styles from "components/NewUI/Terminal/FunctionalPanel/OrderType/index.module.scss";
import { OrderTypeEnum } from "types/orders";
import Item from "./Item";

const OrderType: React.FC<{
	setTradeMode: (nextTradeMode: OrderTypeEnum) => void;
	tradeMode: OrderTypeEnum;
	isLoaded?: boolean;
}> = ({ setTradeMode, tradeMode, isLoaded }) => {
	const { formatMessage } = useIntl();

	const tradeModesArray = [
		{
			mode: OrderTypeEnum.LIMIT,
			text: formatMessage(messages.order_type_limit),
		},
		{
			mode: OrderTypeEnum.MARKET,
			text: formatMessage(messages.order_type_market),
		},
		{
			mode: OrderTypeEnum.STOP_ORDER,
			text: formatMessage(messages.order_type_stop_order),
		},
	];

	return (
		<div className={styles.orderType}>
			<ul className={styles.orderType__list}>
				{tradeModesArray.map(({ mode, text }, index) =>
					isLoaded ? (
						<Item
							key={`${mode}-trade-mode`}
							mode={mode}
							text={text}
							tradeMode={tradeMode}
							setTradeMode={setTradeMode}
						/>
					) : (
						<div key={`${mode}-skeleton-trade-mode`} style={{ height: "28px", marginRight: "8px" }}>
							<Skeleton height={28} width={40 * (index + 1)} borderRadius={8} />
						</div>
					),
				)}
			</ul>
		</div>
	);
};

export default observer(OrderType);

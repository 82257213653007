import React, { memo } from "react";

const Languages = () => (
	<svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M10 19.167A9.167 9.167 0 1010 .833a9.167 9.167 0 000 18.334zM11.897 2.74a13.586 13.586 0 011.789 3.926h3.036a7.517 7.517 0 00-4.825-3.926zM10 2.96a11.92 11.92 0 011.946 3.708H8.054a11.916 11.916 0 011.947-3.708zm2.5 7.04a11.932 11.932 0 00-.155-1.666h-4.69C7.566 8.882 7.513 9.44 7.5 10c.012.561.064 1.118.155 1.667h4.69c.09-.549.142-1.106.155-1.667zm-.554 3.334H8.054a11.918 11.918 0 001.947 3.708 11.917 11.917 0 001.946-3.708zm-5.978-1.666a13.583 13.583 0 01-.135-1.684c.012-.555.057-1.106.135-1.65H2.686a7.525 7.525 0 000 3.334H5.97zM3.28 13.333h3.037a13.586 13.586 0 001.788 3.925 7.517 7.517 0 01-4.825-3.925zm10.405 0h3.036a7.517 7.517 0 01-4.825 3.925 13.587 13.587 0 001.789-3.925zm3.63-1.666h-3.283a13.567 13.567 0 00.135-1.684 13.568 13.568 0 00-.135-1.65h3.283a7.526 7.526 0 010 3.334zM8.105 2.74a13.585 13.585 0 00-1.788 3.926H3.28A7.517 7.517 0 018.105 2.74z"
			fill="#fff"
		/>
	</svg>
);

export default memo(Languages);

import React, { useMemo } from "react"
import { useIntl } from "react-intl"

import { useMst } from "models/Root"
import { TERMINAL_LATEST_PAIR_CACHE_KEY } from "utils/cacheKeys"
import accountMessages from "messages/account"
import useLocalStorage from "hooks/useLocalStorage"
import config from "helpers/config"
import { queryVars } from "constants/query"
import { HeaderNavArrayTypes } from "types/header"
import { URL_VARS, routes } from "constants/routing"
import exchangeMessages from "messages/exchange"
import supportMessages from "messages/support"
import commonMessages from "messages/common"
import User from "assets/icons/header/User"
import Notifications from "assets/icons/header/Notifications"
import Languages from "assets/icons/header/Languages"
import Wallet from "components/NewUI/Header/Navigation/Wallet"
import UserContent from "components/NewUI/Header/Navigation/User"
import NotificationsContent from "components/NewUI/Header/Navigation/Notifications"
import LanguagesContent from "components/NewUI/Header/Navigation/Languages"
import TradingList from "components/NewUI/Header/Navigation/TradingList"
import Orders from "components/NewUI/Header/Navigation/Orders"

const useHeader = () => {
	const { formatMessage } = useIntl()
	const [latestTerminalPair] = useLocalStorage(
		TERMINAL_LATEST_PAIR_CACHE_KEY,
		config.defaultTerminalPair,
	)
	const {
		global: { isAuthenticated },
		notifications: { unread_count },
		render,
	} = useMst()

	const navArray: HeaderNavArrayTypes = useMemo(() => {
		// Items that are displayed in any case
		const commonArray = [
			{
				id: "news",
				list: [
					{
						id: "nav_crypto_news_desc",
						link: routes.news.root,
						title: formatMessage(commonMessages.nav_crypto_news_title),
						subtitle: formatMessage(commonMessages.nav_crypto_news_desc),
						isShow: render.cryptoNews,
					},
					{
						id: "nav_news_of_exchange_desc",
						link: routes.newsAlpCom.root,
						title: formatMessage(commonMessages.nav_news_of_exchange_title),
						subtitle: formatMessage(commonMessages.nav_news_of_exchange_desc),
						isShow: render.news,
					},
					{
						id: "nav_alpha_stories_desc",
						link: routes.stories.root,
						title: "Alpha Stories",
						subtitle: formatMessage(commonMessages.nav_alpha_stories_desc),
						isShow: render.stories,
					},
				],
				onClick: () => {
					console.log("news")
				},
				formatMessage: formatMessage(commonMessages.news),
			},
			{
				id: "trade",
				defaultActive: true,
				list: [
					{
						id: "futures_crypto",
						link: `/`,
						title: "Futures",
						subtitle: "Coming soon",
						disabled: true,
					},
					{
						id: "buy_crypto",
						link: `/${URL_VARS.BUY_CRYPTO}/${config.defaultBuyCryptoPair}`,
						title: formatMessage(commonMessages.buy_crypto),
						subtitle: formatMessage(commonMessages.buy_crypto_desc),
						isShow: render.buyCrypto,
					},
					{
						id: "margin_terminal",
						link: `/${URL_VARS.TRADE}/${config.defaultTerminalMarginPair}?${queryVars.type}=cross`,
						title: formatMessage(commonMessages.margin_terminal),
						subtitle: formatMessage(commonMessages.margin_trading_desc),
						SecondLvl: <TradingList isMargin />,
						isShow: render.margin,
					},
					{
						id: "spot_terminal",
						link: `${routes.trade.getPair(latestTerminalPair)}?${queryVars.type}=spot`,
						title: formatMessage(commonMessages.spot_terminal),
						subtitle: formatMessage(commonMessages.standard_desc),
						SecondLvl: <TradingList />,
						defaultActive: true,
					},
				],
				onClick: () => {
					console.log("trade")
				},
				formatMessage: formatMessage(commonMessages.trade),
			},
			{
				id: "p2p",
				defaultActive: true,
				linkTo: routes.p2p.root,
				formatMessage: "P2P",
				disabled: !render.p2p,
			},
			{
				id: "staking",
				defaultActive: true,
				list: [],
				onClick: () => {
					console.log("Staking")
				},
				formatMessage: formatMessage(commonMessages.staking),
			},
			{
				id: "checkCrypto",
				defaultActive: true,
				list: [],
				onClick: () => {
					console.log("Check Crypto")
				},
				formatMessage: formatMessage(commonMessages.checkCrypto),
			},
			{
				id: "aiBot",
				defaultActive: true,
				list: [],
				onClick: () => {
					console.log("AI Bot")
				},
				formatMessage: "AI Bot",
			},
			{
				id: "info",
				list: [
					{
						id: "margin_trade_faq_desc",
						link: routes.marginTradingFaq,
						title: `${formatMessage(accountMessages.margin_trading)} - FAQ`,
						subtitle: formatMessage(exchangeMessages.margin_trade_faq_desc),
						isShow: render.margin,
					},
					{
						id: "coin_info",
						link: routes.coin.root,
						title: formatMessage(commonMessages.coin_info),
						subtitle: formatMessage(commonMessages.coin_info_name_desc),
						isShow: render.coinInfo,
					},
					{
						id: "support_center",
						link: routes.support.root,
						title: formatMessage(supportMessages.support_center),
						subtitle: formatMessage(supportMessages.support_center_search_header),
						isShow: render.supportCenter,
					},
				],
				onClick: () => {
					console.log("info")
				},
				formatMessage: formatMessage(commonMessages.info),
			},
		]

		// Items that are displayed only for authenticated users
		const authenticatedArray = [
			{
				id: "wallet",
				children: <Wallet />,
				onClick: () => {
					console.log("wallet")
				},
				formatMessage: formatMessage(commonMessages.wallet),
			},
			{
				id: "orders",
				children: <Orders />,
				onClick: () => {
					console.log("orders")
				},
				formatMessage: formatMessage(commonMessages.orders),
			},
			{
				id: "user",
				children: <UserContent />,
				onClick: () => {
					console.log("user")
				},
				ariaLabel: "User settings",
				Icon: <User />,
			},
			{
				id: "notifications",
				count: unread_count,
				children: <NotificationsContent />,
				onClick: () => {
					console.log("notifications")
				},
				ariaLabel: "Notifications",
				Icon: <Notifications />,
			},
		]

		// Items that are displayed only for unauthenticated users
		const unAuthenticatedArray = [
			{
				id: "signIn",
				children: <div />,
				linkTo: routes.login.root,
				formatMessage: formatMessage(commonMessages.login),
			},
			{
				id: "signUp",
				children: <div />,
				linkTo: routes.register.root,
				formatMessage: formatMessage(commonMessages.register),
			},
		]

		// Language configurator, displayed in any case
		const languagesItem = {
			id: "languages",
			children: <LanguagesContent />,
			onClick: () => {
				console.log("Languages")
			},
			ariaLabel: "Languages settings",
			Icon: <Languages />,
		}

		const selectedArray = isAuthenticated ? authenticatedArray : unAuthenticatedArray

		return [...commonArray, ...selectedArray, languagesItem]
	}, [isAuthenticated, formatMessage, latestTerminalPair, unread_count, render])

	return { navArray, logoLinkTo: routes.trade.root }
}

export default useHeader

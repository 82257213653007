import React, { memo } from "react"

const Cross = () => (
	<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M8 0.666626C3.94992 0.666626 0.666672 3.94987 0.666672 7.99996C0.666672 12.05 3.94992 15.3333 8 15.3333C12.0501 15.3333 15.3333 12.05 15.3333 7.99996C15.3333 3.94987 12.0501 0.666626 8 0.666626ZM10.4714 5.52855C10.7318 5.7889 10.7318 6.21101 10.4714 6.47136L8.94281 7.99996L10.4714 9.52855C10.7318 9.7889 10.7318 10.211 10.4714 10.4714C10.2111 10.7317 9.78895 10.7317 9.5286 10.4714L8 8.94277L6.47141 10.4714C6.21106 10.7317 5.78895 10.7317 5.5286 10.4714C5.26825 10.211 5.26825 9.7889 5.5286 9.52855L7.0572 7.99996L5.5286 6.47136C5.26825 6.21101 5.26825 5.7889 5.5286 5.52855C5.78895 5.26821 6.21106 5.26821 6.47141 5.52855L8 7.05715L9.5286 5.52855C9.78895 5.26821 10.2111 5.26821 10.4714 5.52855Z"
			fill="#FF3B30"
		/>
	</svg>
)

export default memo(Cross)

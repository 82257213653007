import React from "react"
import { observer } from "mobx-react-lite"

import Google from "assets/icons/auth/Google"
import Apple from "assets/icons/auth/Apple"
import styles from "./ThirdParty.module.scss"

const ThirdParty: React.FC<{ lineText: string }> = ({ lineText }) => (
	<>
		<div className={styles.thirdParty__line}>
			<p className={styles.thirdParty__lineText}>{lineText}</p>
		</div>

		<div className={styles.thirdParty__auth}>
			<button type="button" disabled className={styles.thirdParty__authButton}>
				<Google />

				<span>Google</span>
			</button>

			<button type="button" disabled className={styles.thirdParty__authButton}>
				<Apple />

				<span>Apple</span>
			</button>
		</div>
	</>
)

export default observer(ThirdParty)

import React, { memo } from "react";

const FormTopArrow = () => (
	<svg width={6} height={10} fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M3.295 1.372a.417.417 0 00-.59 0l-2.5 2.5a.417.417 0 10.59.59l1.788-1.79v5.661a.417.417 0 10.834 0v-5.66L5.206 4.46a.417.417 0 00.589-.589l-2.5-2.5z"
			fill="#fff"
		/>
	</svg>
);

export default memo(FormTopArrow);

import React from "react";
import { observer } from "mobx-react-lite";

import Spinner from "components/UI/Spinner";
import styles from "components/NewUI/Terminal/Bottom/TradeHistory/List/index.module.scss";
import Section from "./Section";

const List: React.FC<{
	list: unknown[];
	listScheme: {
		arrCount: number;
		id: number;
		isScroll?: boolean;
		arrNames: { headline: string; width: number; asociate: string }[];
	}[];
	pairAmountPrecision: number;
	pairPricePrecision: number;
	isTradeHistoryLoading: boolean;
}> = ({ list, listScheme, pairAmountPrecision, pairPricePrecision, isTradeHistoryLoading }) => (
	<>
		<div className={styles.list}>
			{listScheme.map(({ id, arrCount, isScroll, arrNames }) => (
				<Section
					key={`${id}-list`}
					list={list}
					arrNames={arrNames}
					arrCount={arrCount}
					isScroll={isScroll}
					id={id}
					pairAmountPrecision={pairAmountPrecision}
					pairPricePrecision={pairPricePrecision}
				/>
			))}
		</div>

		{list.length === 0 && isTradeHistoryLoading ? (
			<div className={styles.list__loading}>
				<Spinner size={24} />
			</div>
		) : null}

		{list.length === 0 && !isTradeHistoryLoading ? (
			<div className={styles.list__empty}>You have no closed orders.</div>
		) : null}
	</>
);

export default observer(List);

import React, { memo } from "react"

const Alert = () => (
	<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M9.99992 0.833496C4.93731 0.833496 0.833252 4.93755 0.833252 10.0002C0.833252 15.0628 4.93731 19.1668 9.99992 19.1668C15.0625 19.1668 19.1666 15.0628 19.1666 10.0002C19.1666 4.93755 15.0625 0.833496 9.99992 0.833496ZM10.8333 6.66683C10.8333 6.20659 10.4602 5.8335 9.99992 5.8335C9.53968 5.8335 9.16658 6.20659 9.16658 6.66683V10.0002C9.16658 10.4604 9.53968 10.8335 9.99992 10.8335C10.4602 10.8335 10.8333 10.4604 10.8333 10.0002V6.66683ZM9.99992 12.5002C9.53968 12.5002 9.16658 12.8733 9.16658 13.3335C9.16658 13.7937 9.53968 14.1668 9.99992 14.1668H10.0083C10.4685 14.1668 10.8416 13.7937 10.8416 13.3335C10.8416 12.8733 10.4685 12.5002 10.0083 12.5002H9.99992Z"
			fill="#EF454A"
		/>
	</svg>
)

export default memo(Alert)

import React, { ButtonHTMLAttributes, FC } from "react"

import Loader from "assets/icons/Loader"
import styles from "./Button.module.scss"

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
	isLoading?: boolean
}

const Button: FC<ButtonProps> = ({ children, isLoading = false, ...props }) => (
	// eslint-disable-next-line react/button-has-type
	<button
		{...props}
		className={`${styles.button__button} ${
			props.color === "transparent" ? styles.button__button___transparent : ""
		} ${isLoading ? styles.button__button___loading : ""}`}
	>
		{isLoading ? (
			<span className={styles.button__loader}>
				<Loader />
			</span>
		) : (
			children
		)}
	</button>
)

export default Button

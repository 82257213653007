import React, { memo } from "react";

const OrderRed = () => (
	<svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
		<g clipPath="url(#prefix__clip0_229_13919)">
			<path d="M0 0h7v16H0V0z" fill="#EF454A" />
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M9 0h7v4H9V0zm0 6h7v4H9V6zm7 6H9v4h7v-4z"
				fill="#2C2C2E"
			/>
		</g>
		<defs>
			<clipPath id="prefix__clip0_229_13919">
				<path fill="#fff" d="M0 0h16v16H0z" />
			</clipPath>
		</defs>
	</svg>
);

export default memo(OrderRed);

import React, { FC, useEffect } from "react"
import { observer } from "mobx-react-lite"
import { useNavigate } from "react-router-dom"

import { TradeTypeEnum } from "types/exchange"
import { useMst } from "models/Root"
import { queryVars } from "constants/query"
import { URL_VARS } from "constants/routing"
import styles from "components/NewUI/Header/Navigation/index.module.scss"
import { QUOTED_CURRENCIES_BASE } from "models/Ticker"
import Tab from "./Tab"
import Item from "./Item"

const TradingList: FC<{ isMargin?: boolean }> = ({ isMargin = false }) => {
	const {
		tickers: {
			filter: {
				quotedCurrencySpotHeader,
				setQuotedCurrencySpotHeader,
				quotedCurrencyMarginHeader,
				setQuotedCurrencyMarginHeader,
			},
			formattedListSpotHeader,
			formattedListMarginHeader,
			loadTickers,
		},
		global: { locale },
		render: { margin: isRenderMargin },
	} = useMst()
	const navigate = useNavigate()
	const currencyList = [...QUOTED_CURRENCIES_BASE]
		.map(currency => ({
			label: currency,
			name: currency,
		}))
		.reverse()
	const quotedCurrency = isMargin ? quotedCurrencyMarginHeader : quotedCurrencySpotHeader
	const setQuotedCurrency = isMargin ? setQuotedCurrencyMarginHeader : setQuotedCurrencySpotHeader
	const list = isMargin ? formattedListMarginHeader : formattedListSpotHeader

	const handleLinkClick = (symbol: string) => {
		navigate(
			`/${locale}/${URL_VARS.TRADE}/${symbol}?${queryVars.type}=${
				isMargin ? TradeTypeEnum.CROSS : TradeTypeEnum.SPOT
			}`,
		)
		window.location.reload()
	}

	useEffect(() => {
		if (isMargin && currencyList[0]?.name) {
			setQuotedCurrencyMarginHeader(currencyList[0].name)
		} else if (currencyList[0]?.name) {
			setQuotedCurrencySpotHeader(currencyList[0].name)
		}
	}, [isMargin])

	useEffect(() => {
		loadTickers()
	}, [])

	return (
		<div
			className={`${styles.nav__tradingList} ${isMargin ? styles.nav__tradingList___margin : ""}`}
		>
			<ul className={styles.nav__tradingListUl}>
				{currencyList.map(({ label, name }) => (
					<Tab
						key={`${label}-radioHeaderLabel`}
						name={name}
						label={label}
						quotedCurrency={quotedCurrency}
						setQuotedCurrency={setQuotedCurrency}
						isMargin={isMargin}
					/>
				))}
			</ul>

			{list.length > 0 ? (
				<ul className={styles.nav__tradingCoins}>
					{list.map(item => (
						<Item
							key={item.label}
							item={item}
							isRenderMargin={isRenderMargin}
							handleLinkClick={handleLinkClick}
						/>
					))}
				</ul>
			) : (
				<p style={{ textAlign: "center", marginTop: "30px" }}>I need Icon for empty state</p>
			)}
		</div>
	)
}

export default observer(TradingList)

import React, { ChangeEvent } from "react";
import { useIntl } from "react-intl";
import { observer } from "mobx-react-lite";
import Skeleton from "react-loading-skeleton";

import Input from "components/NewUI/Input";
import messages from "messages/exchange";
import commonMessages from "messages/common";
import styles from "components/NewUI/Terminal/FunctionalPanel/TriggerOrderPriceQty/index.module.scss";
import ArrowPrecision from "assets/icons/terminal/ArrowPrecision";
import { TradeActionsEnum } from "types/exchange";

const TriggerOrderPriceQty: React.FC<{
	tradeAction: TradeActionsEnum;
	stop_price: string;
	onChangeTargetPrice: (value: string) => void;
	onChangePrice: (value: string) => void;
	onChangeAmount: (value: string) => void;
	onBlurAmount: (value: string) => void;
	onBlurPrice: (value: string) => void;
	onBlurTargetPrice: (value: string) => void;
	quoteCurrencyCode: string;
	price: string;
	baseCurrencyCode: string;
	qtyError?: string;
	orderPriceError?: string;
	stopPriceError?: string;
	amount: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	handleSubmit?: any;
	isLoaded?: boolean;
}> = ({
	tradeAction,
	stop_price,
	onChangeTargetPrice,
	onBlurTargetPrice,
	onBlurPrice,
	onBlurAmount,
	quoteCurrencyCode,
	price,
	baseCurrencyCode,
	amount,
	handleSubmit,
	onChangePrice,
	onChangeAmount,
	qtyError,
	orderPriceError,
	stopPriceError,
	isLoaded,
}) => {
	const { formatMessage } = useIntl();

	const onChangeTargetPriceAction = (ev: ChangeEvent<HTMLInputElement>) =>
		onChangeTargetPrice(ev.target.value);

	const onBlurTargetPriceAction = (ev: ChangeEvent<HTMLInputElement>) =>
		onBlurTargetPrice(ev.target.value);

	const onChangePriceAction = (ev: ChangeEvent<HTMLInputElement>) => onChangePrice(ev.target.value);

	const onBlurPriceAction = (ev: ChangeEvent<HTMLInputElement>) => onBlurPrice(ev.target.value);

	const onChangeAmountAction = (ev: ChangeEvent<HTMLInputElement>) =>
		onChangeAmount(ev.target.value);

	const onBlurAmountAction = (ev: ChangeEvent<HTMLInputElement>) => onBlurAmount(ev.target.value);

	return (
		<div className={styles.orderPriceQty}>
			{/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
			<label className={styles.orderPriceQty__price}>
				<span className={styles.orderPriceQty__labelText}>
					{formatMessage(messages.trigger_price)}
				</span>

				{isLoaded ? (
					<Input
						name={`${tradeAction}-stop_price`}
						type="string"
						placeholder={Number(stop_price) === 0 ? "0" : ""}
						value={stop_price}
						onChange={onChangeTargetPriceAction}
						onEnter={handleSubmit}
						onBlur={onBlurTargetPriceAction}
						error={stopPriceError}
						postfixText={quoteCurrencyCode}
					/>
				) : (
					<Skeleton height={40} borderRadius={8} />
				)}
			</label>

			<div className={styles.orderPriceQty__orderPrice}>
				{/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
				<label className={`${styles.orderPriceQty__price} ${styles.orderPriceQty__price___order}`}>
					<span className={styles.orderPriceQty__labelText}>
						{formatMessage(messages.order_price)}
					</span>

					{isLoaded ? (
						<Input
							name={`${tradeAction}-price`}
							type="text"
							postfixText={quoteCurrencyCode}
							placeholder={Number(price) === 0 ? "0" : ""}
							value={price}
							onChange={onChangePriceAction}
							onBlur={onBlurPriceAction}
							onEnter={handleSubmit}
							error={orderPriceError}
							// disabled={type === OrderTypeEnum.STOP_MARKET}
						/>
					) : (
						<Skeleton height={40} borderRadius={8} />
					)}
				</label>

				<div className={styles.orderPriceQty__orderPriceWrapper}>
					<button
						className={`${styles.orderPriceQty__orderPriceButton} ${styles.orderPriceQty__orderPriceButton___disabled}`}
						type="button"
						disabled
					>
						<span className={styles.orderPriceQty__orderPriceButtonText}>Limit</span>

						<ArrowPrecision />
					</button>
				</div>
			</div>

			{/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
			<label className={styles.orderPriceQty__qty}>
				<span className={styles.orderPriceQty__labelText}>
					{formatMessage(commonMessages.quantity)}
				</span>

				{isLoaded ? (
					<Input
						name={`${tradeAction}-amount`}
						type="text"
						postfixText={baseCurrencyCode}
						placeholder={Number(amount) === 0 ? "0" : ""}
						value={amount}
						onChange={onChangeAmountAction}
						onEnter={handleSubmit}
						onBlur={onBlurAmountAction}
						error={qtyError}
					/>
				) : (
					<Skeleton height={40} borderRadius={8} />
				)}
			</label>
		</div>
	);
};

export default observer(TriggerOrderPriceQty);

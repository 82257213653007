import React, { memo } from "react"

const Logo = () => (
	<svg xmlns="http://www.w3.org/2000/svg" width="133" height="20" fill="none" viewBox="0 0 38 20">
		<path
			fill="#4E55A2"
			d="M10.65 2.138a1.597 1.597 0 00-1.3 0c-.319.142-.526.392-.67.596-.142.2-.294.463-.461.752L1.613 14.908c-.168.29-.32.553-.423.777-.105.227-.22.533-.183.881a1.6 1.6 0 00.65 1.128c.283.206.604.26.853.284.245.022.55.022.884.022h13.212c.334 0 .639 0 .884-.023.249-.022.57-.077.853-.283a1.6 1.6 0 00.65-1.128c.036-.348-.077-.654-.183-.881a10.446 10.446 0 00-.423-.777L11.78 3.486c-.167-.289-.319-.551-.46-.752-.145-.204-.352-.454-.672-.596z"
		/>
		<path
			fill="#EF454A"
			d="M33.393 2h-6.786c-.644 0-1.175 0-1.608.035-.45.037-.863.116-1.252.314a3.2 3.2 0 00-1.398 1.398c-.198.389-.277.802-.314 1.252C22 5.43 22 5.963 22 6.607v6.786c0 .644 0 1.175.035 1.608.037.45.116.864.314 1.252a3.2 3.2 0 001.398 1.398c.389.198.802.277 1.252.314.433.035.964.035 1.608.035h6.786c.644 0 1.175 0 1.608-.035.45-.037.864-.116 1.252-.314a3.2 3.2 0 001.398-1.398c.198-.389.277-.802.314-1.252.035-.432.035-.964.035-1.608V6.607c0-.644 0-1.176-.035-1.608-.037-.45-.116-.863-.314-1.252a3.2 3.2 0 00-1.398-1.398c-.389-.198-.802-.277-1.252-.314C34.57 2 34.038 2 33.394 2z"
		/>
	</svg>
)

export default memo(Logo)

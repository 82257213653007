import React, {
	FC,
	HTMLInputTypeAttribute,
	InputHTMLAttributes,
	useCallback,
	useState,
} from "react"
import { observer } from "mobx-react-lite"

import styles from "./Input.module.scss"
import Alert from "../../../../assets/icons/auth/Alert"
import Eye from "../../../../assets/icons/auth/Eye"
import ClosedEye from "../../../../assets/icons/auth/ClosedEye"
import ArrowUp from "../../../../assets/icons/auth/ArrowUp"

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
	label?: string
	error?: string
	id: string
	labelRightComponent?: React.ReactNode
	handleChange?: (text: string) => void
	isCollapsible?: boolean
	collapsibleDefault?: boolean
}

const Input: FC<InputProps> = ({
	label,
	id,
	handleChange,
	labelRightComponent = null,
	error,
	isCollapsible = false,
	collapsibleDefault = false,
	...props
}) => {
	const [type, setType] = useState<HTMLInputTypeAttribute | undefined>(props?.type)
	const [isCollapsed, setIsCollapsed] = useState<boolean>(collapsibleDefault)

	const handleChangeAction = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (handleChange) handleChange(event.target.value)
	}

	const handleCollapse = useCallback(() => {
		setIsCollapsed(!isCollapsed)
	}, [isCollapsed])

	const changeType = useCallback(() => {
		if (type === "password") {
			setType("text")
		} else {
			setType("password")
		}
	}, [type])

	return (
		<div className={`${styles.input} ${error ? styles.input___error : ""}`}>
			{label ? (
				<div className={styles.input__labelWrapper}>
					{!isCollapsible ? (
						<label className={styles.input__label} htmlFor={id}>
							{label}
						</label>
					) : (
						<button
							type="button"
							onClick={handleCollapse}
							className={`${styles.input__label} ${styles.input__label___button} ${
								isCollapsed ? styles.input__label___buttonClose : ""
							}`}
						>
							<span>{label}</span>

							<ArrowUp />
						</button>
					)}

					{labelRightComponent}
				</div>
			) : null}

			<div
				className={`${styles.input__wrapper} ${label ? styles.input__wrapper___label : ""} ${
					isCollapsible && isCollapsed ? styles.input__wrapper___collapsed : ""
				}`}
			>
				<input
					id={id}
					onChange={handleChangeAction}
					className={styles.input__element}
					{...props}
					type={type}
				/>
			</div>

			{props.type === "password" ? (
				<button type="button" onClick={changeType} className={styles.input__password}>
					{type === "password" ? <Eye /> : <ClosedEye />}
				</button>
			) : null}

			<div className={styles.input__error}>
				<Alert />

				<div className={styles.input__errorTextWrapper}>
					<p className={styles.input__errorText}>{error}</p>
				</div>
			</div>
		</div>
	)
}

export default observer(Input)

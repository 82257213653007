import React, { memo } from "react";

const ArrowPrecision = () => (
	<svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M3.528 5.529c.26-.26.683-.26.943 0L8 9.057l3.528-3.528a.667.667 0 11.943.943l-4 4a.667.667 0 01-.943 0l-4-4a.667.667 0 010-.943z"
			fill="#EBEBF5"
			fillOpacity={0.6}
		/>
	</svg>
);

export default memo(ArrowPrecision);

import React, { memo } from "react"
import { useIntl } from "react-intl"

import { TooltipArrowEnum, TooltipModal } from "components/NewUI/TooltipModal"
import styles from "components/NewUI/Terminal/FunctionalPanel/AvailableBalance/index.module.scss"
import SpotBalance from "./SpotBalance"
import commonMessages from "../../../../../messages/common"

const AvailableBalance: React.FC<{
	currencyCodeBySide: string
	mainBalance: string | undefined
}> = ({ currencyCodeBySide, mainBalance }) => {
	const { formatMessage } = useIntl()

	return (
		<div className={styles.availableBalance}>
			<TooltipModal
				arrowDirection={TooltipArrowEnum.TOP_LEFT}
				content={
					<span>
						Available Balance reflects the amount of funds in your account that are available for
						trading and executing other operations on our exchange. This includes all your deposits,
						minus amounts held in open orders or reserved due to other obligations. Ensure that your
						Available Balance meets your trading needs to avoid delays or issues in fulfilling
						orders.
					</span>
				}
				width={268}
			>
				<p className={styles.availableBalance__text}>
					{formatMessage(commonMessages.available_balance)}
				</p>
			</TooltipModal>

			<p className={styles.availableBalance__amount}>
				<SpotBalance currencyCodeBySide={currencyCodeBySide} mainBalance={mainBalance} />
			</p>
		</div>
	)
}

export default memo(AvailableBalance)

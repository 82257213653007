import React from "react";
import { observer } from "mobx-react-lite";

import Spinner from "components/UI/Spinner";
import styles from "components/NewUI/Terminal/Bottom/OrderHistory/List/index.module.scss";
import Section from "./Section";

const List: React.FC<{
	list: unknown[];
	listScheme: {
		arrCount: number;
		id: number;
		isScroll?: boolean;
		arrNames: { headline: string; width: number; asociate: string }[];
	}[];
	pairAmountPrecision: number;
	pairPricePrecision: number;
	isClosedOrdersLoading: boolean;
	setCurrentOpenOrderHistoryOrderID?: (id: string) => void;
	currentOpenOrderHistoryOrderID?: string;
}> = ({
	list,
	listScheme,
	pairAmountPrecision,
	pairPricePrecision,
	isClosedOrdersLoading,
	setCurrentOpenOrderHistoryOrderID,
	currentOpenOrderHistoryOrderID,
}) => (
	<>
		<div className={styles.list}>
			{listScheme.map(({ id, arrCount, isScroll, arrNames }) => (
				<Section
					key={`${id}-list`}
					list={list}
					arrNames={arrNames}
					arrCount={arrCount}
					isScroll={isScroll}
					id={id}
					pairAmountPrecision={pairAmountPrecision}
					pairPricePrecision={pairPricePrecision}
					setCurrentOpenOrderHistoryOrderID={setCurrentOpenOrderHistoryOrderID}
					currentOpenOrderHistoryOrderID={currentOpenOrderHistoryOrderID}
				/>
			))}
		</div>

		{list.length === 0 && isClosedOrdersLoading ? (
			<div className={styles.list__loading}>
				<Spinner size={24} />
			</div>
		) : null}

		{list.length === 0 && !isClosedOrdersLoading ? (
			<div className={styles.list__empty}>You have no closed orders.</div>
		) : null}
	</>
);

export default observer(List);

export enum RenderModuleEnum {
	MOBILE_APP = "mobile-app",
	PAYMENT_SERVICE = "payment-services",
	ALPHA_CODE = "alpha-code",
	LISTING = "listing",
	SOCIAL_LISTING = "social-listing",
	MARGIN = "margin",
	CHARITY = "charity",
	REFERRALS = "referrals",
	SUB_ACCOUNTS = "sub-accounts",
	TRANSFERS = "transfers",
	WELCOME_BONUS = "welcome-bonus",
	COMPETITIONS = "competitions",
	NEWS = "news",
	CRYPTO_NEWS = "crypto-news",
	STORIES = "stories",
	STAKING = "staking",
	COIN_INFO = "coin-info",
	BUY_CRYPTO = "buy-crypto",
	HOME_STATS = "home-stats",
	SUPPORT_CENTER = "support-center",
	ALP_COIN = "alp-coin",
	TRANSACTIONS_MONITORING = "transactions-monitoring",
	P2P = "p2p",
	GIVEAWAY = "giveaway",
}

import React, { CSSProperties } from "react"
import Select, { components } from "react-select"
import { useIntl } from "react-intl"
import cn from "classnames"

import commonMessages from "messages/common"
import SelectDropdownArrow from "assets/icons/SelectDropdownArrow"
import styles from "./Select.module.scss"

const initialStyles = {
	// TODO: Add real type, try to use generic from react-select StylesConfig<>
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore-start
	control: (base: CSSProperties, state) => ({
		...base,
		outline: "none",
		margin: "8px 0 0 0",
		border: `1px solid ${
			state.isFocused
				? "##4E55A2"
				: state.selectProps.error
				? "#EF454A"
				: "rgba(198, 198, 200, 0.08)"
		}`,
		transition: "border-color 0.2s ease-in-out",
		pointerEvents: state.selectProps.disabled ? "none" : "all",
		backgroundColor: "#F2F2F7",
		minHeight: 0,
		height: "52px",
		":hover": {
			border: `1px solid ${
				state.isFocused
					? "##4E55A2"
					: state.selectProps.error
					? "#EF454A"
					: "rgba(198, 198, 200, 0.08)"
			}`,
		},
		boxShadow: "unset",
		borderRadius: "16px",
		fontSize: 16,
		cursor: "pointer",
	}),
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore-start
	placeholder: (_, state) => ({
		color: state.selectProps.disabled ? "var(--input-label-disabled)" : "#000",
		fontSize: "16px",
		transition: "0.2s ease",
		padding: "0 10px",
		position: "absolute",
		// ".ai": {
		// 	color: state.selectProps.error ? "var(--input-error)" : "var(--icon-color)",
		// },
	}),
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore-start
	input: (base: CSSProperties, state) => ({
		...base,
		margin: 0,
		padding: 0,
		height: "52px",
		color: "#000",
	}),
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore-start
	menu: (base: CSSProperties, state) => ({
		...base,
		top: "calc(100% + 8px)",
		zIndex: 202,
		border: "none",
		boxShadow: "none",
		borderRadius: "16px",
		margin: 0,
		marginTop: "8px",
		overflow: "hidden",
		borderTop: `unset`,
		backgroundColor: "#F2F2F7",
	}),
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore-start
	dropdownIndicator: (base: CSSProperties, state) => ({
		...base,
		padding: 0,
		marginRight: "24px",
		transition: ".3s ease",
		transform: state.selectProps.menuIsOpen ? "rotate(-180deg)" : "rotate(0deg)",
	}),
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore-start
	menuList: (base: CSSProperties) => ({
		...base,
		maxHeight: "240px",
		backgroundColor: "#F2F2F7",
		border: "none",
		borderBottomRightRadius: 16,
		borderBottomLeftRadius: 16,
		margin: 0,
		padding: 0,
		":last-child": {
			borderBottom: "none",
		},
	}),
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore-start
	option: (base: CSSProperties, state) => ({
		...base,
		display: "flex",
		alignItems: "center",
		fontSize: 14,
		cursor: "pointer",
		backgroundColor: "#F2F2F7",
		color: "#000",
		padding: "0 24px",
		borderBottom: "1px solid rgba(198, 198, 200, 0.20)",
		height: "52px",
		margin: 0,
	}),
}

const DropdownIndicator = React.memo(({ ...props }: any) => (
	<components.DropdownIndicator {...props}>
		<SelectDropdownArrow />
	</components.DropdownIndicator>
))

const ClearIndicator = () => null

const MenuList = React.memo(({ ...menuProps }: any) => (
	<components.MenuList {...menuProps}>
		{menuProps.children}
		{menuProps.selectProps.additionalOption}
	</components.MenuList>
))

const SelectWrapper: React.FC<any> = (props: any) => {
	const { formatMessage } = useIntl()
	const {
		labeled,
		isLoading,
		labeledAbsolute,
		label,
		styles: parentStyles,
		placeholder,
		components,
		error,
		additionalOption,
		selectRef,
	} = props

	return labeled || labeledAbsolute ? (
		<div className={styles.labeled_container}>
			<div className={cn(styles.label, labeledAbsolute && styles.absolute)}>{label}</div>

			<Select
				{...props}
				ref={selectRef}
				isLoading={isLoading}
				styles={{ ...initialStyles, ...parentStyles }}
				placeholder={placeholder || formatMessage(commonMessages.select)}
				classNamePrefix="react-select-custom"
				error={error}
				additionalOption={additionalOption}
				components={{
					DropdownIndicator,
					ClearIndicator,
					MenuList,
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore-start
					...components,
				}}
			/>
		</div>
	) : (
		<Select
			{...props}
			ref={selectRef}
			isLoading={isLoading}
			error={error}
			styles={{ ...initialStyles, ...parentStyles }}
			placeholder={label || formatMessage(commonMessages.select)}
			classNamePrefix="react-select-custom"
			additionalOption={additionalOption}
			components={{
				DropdownIndicator,
				ClearIndicator,
				MenuList,
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore-start
				...components,
			}}
		/>
	)
}

export default SelectWrapper

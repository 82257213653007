import { useLocation } from "react-router-dom";

import { AccountTypeEnum } from "types/account";

/**
 * Returns the account type from the URL query parameters.
 * If the account type is not found or is invalid, it returns the default account type.
 * @returns {AccountTypeEnum} The account type from the URL query parameters or the default account type.
 */

const useAccountType = (): AccountTypeEnum => {
	const queryParams = new URLSearchParams(useLocation().search);
	const typeFromUrl = queryParams.get("type") as AccountTypeEnum | null | undefined;

	if (!typeFromUrl || !Object.values(AccountTypeEnum).includes(typeFromUrl)) {
		return AccountTypeEnum.SPOT;
	}

	return typeFromUrl;
};

export default useAccountType;

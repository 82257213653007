import { smoothScroll } from "helpers/global";
import { useCallback, useEffect, useRef, useState } from "react";

const distance = 300;
const duration = 300;

const useScrollArrows = (
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	dependencies: ReadonlyArray<any> | undefined = [],
	isScrollWidth: boolean | undefined = false,
	isOffsetWidth: boolean | undefined = false,
) => {
	const wrapperRef = useRef<HTMLDivElement | null>(null);
	const [showArrows, setShowArrows] = useState(false);
	const [showLeftArrow, setShowLeftArrow] = useState<boolean>(false);
	const [showRightArrow, setShowRightArrow] = useState<boolean>(true);
	const scrollDependencies = [
		...dependencies,
		isScrollWidth ? wrapperRef.current?.scrollWidth : undefined,
	];
	const resizeDependencies = [
		...dependencies,
		isOffsetWidth ? wrapperRef.current?.offsetWidth : undefined,
		isScrollWidth ? wrapperRef.current?.scrollWidth : undefined,
	];

	const handleResize = () => {
		if (wrapperRef.current) {
			const isOverflowing = wrapperRef.current.offsetWidth < wrapperRef.current.scrollWidth;

			if (isOverflowing) {
				handleScroll();
			}

			setShowArrows(isOverflowing);
		}
	};

	const handleScroll = () => {
		if (wrapperRef.current) {
			const { scrollLeft, scrollWidth, offsetWidth } = wrapperRef.current;
			const roundScrollLeft = Math.round(scrollLeft);

			setShowLeftArrow(roundScrollLeft > 0);

			setShowRightArrow(roundScrollLeft + 5 < scrollWidth - offsetWidth);
		}
	};

	const handleArrowLeftClick = useCallback(() => {
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		smoothScroll(wrapperRef.current!, wrapperRef.current!.scrollLeft - distance, duration);
	}, [wrapperRef.current, distance, duration]);

	const handleArrowRightClick = useCallback(() => {
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		smoothScroll(wrapperRef.current!, wrapperRef.current!.scrollLeft + distance, duration);
	}, [wrapperRef.current, distance, duration]);

	useEffect(() => {
		handleScroll();

		if (wrapperRef.current) {
			wrapperRef.current.addEventListener("scroll", handleScroll, { passive: true });
		}

		return () => {
			if (wrapperRef.current) {
				wrapperRef.current.removeEventListener("scroll", handleScroll);
			}
		};
	}, scrollDependencies);

	useEffect(() => {
		handleResize();

		window.addEventListener("resize", handleResize, { passive: true });
		return () => window.removeEventListener("resize", handleResize);
	}, resizeDependencies);

	return {
		ref: wrapperRef,
		handleArrowLeftClick,
		showArrows,
		showLeftArrow,
		handleArrowRightClick,
		showRightArrow,
	};
};

export default useScrollArrows;

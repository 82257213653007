import { defineMessages } from "react-intl";

export default defineMessages({
	restore_twofa_title: {
		id: "app.containers.auth.restore.twofa_title",
		defaultMessage: "2FA Authenticator Setup",
	},
	restore_twofa_subtitle: {
		id: "app.containers.auth.restore.twofa_subtitle",
		defaultMessage: "Configuration of 2FA Authenticator.",
	},
	restore_twofa_description: {
		id: "app.containers.auth.restore.twofa_description",
		defaultMessage:
			"This QR code can be scanned by one of these two applications. {important_text}",
	},
	restore_twofa_description_important_text: {
		id: "app.containers.auth.restore.twofa_description_important_text",
		defaultMessage:
			"In the future, the application will be used constantly as a mandatory security measure for each login to the platform. (Do not uninstall this app!)",
	},
	restore_twofa_enter_code: {
		id: "app.containers.auth.restore.twofa_enter_code",
		defaultMessage: "Enter the code generated by ALP2FA or Google Authentificathor",
	},
	restore_new_password_title: {
		id: "app.containers.auth.restore.new_password_title",
		defaultMessage: "Setting a new password",
	},
	restore_new_password_subtitle: {
		id: "app.containers.auth.restore.new_password_subtitle",
		defaultMessage: "Please create a new password.",
	},
	restore_new_password_important: {
		id: "app.containers.auth.restore.new_password_important",
		defaultMessage: "Never use your old password!",
	},
	restore_success: {
		id: "app.containers.auth.restore.success",
		defaultMessage: "You have successfully restored access to your account",
	},
	error: {
		id: "app.containers.auth.Error",
		defaultMessage: "Error from server",
	},
	status: {
		id: "app.containers.auth.status",
		defaultMessage: "Status:",
	},
	status_text: {
		id: "app.containers.auth.status_text",
		defaultMessage: "Status text:",
	},
	reset_password_desc: {
		id: "app.containers.auth.reset_password_desc",
		defaultMessage: "Enter your email address below",
	},
	reset_password_confirmation_header: {
		id: "app.containers.auth.reset_password_confirmation_header",
		defaultMessage: "Password Reset",
	},
	reset_password_confirmation_description: {
		id: "app.containers.auth.reset_password_confirmation_description",
		defaultMessage: "Please enter your new password below.",
	},
	reset_password_confirmation_success: {
		id: "app.containers.auth.reset_password_confirmation_success",
		defaultMessage: "You have successfully changed your password. Now you can log in.",
	},
	reset_password_confirmation_input_placeholder: {
		id: "app.containers.auth.reset_password_confirmation_input_placeholder",
		defaultMessage: "Password",
	},
	reset_password_confirmation_input_placeholder2: {
		id: "app.containers.auth.reset_password_confirmation_input_placeholder2",
		defaultMessage: "Password Confirmation",
	},
});

import React, { useEffect, useState } from "react"
import { observer } from "mobx-react-lite"
import Skeleton from "react-loading-skeleton"
import dayjs, { unix } from "dayjs"
import { useIntl } from "react-intl"

import { useCoinDetails } from "../../../../../../hooks/useCoinDetails"
import CoinMarketCapService from "../../../../../../services/CoinMarketCapService"
import errorHandler from "../../../../../../utils/errorHandler"
import { useMst } from "../../../../../../models/Root"
import InfoLink from "../../../../../../assets/icons/terminal/InfoLink"
import {
	ICurrency,
	IMarketCapCoin,
	IMarketCapCoinInfo,
} from "../../../../../../types/coinmarketcap"
import styles from "../../index.module.scss"
import messages from "../../../../../../messages/exchange"
import { formatCryptoCurrencyPrice } from "../../../../../../utils/format"
import { ThemeEnum } from "../../../../../../types/theme"
import CoinLinkDarkIcon from "../../../../../../assets/icons/coin-link-dark.svg"
import CoinLinkLightIcon from "../../../../../../assets/icons/coin-link-light.svg"

const CoinInfo: React.FC = () => {
	const {
		terminal: { pair, baseCurrencyCode, baseCurrencyName },
	} = useMst()
	const [marketCapCoin, setMarketCapCoin] = useState<IMarketCapCoin>()
	const [coinInfo, setCoinInfo] = useState<IMarketCapCoinInfo>()
	const [usd, setUSD] = useState<ICurrency>()
	const { formatMessage, formatNumber } = useIntl()

	const { coinLinks } = useCoinDetails(coinInfo)

	const loadCoinMarketCap = async () => {
		try {
			if (!baseCurrencyCode) return
			const { data } = await CoinMarketCapService.getCoin({ symbol: baseCurrencyCode })
			if (!data[baseCurrencyCode]) return
			setMarketCapCoin(data[baseCurrencyCode][0])
			setUSD(data[baseCurrencyCode][0].quote?.USD)
		} catch (err) {
			errorHandler(err)
		}
	}

	const loadCoinMeta = async () => {
		try {
			if (!baseCurrencyCode) return
			const { results } = await CoinMarketCapService.getCoinInfo({
				symbol: baseCurrencyCode,
			})
			if (!results[0]) return
			setCoinInfo(results[0])
		} catch (err) {
			errorHandler(err)
		}
	}

	const initializeCoin = async () => {
		try {
			if (!baseCurrencyCode) return
			await loadCoinMarketCap()
			await loadCoinMeta()
		} catch (err) {
			errorHandler(err)
		}
	}

	useEffect(() => {
		initializeCoin().then(() => null)
	}, [baseCurrencyCode])

	return (
		<div className={styles.trading__infoModalWrapper}>
			<div className={styles.trading__infoName}>
				{pair?.base_currency_name ?? (
					<div className={styles.trading__skeleton}>
						<Skeleton width={44} height={16} borderRadius={4} />
					</div>
				)}
			</div>

			<div className={styles.trading__infoModal}>
				<div className={styles.trading__infoModalHeader}>
					{coinInfo?.logo ? (
						<img
							className={styles.trading__infoModalImg}
							src={coinInfo?.logo}
							alt={`${baseCurrencyName}-logo`}
						/>
					) : (
						<div className={styles.trading__infoModalImage} />
					)}

					<div className={styles.trading__infoModalNameWrapper}>
						<p className={styles.trading__infoModalName}>
							{baseCurrencyCode !== "" ? baseCurrencyCode : "--"}
						</p>
						<p className={styles.trading__infoModalFullName}>
							{baseCurrencyName !== "" ? baseCurrencyName : "--"}
						</p>
					</div>
				</div>

				<div className={styles.trading__infoModalTableData}>
					<div className={styles.trading__infoModalColumn}>
						<p className={styles.trading__infoModalColumnHeadline}>
							{formatMessage(messages.release_date)}
						</p>
						<p className={styles.trading__infoModalColumnText}>
							{marketCapCoin?.date_added
								? dayjs(marketCapCoin?.date_added).format("YYYY-MM-DD")
								: "--"}
						</p>
					</div>

					<div className={styles.trading__infoModalColumn}>
						<p className={styles.trading__infoModalColumnHeadline}>
							{formatMessage(messages.supply_limit)}
						</p>
						<p className={styles.trading__infoModalColumnText}>
							{marketCapCoin?.max_supply ? formatNumber(marketCapCoin?.max_supply) : "--"}
						</p>
					</div>

					<div className={styles.trading__infoModalColumn}>
						<p className={styles.trading__infoModalColumnHeadline}>
							{formatMessage(messages.market_cap)}
						</p>
						<p className={styles.trading__infoModalColumnText}>
							{usd?.market_cap ? formatCryptoCurrencyPrice(usd?.market_cap) : "--"}
						</p>
					</div>
				</div>

				<ul className={styles.trading__infoModalLinks}>
					{coinLinks.map(({ links }, index) => (
						<li key={index} className={styles.trading__infoModalLink}>
							<a
								href={links[0]}
								className={styles.trading__infoModalLinkText}
								target="_blank"
								rel="noopener noreferrer"
							>
								<InfoLink />

								<span>{`${links[0].split("/")[2]}`}</span>
							</a>
						</li>
					))}
				</ul>

				<p className={styles.trading__infoModalContent}>
					{coinInfo?.description ? coinInfo?.description : "--"}
				</p>
			</div>
		</div>
	)
}

export default observer(CoinInfo)

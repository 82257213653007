import React, { useCallback, useEffect } from "react"
import { observer } from "mobx-react-lite"
import { useIntl } from "react-intl"

import { useMst } from "models/Root"
import Input from "../Input"
import Header from "../Header"
import Button from "../Button"
import styles from "./RegPersonalForm.module.scss"
import CountrySelect from "../../CountrySelect/CountrySelect"

const RegPersonalForm: React.FC = () => {
	const { formatMessage } = useIntl()
	const {
		register: {
			resetRegisterPersonalState,
			onSubmitPersonalStep,
			isLoading,
			errors,
			personalFirstName,
			setPersonalLastName,
			personalLastName,
			setPersonalFirstName,
			countryCode,
			setCountryCode,
		},
		global: { locale },
	} = useMst()

	const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault()

		onSubmitPersonalStep(formatMessage).then(() => null)
	}

	const handleCountryChange = useCallback((_: any, value: any) => {
		setCountryCode(value ?? "")
	}, [])

	useEffect(() => () => resetRegisterPersonalState(), [])

	return (
		<div className={styles.regPersonalForm}>
			<Header
				headline="Almost Done"
				description="Please complete the form to create your account."
			/>

			<div className={styles.regPersonalForm__wrapper}>
				<form onSubmit={onSubmit}>
					<div className={styles.regPersonalForm__inputWrapper}>
						<Input
							id="reg-firstname"
							label="First Name"
							handleChange={setPersonalFirstName}
							error={errors.personalFirstName}
							value={personalFirstName}
							autoFocus
							placeholder="First Name"
						/>

						<Input
							id="reg-lastname"
							label="Last Name"
							handleChange={setPersonalLastName}
							error={errors.personalLastName}
							value={personalLastName}
							placeholder="Last Name"
						/>
					</div>

					<div className={styles.regPersonalForm__countryWrapper}>
						<CountrySelect
							value={countryCode}
							label="Country Residence"
							name="residence"
							locale={locale}
							onSelect={handleCountryChange}
							error={errors.countryCode}
						/>
					</div>

					<div className={styles.regPersonalForm__buttonWrapper}>
						<Button isLoading={isLoading} type="submit">
							Finish
						</Button>
					</div>
				</form>
			</div>
		</div>
	)
}

export default observer(RegPersonalForm)

import { useLocation, useNavigate } from "react-router-dom";

import usePathname from "hooks/usePathname";
import { queryVars } from "constants/query";
import { useMst } from "models/Root";
import { appLocales, availableLocales } from "providers/LanguageProvider/i18n";

const useLanguages = () => {
	const { search } = useLocation();
	const navigate = useNavigate();
	const pathname = usePathname();
	const { global } = useMst();

	// Language change function
	const handleLocaleChange = (lang: string): void => {
		global.setLocale(lang);

		const nextPath = [...availableLocales, ""].includes(pathname) ? "" : pathname;

		navigate({
			[queryVars.pathname]: `/${lang}/${nextPath}`,
			[queryVars.search]: search,
		});
	};

	return { appLocales, handleLocaleChange, globalLocale: global.locale };
};

export default useLanguages;

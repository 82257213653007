import React from "react";
import { observer } from "mobx-react-lite";
import { useIntl } from "react-intl";

import styles from "components/NewUI/Terminal/Bottom/SignInSignUp/index.module.scss";
import { routes } from "constants/routing";
import commonMessages from "messages/common";
import InternalLink from "components/InternalLink";

const SignInSignUp: React.FC = () => {
	const { formatMessage } = useIntl();

	return (
		<div className={styles.signInSignUp}>
			<span className={styles.signInSignUp__text}>Please</span>

			<InternalLink to={routes.login.root} className={styles.signInSignUp__button}>
				{formatMessage(commonMessages.login)}
			</InternalLink>

			<span className={styles.signInSignUp__text}>or</span>

			<InternalLink
				to={routes.register.root}
				className={`${styles.signInSignUp__button} ${styles.signInSignUp__button___signUp}`}
			>
				{formatMessage(commonMessages.register)}
			</InternalLink>

			<span className={styles.signInSignUp__text}>first.</span>
		</div>
	);
};

export default observer(SignInSignUp);

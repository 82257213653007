export const queryVars = {
	page: "page",
	page_size: "page-size",
	ordering: "ordering",
	wallet_type: "wallet_type",
	is_favorite: "is_favorite",
	type: "type",
	search: "search",
	sub_account: "sub_account",
	account: "account",
	date: "date",
	date_after: "date_after",
	date_before: "date_before",
	desc: "desc",
	asc: "asc",
	pathname: "pathname",
	sort: "sort",
	layout: "layout",
	currency: "currency",
	pair: "pair",
	amount: "amount",
	amount_min: "amount_min",
	direction: "direction",
	is_redeemed: "is_redeemed",
	percent_change_24h: "percent_change_24h",
	value: "value",
	redeemed_at: "redeemed_at",
	subscribed_at: "subscribed_at",
	side: "side",
	currency_id: "currency_id",
	is_outgoing: "is_outgoing",
	is_outdated: "is_outdated",
	is_familiar: "is_familiar",
	status: "status",
	is_active: "is_active",
	order_id: "order_id",
	payment_type_name: "payment_type_name",
	email: "email",
	curr: "curr", // TODO: Rename it on back to "currency"
	out: "out",
	in: "in",
	aff_sub: "aff_sub",
	aff_id: "aff_id",
	utm_campaign: "utm_campaign",
	vote: "vote",
	category: "category",
	position_id: "position_id",
	seller_id: "seller_id",
	quote_currency: "quote_currency",
	base_currency: "base_currency",
	payment_methods: "payment_methods",
	payment_method: "payment_method",
	payment_time: "payment_time",
	regions: "regions",
	region: "region",
	is_merchant: "is_merchant",
	id: "id",
	price: "price",
	price_type: "price_type",
	limit: "limit",
	minimal: "minimal",
	maximum: "maximum",
	deal: "deal",
	reason: "reason",
	comment: "comment",
	order: "order",
	accept: "accept",
	attributes: "attributes",
	tab: "tab",
	is_positive: "is_positive",
	payment_requisites: "payment_requisites",
	requisites: "requisites",
	terms: "terms",
	is_anonymous: "is_anonymous",
	text: "text",
	owner: "owner",
	target: "target",
	is_blocked: "is_blocked",
	telegram: "telegram",
	proof: "proof",
	video: "video",
	all: "all",
	name: "name",
} as const;

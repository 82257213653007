import React from "react"
import { observer } from "mobx-react-lite"

import styles from "components/NewUI/Terminal/Bottom/OpenOrders/List/index.module.scss"
import { TooltipArrowEnum, TooltipModal } from "../../../../../TooltipModal"

const HeadlineItem: React.FC<{
	headline: string
	width: number
	isModal?: boolean
	modalContent?: string
	modalWidth?: number
}> = ({ headline, width, isModal = false, modalContent, modalWidth }) => (
	<li className={styles.list__headlineText} style={{ minWidth: `${width}px` }}>
		{isModal ? (
			<TooltipModal
				arrowDirection={TooltipArrowEnum.TOP_LEFT}
				content={<span>{modalContent}</span>}
				width={modalWidth}
			>
				<span className={styles.list__headlineTextUnderline}>{headline}</span>
			</TooltipModal>
		) : (
			headline
		)}
	</li>
)

export default observer(HeadlineItem)

import { types as t, Instance, getSnapshot, applySnapshot, cast } from "mobx-state-tree";

import { ICreateOrderBody } from "types/exchange";
import { Order, IOrder } from "./order";

const OrderType = t.model("Order", {
	type: t.string,
	side: t.string,
	symbol: t.string,
	amount: t.maybe(t.string),
	price: t.maybe(t.string),
	pair: t.maybe(t.string),
	side_effect: t.maybe(t.string),
	stop_price: t.maybe(t.string),
	stop_operator: t.maybe(t.string),
	wallet_type: t.maybe(t.string),
	quote_amount: t.maybe(t.string),
});

export const TradeForm = t
	.model({
		clickedOrder: t.optional(t.maybeNull(Order), null),

		// form data
		orderPrice: t.optional(t.string, ""),
		marginLvl: t.optional(t.number, 2), // 2 its minimum for margin
		orderQty: t.optional(t.string, ""),
		orderValue: t.optional(t.string, ""),
		triggerPrice: t.optional(t.string, ""),
		rangePercentage: t.optional(t.number, 0),
		order: t.optional(t.maybeNull(OrderType), null),
		isOpenStopOrder: false,
		isOpenMarginChangeModal: false,
		// end form data
	})
	.actions((self: any) => {
		const initialState = getSnapshot(self);

		return {
			resetState() {
				applySnapshot(self, initialState);
			},
		};
	})
	.actions((self: any) => ({
		resetForm() {
			self.orderQty = "";
			self.orderValue = "";
			self.rangePercentage = 0;
			self.order = null;
			self.clickedOrder = null;
			self.isOpenStopOrder = false;
			self.isOpenMarginChangeModal = false;
		},
	}))
	.views((self: any) => ({
		get orderPriceString() {
			return self.orderPrice;
		},
		get orderQtyString() {
			return self.orderQty;
		},
		get orderValueString() {
			return self.orderValue;
		},
		get triggerPriceString() {
			return self.triggerPrice;
		},
	}))
	.actions((self: any) => ({
		setClickedOrder(nextOrder: IOrder | null) {
			if (nextOrder === null) {
				self.clickedOrder = null;
				return;
			}

			self.clickedOrder = cast({ ...nextOrder });
		},
		setOrder(nextOrder: ICreateOrderBody | null) {
			if (nextOrder === null) {
				self.isOpenStopOrder = false;
				self.order = null;
				return;
			}

			self.isOpenStopOrder = true;
			self.order = cast({ ...nextOrder });
		},
		setOrderPrice(str: string) {
			self.orderPrice = str;
		},
		setMarginLvl(num: number) {
			self.marginLvl = num;
		},
		setOpenStopOrder(bool: boolean) {
			self.isOpenStopOrder = bool;
		},
		setOpenMarginChangeModal(bool: boolean) {
			self.isOpenMarginChangeModal = bool;
		},
		setOrderQty(str: string) {
			self.orderQty = str;
		},
		setOrderValue(str: string) {
			self.orderValue = str;
		},
		setTriggerPrice(str: string) {
			self.triggerPrice = str;
		},
		setRangePercentage(num: number) {
			self.rangePercentage = num;
		},
	}));

export interface ITradeForm extends Instance<typeof TradeForm> {}

import React from "react";
import { observer } from "mobx-react-lite";
import { useIntl } from "react-intl";

import styles from "components/NewUI/Terminal/FunctionalPanel/BuySellToggle/index.module.scss";
import { TradeActionsEnum, TradeTypeEnum } from "types/exchange";
import messages from "messages/exchange";

const BuySellToggle: React.FC<{
	tradeType: TradeTypeEnum;
	tradeAction: TradeActionsEnum;
	setTradeAction: (nextTradeAction: TradeActionsEnum) => void;
}> = ({ tradeType, tradeAction, setTradeAction }) => {
	const { formatMessage } = useIntl();

	const onChangeBuyRadio = () => setTradeAction(TradeActionsEnum.BUY);

	const onChangeSellRadio = () => setTradeAction(TradeActionsEnum.SELL);

	// The positions of the elements are crucial, as they are styled in a hierarchical order. If you change the HTML, also adjust the CSS.
	return (
		<div className={styles.buySellToggle}>
			<input
				className={`visually-hidden ${styles.buySellToggle__toggleRadio}`}
				type="radio"
				name="BuySellToggle"
				id="BuyRadio"
				value="BuyRadio"
				onChange={onChangeBuyRadio}
				checked={tradeAction === TradeActionsEnum.BUY}
			/>

			<input
				className={`visually-hidden ${styles.buySellToggle__toggleRadio}`}
				type="radio"
				name="BuySellToggle"
				id="SellRadio"
				value="SellRadio"
				onChange={onChangeSellRadio}
				checked={tradeAction === TradeActionsEnum.SELL}
			/>

			<div className={styles.buySellToggle__wrapper}>
				{/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
				<label htmlFor="BuyRadio" className={styles.buySellToggle__button}>
					{formatMessage(tradeType === TradeTypeEnum.SPOT ? messages.buy : messages.long)}
				</label>

				{/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
				<label htmlFor="SellRadio" className={styles.buySellToggle__button}>
					{formatMessage(tradeType === TradeTypeEnum.SPOT ? messages.sell : messages.short)}
				</label>

				<div className={styles.buySellToggle__background} />
			</div>
		</div>
	);
};

export default observer(BuySellToggle);

import React, { memo } from "react";

const InfoLink = () => (
	<svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M8.517 1.834a4 4 0 015.656 5.656l-.008.008-2 2a4 4 0 01-6.032-.432.667.667 0 111.067-.799 2.667 2.667 0 004.022.288l1.995-1.995a2.667 2.667 0 00-3.77-3.77L8.302 3.926a.667.667 0 11-.94-.945L8.51 1.84l.007-.007z"
			fill="#fff"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M5.266 5.582a4 4 0 014.601 1.352.667.667 0 01-1.068.799 2.667 2.667 0 00-4.021-.288L2.782 9.44a2.667 2.667 0 003.77 3.77l1.136-1.135a.667.667 0 01.943.943l-1.14 1.14-.008.008A4 4 0 011.827 8.51l.008-.008 2-2a4 4 0 011.43-.92z"
			fill="#fff"
		/>
	</svg>
);

export default memo(InfoLink);

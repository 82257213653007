import { observable } from "mobx";
import config from "helpers/config";

export interface IOrderBookOrder {
	amount: number;
	amount2: number;
	key: string;
	last_update: number;
	orderDepth: number;
	price: number;
	progress: string;
	unique: boolean;
}

type DataItem = {
	amount: number;
	amount2: number;
	orderDepth: number;
	price: number;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	[key: string]: any;
};

export const orderBook = observable({
	buy: [] as IOrderBookOrder[],
	sell: [] as IOrderBookOrder[],
	update(buy: IOrderBookOrder[], sell: IOrderBookOrder[]) {
		this.buy = buy;
		this.sell = sell;
	},
	get maxViewSize() {
		return typeof +config.maxOrderBookSize === "number" ? +config.maxOrderBookSize : 100;
	},
	get totalBuy() {
		return this.buy.reduce((a: number, b: IOrderBookOrder) => +a + b.amount * b.price, 0); // quote amount
	},
	get totalSell() {
		return this.sell.reduce((a: number, b: IOrderBookOrder) => +a + b.amount, 0); // base amount
	},
	getFirst(isSell: boolean) {
		const list = isSell ? this.sell : this.buy;

		return list.length ? list[0].price : null;
	},
	getList(isSell: boolean, orderBookPrecision: number, keys: string[]) {
		let factor: number;
		let amount2Accum = 0;
		let amountAccum = 0;
		const list = isSell ? this.sell : this.buy;

		if (orderBookPrecision > 0) {
			factor = 10 ** orderBookPrecision;
		} else if (orderBookPrecision < 0) {
			factor = 1 / 10 ** Math.abs(orderBookPrecision);
		} else {
			factor = 1;
		}

		const openedOrdersKeys = keys.map((item) => Math.round(Number(item) * factor) / factor);

		const groupedData: Record<number, DataItem> = {};

		for (const item of list) {
			if (
				// eslint-disable-next-line no-prototype-builtins
				!item.hasOwnProperty("price") ||
				// eslint-disable-next-line no-prototype-builtins
				!item.hasOwnProperty("amount") ||
				// eslint-disable-next-line no-prototype-builtins
				!item.hasOwnProperty("amount2") ||
				// eslint-disable-next-line no-prototype-builtins
				!item.hasOwnProperty("orderDepth")
			) {
				// eslint-disable-next-line no-continue
				continue; // пропустить элементы, не содержащие необходимые свойства
			}

			// Группируем по price
			const groupedPrice = Math.round(item.price * factor) / factor;

			if (!groupedData[groupedPrice]) {
				groupedData[groupedPrice] = {
					price: groupedPrice,
					amount: 0,
					amountAccum: 0,
					amount2: 0,
					orderDepth: 0,
					amount2Accum: 0,
					key: "",
					last_update: 0,
					progress: "",
					unique: false,
					isUserOrder: false,
				};
			}

			amount2Accum += +item.amount2;
			amountAccum += +item.amount;
			groupedData[groupedPrice].amount += +item.amount;
			groupedData[groupedPrice].amount2Accum = amount2Accum;
			groupedData[groupedPrice].amount2 = item.amount2;
			groupedData[groupedPrice].amountAccum = amountAccum;
			groupedData[groupedPrice].orderDepth = item.orderDepth;
			groupedData[groupedPrice].key = item.key;
			groupedData[groupedPrice].last_update = item.last_update;
			groupedData[groupedPrice].progress = item.progress;
			groupedData[groupedPrice].unique = item.unique;
			groupedData[groupedPrice].isUserOrder = openedOrdersKeys.includes(+groupedPrice);
		}

		if (isSell) {
			return Object.values(groupedData)
				.sort((a, b) => a.price - b.price)
				.slice(0, 32);
		}

		return Object.values(groupedData)
			.sort((a, b) => a.price - b.price)
			.reverse()
			.slice(0, 32);
	},
});

import React, { useCallback, useEffect } from "react";

export const useOnClickOutside = (
	ref: React.MutableRefObject<HTMLElement | null>,
	handler: (event: React.MouseEvent) => void,
) => {
	const listener = useCallback(
		(event: MouseEvent | TouchEvent) => {
			// Ensure event.target is an instance of Node for the contains check
			if (!(event.target instanceof Node) || !ref.current || ref.current.contains(event.target)) {
				return;
			}

			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			handler(event as any);
		},
		[ref, handler],
	);

	useEffect(() => {
		document.addEventListener("mousedown", listener, { passive: true });
		document.addEventListener("touchstart", listener, { passive: true });

		return () => {
			document.removeEventListener("mousedown", listener);
			document.removeEventListener("touchstart", listener);
		};
	}, [listener]); // Adding listener to the dependencies
};

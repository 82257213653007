import { types as t, Instance } from "mobx-state-tree";
import { TerminalModelNamesEnum } from "types/models";

export const Order = t.model(TerminalModelNamesEnum.ORDER, {
	amount: t.number,
	amount2: t.number,
	key: t.identifier,
	last_update: t.number,
	orderDepth: t.number,
	price: t.number,
	progress: t.string,
	unique: t.boolean,
});

export interface IOrder extends Instance<typeof Order> {}

import React from "react"
import { observer } from "mobx-react-lite"

import useHeader from "hooks/newUI/header/useHeader"
import styles from "components/NewUI/Header/index.module.scss"
import Navigation from "./Navigation"
import Logo from "./Logo"
import QRCode from "./QRCode"
import MobileBurger from "./MobileBurger"

const Header: React.FC<{ isLanding?: boolean }> = ({ isLanding = false }) => {
	const { navArray, logoLinkTo } = useHeader()

	return (
		<header className={styles.header}>
			<div
				className={`${styles.header__wrapper} ${isLanding ? styles.header__wrapper___landing : ""}`}
			>
				<Logo linkTo={logoLinkTo} />

				<Navigation navArray={navArray} />

				<QRCode disabled />

				<MobileBurger />
			</div>
		</header>
	)
}

export default observer(Header)

import React from "react";
import { observer } from "mobx-react-lite";

import usePositions from "hooks/newUI/terminal/usePositions";
import styles from "components/NewUI/Terminal/Bottom/Positions/index.module.scss";
import SignInSignUp from "../SignInSignUp";
import ListHeadline from "../ListHeadline";
import List from "./List";

const Positions: React.FC = () => {
	const {
		isAuthenticated,
		listHeadline,
		listScheme,
		list,
		pairAmountPrecision,
		pairPricePrecision,
		isTradeHistoryLoading,
	} = usePositions();

	return (
		<div className={styles.positions}>
			{isAuthenticated ? (
				<List
					list={list}
					listScheme={listScheme}
					pairAmountPrecision={pairAmountPrecision}
					pairPricePrecision={pairPricePrecision}
					isTradeHistoryLoading={isTradeHistoryLoading}
				/>
			) : (
				<>
					<ListHeadline list={listHeadline} listScheme={listScheme} />

					<SignInSignUp />
				</>
			)}
		</div>
	);
};

export default observer(Positions);

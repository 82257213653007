import React, { FC, memo, useCallback } from "react";

import styles from "components/NewUI/Header/Navigation/index.module.scss";
import LanguageCheck from "assets/icons/header/LanguageCheck";
import { LanguagesItemTypes } from "types/header";

const Item: FC<LanguagesItemTypes> = ({ label, value, isChecked, handleChange }) => {
	const onClick = useCallback(() => {
		handleChange(value);
	}, [value]);

	return (
		<li className={`${styles.nav__itemFirst} ${isChecked ? styles.nav__itemFirst___checked : ""}`}>
			<button type="button" onClick={onClick} className={styles.nav__langButton}>
				<span>{label}</span>

				{isChecked ? (
					<span className={styles.nav__langButtonCheck}>
						<LanguageCheck />
					</span>
				) : null}
			</button>
		</li>
	);
};

export default memo(Item);

import React, { useMemo, useEffect } from "react"
import { observer } from "mobx-react-lite"
import { useIntl } from "react-intl"
import Skeleton from "react-loading-skeleton"

import { useMst } from "models/Root"
import useScrollArrows from "hooks/newUI/terminal/useScrollArrows"
import Menu from "assets/icons/terminal/Menu"
import commonMessages from "messages/common"
import ArrowRight from "assets/icons/terminal/ArrowRight"
import messages from "messages/exchange"
import CoinInfo from "./CoinInfo"
import styles from "../index.module.scss"

// The functionality of this component is completely copied from the previous design. I believe it's worth considering rewriting and optimizing it in the future.
const Info: React.FC<{ openMenuAction: (ev: any) => void; isOpen: boolean }> = ({
	openMenuAction,
	isOpen,
}) => {
	const {
		ref,
		handleArrowLeftClick,
		showArrows,
		showLeftArrow,
		handleArrowRightClick,
		showRightArrow,
	} = useScrollArrows(undefined, false, true)
	const { formatNumber, formatMessage } = useIntl()
	const {
		terminal: {
			pair,
			loanConditions,
			loadLoanConditions,
			pairPricePrecision,
			pairAmountPrecision,
			marginLeverage,
			quoteCurrencyCode,
			baseCurrencyCode,
		},
		render: { margin: isMargin },
	} = useMst()
	const marginValue = useMemo(() => marginLeverage ?? 0, [marginLeverage])
	const formatPriceNumberOptions = useMemo(
		() => ({
			useGrouping: true,
			maximumFractionDigits: pairPricePrecision,
			minimumFractionDigits: pairPricePrecision,
		}),
		[pairPricePrecision],
	)
	const formatAmountNumberOptions = useMemo(
		() => ({
			useGrouping: true,
			maximumFractionDigits: pairAmountPrecision,
			minimumFractionDigits: pairAmountPrecision,
		}),
		[pairAmountPrecision],
	)
	const infoPrice = useMemo(() => {
		if (pair) return formatNumber(pair.close, formatPriceNumberOptions)

		return "0"
	}, [pair?.close, formatPriceNumberOptions, formatNumber])

	const hourlyInterestsRate = useMemo(
		() =>
			loanConditions && loanConditions.length && pair
				? `${formatNumber(
						(Number(
							// eslint-disable-next-line @typescript-eslint/no-explicit-any
							loanConditions?.find((item: any) => item?.currency?.code === pair.quote_currency_code)
								?.interest_rate,
						) *
							100) /
							24,
						{
							useGrouping: false,
							minimumFractionDigits: 2,
							maximumFractionDigits: 10,
						},
				  )}% | ${formatNumber(
						(Number(
							// eslint-disable-next-line @typescript-eslint/no-explicit-any
							loanConditions?.find((item: any) => item?.currency?.code === pair.base_currency_code)
								?.interest_rate,
						) *
							100) /
							24,
						{
							useGrouping: false,
							minimumFractionDigits: 2,
							maximumFractionDigits: 10,
						},
				  )}%`
				: undefined,
		[loanConditions, pair],
	)

	useEffect(() => {
		loadLoanConditions().then(() => null)
	}, [pair?.id])

	return (
		<div className={styles.trading__header}>
			<button
				type="button"
				className={styles.trading__menuButton}
				aria-label="Toggle search open"
				onClick={openMenuAction}
				disabled={isOpen}
			>
				<Menu />
			</button>

			<div className={styles.trading__info}>
				<div className={styles.trading__infoLeft}>
					<div className={styles.trading__infoHeadline}>
						{baseCurrencyCode !== "" ? (
							baseCurrencyCode
						) : (
							<div className={styles.trading__skeleton}>
								<Skeleton width={30} height={19} borderRadius={4} />
							</div>
						)}
						/
						{quoteCurrencyCode !== "" ? (
							quoteCurrencyCode
						) : (
							<div className={styles.trading__skeleton}>
								<Skeleton width={40} height={19} borderRadius={4} />
							</div>
						)}
					</div>

					<div className={styles.trading__infoContent}>
						<CoinInfo />

						{marginValue !== 0 && isMargin ? (
							<div className={styles.trading__infoIndicator}>{`${marginValue}x`}</div>
						) : null}
					</div>
				</div>

				<div className={styles.trading__infoRight}>
					<div
						className={`${styles.trading__infoPrice} ${
							pair?.change_percent.toString().charAt(0) === "-"
								? styles.trading__infoPrice___minus
								: ""
						}`}
					>
						{infoPrice !== "0" ? (
							`${pair?.change_percent.toString().charAt(0) !== "-" ? "" : ""}${infoPrice}`
						) : (
							<div className={styles.trading__skeleton}>
								<Skeleton width={72} height={18} borderRadius={4} />
							</div>
						)}
					</div>

					<div className={styles.trading__infoPrice2}>
						≈{" "}
						{infoPrice !== "0" ? (
							infoPrice
						) : (
							<div className={styles.trading__skeleton}>
								<Skeleton width={40} height={14} borderRadius={4} />
							</div>
						)}{" "}
						USD
					</div>
				</div>
			</div>

			<div className={styles.trading__listContainer}>
				<div ref={ref} className={styles.trading__listWrapper}>
					<button
						type="button"
						aria-label="to left"
						onClick={handleArrowLeftClick}
						className={`${styles.trading__listButtonLeft} ${
							showArrows && showLeftArrow ? styles.trading__listButtonLeft___show : ""
						}`}
					>
						<ArrowRight />
					</button>

					<button
						type="button"
						aria-label="to right"
						onClick={handleArrowRightClick}
						className={`${styles.trading__listButtonRight} ${
							showArrows && showRightArrow ? styles.trading__listButtonRight___show : ""
						}`}
					>
						<ArrowRight />
					</button>

					<ul className={styles.trading__list}>
						<li className={styles.trading__item}>
							<p className={styles.trading__itemTopText}>
								24H {formatMessage(commonMessages.change)}
							</p>

							<div
								className={`${styles.trading__itemValue} ${
									pair?.change_percent.toString().charAt(0) === "-"
										? styles.trading__itemValue___minus
										: ""
								}`}
							>
								{pair ? (
									`${formatNumber(pair.change_percent, {
										useGrouping: false,
										maximumFractionDigits: 2,
										minimumFractionDigits: 2,
									})}%`
								) : (
									<div className={styles.trading__skeleton}>
										<Skeleton width={37} height={15} borderRadius={4} />
									</div>
								)}
							</div>
						</li>

						<li className={styles.trading__item}>
							<p className={styles.trading__itemTopText}>
								24H {formatMessage(commonMessages.high)}
							</p>

							<div className={styles.trading__itemValue}>
								{pair ? (
									formatNumber(pair.high, formatPriceNumberOptions)
								) : (
									<div className={styles.trading__skeleton}>
										<Skeleton width={54} height={13} borderRadius={4} />
									</div>
								)}
							</div>
						</li>

						<li className={styles.trading__item}>
							<p className={styles.trading__itemTopText}>24H {formatMessage(commonMessages.low)}</p>

							<div className={styles.trading__itemValue}>
								{pair ? (
									formatNumber(pair.low, formatPriceNumberOptions)
								) : (
									<div className={styles.trading__skeleton}>
										<Skeleton width={54} height={13} borderRadius={4} />
									</div>
								)}
							</div>
						</li>

						<li className={styles.trading__item}>
							<div className={styles.trading__itemTopText}>
								24H {formatMessage(commonMessages.turnover)} (
								{quoteCurrencyCode !== "" ? (
									quoteCurrencyCode
								) : (
									<div className={styles.trading__skeleton}>
										<Skeleton width={35} height={13} borderRadius={2} />
									</div>
								)}
								)
							</div>

							<div className={styles.trading__itemValue}>
								{pair ? (
									formatNumber(pair.base_volume * pair.close, formatPriceNumberOptions)
								) : (
									<div className={styles.trading__skeleton}>
										<Skeleton width={84} height={13} borderRadius={4} />
									</div>
								)}
							</div>
						</li>

						<li className={styles.trading__item}>
							<div className={styles.trading__itemTopText}>
								24H {formatMessage(commonMessages.volume)} (
								{baseCurrencyCode !== "" ? (
									baseCurrencyCode
								) : (
									<div className={styles.trading__skeleton}>
										<Skeleton width={35} height={13} borderRadius={2} />
									</div>
								)}
								)
							</div>

							<div className={styles.trading__itemValue}>
								{pair ? (
									formatNumber(pair.base_volume, formatAmountNumberOptions)
								) : (
									<div className={styles.trading__skeleton}>
										<Skeleton width={48} height={13} borderRadius={4} />
									</div>
								)}
							</div>
						</li>

						{isMargin ? (
							<li className={styles.trading__item}>
								<div className={styles.trading__itemTopText}>
									{formatMessage(messages.borrow_hourly_rate)} (
									{quoteCurrencyCode !== "" ? (
										quoteCurrencyCode
									) : (
										<div className={styles.trading__skeleton}>
											<Skeleton width={35} height={13} borderRadius={2} />
										</div>
									)}
									/
									{baseCurrencyCode !== "" ? (
										baseCurrencyCode
									) : (
										<div className={styles.trading__skeleton}>
											<Skeleton width={35} height={13} borderRadius={2} />
										</div>
									)}
									)
								</div>

								<div className={styles.trading__itemValue}>
									{hourlyInterestsRate !== undefined ? (
										hourlyInterestsRate
									) : (
										<div className={styles.trading__skeleton}>
											<Skeleton width={130} height={13} borderRadius={4} />
										</div>
									)}
								</div>
							</li>
						) : null}
					</ul>
				</div>
			</div>
		</div>
	)
}

export default observer(Info)

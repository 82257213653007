import React, { memo } from "react";

const Deposit = () => (
	<svg width={21} height={20} fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M6.521 12.057c-.144-.144-.216-.216-.3-.267a.833.833 0 00-.241-.1c-.096-.023-.198-.023-.402-.023h-.635c-.253 0-.472 0-.668.03a2.5 2.5 0 00-2.078 2.079c-.031.196-.031.414-.031.668v.125c0 .397 0 .692.041.953a3.333 3.333 0 002.771 2.77c.26.042.555.042.953.041h9.137c.398 0 .692 0 .953-.04a3.333 3.333 0 002.77-2.771c.042-.261.042-.556.042-.953v-.125c0-.254 0-.472-.031-.668a2.5 2.5 0 00-2.078-2.078 4.392 4.392 0 00-.669-.031h-.634c-.204 0-.306 0-.402.023a.834.834 0 00-.241.1c-.084.051-.156.123-.3.267l-2.21 2.21a2.5 2.5 0 01-3.536 0l-2.21-2.21z"
			fill="#fff"
		/>
		<path
			d="M11.333 2.5a.833.833 0 10-1.667 0v7.988L6.922 7.744a.833.833 0 10-1.179 1.179l4.167 4.166a.833.833 0 001.179 0l4.166-4.166a.833.833 0 10-1.178-1.179l-2.744 2.744V2.5z"
			fill="#fff"
		/>
	</svg>
);

export default memo(Deposit);

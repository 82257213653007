import React, { useCallback } from "react"
import { useIntl } from "react-intl"
import { observer } from "mobx-react-lite"

import Star from "assets/icons/terminal/Star"
import styles from "../index.module.scss"

const Item: React.FC<{
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	item: any
	handleLinkClick: (name: string) => void
	handleTickerFavoriteClick: (symbol: string, isFavorite: boolean) => void
	isFiat: boolean
	isMargin: boolean
}> = ({ item, handleLinkClick, handleTickerFavoriteClick, isFiat, isMargin }) => {
	const { formatNumber } = useIntl()
	const fixedPercent = +item.change_percent.toFixed(2)
	const changePercent = fixedPercent < 0.01 && fixedPercent > -0.01 ? 0 : fixedPercent

	const onClickLink = useCallback(() => handleLinkClick(item.symbol), [item.symbol])

	const onClickFavorite = useCallback(() => {
		item.setIsFavorite(!item.is_favorite)
		handleTickerFavoriteClick(item.symbol, !item.is_favorite)
	}, [item.symbol, item.is_favorite])

	return (
		<li className={styles.search__coinsItem}>
			<button
				onClick={onClickFavorite}
				className={styles.search__favoriteButton}
				type="button"
				aria-label={`Toggle favorite ${item.label} pair`}
			>
				<Star isChecked={item.is_favorite} />
			</button>

			<button onClick={onClickLink} type="button" className={styles.search__coinLink}>
				<div className={styles.search__coinName}>
					<span className={styles.search__coinNameText}>
						{!isFiat ? item.base_currency_code : item.label}
					</span>

					{item.cross_margin_leverage > 0 && isMargin ? (
						<span className={styles.search__coinNameIndicator}>{item.cross_margin_leverage}x</span>
					) : null}
				</div>

				<span className={styles.search__coinPrice}>
					{formatNumber(item.close, {
						minimumFractionDigits: item.price_precision ?? 0,
						maximumFractionDigits: item.price_precision ?? 8,
					})}
				</span>

				<span
					className={`${styles.search__coinPercent} ${
						changePercent > 0 ? styles.search__coinPercent___green : ""
					} ${changePercent < 0 ? styles.search__coinPercent___red : ""}`}
				>
					{`${changePercent > 0 ? "+" : ""}${formatNumber(changePercent, {
						useGrouping: false,
						minimumFractionDigits: 2,
						maximumFractionDigits: 2,
					})} %`}
				</span>
			</button>
		</li>
	)
}

export default observer(Item)

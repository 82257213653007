import React from "react";
import { useIntl } from "react-intl";
import { Helmet } from "react-helmet";
import { observer } from "mobx-react-lite";

import { useMst } from "models/Root";
import useAccountType from "hooks/useAccountType";
import { AccountTypeEnum } from "types/account";
import commonMessages from "messages/common";
import { getPageTitle } from "helpers/global";

const Terminal: React.FC = () => {
	const { terminal } = useMst();
	const { formatMessage, formatNumber } = useIntl();
	const type = useAccountType();
	const isMargin = [AccountTypeEnum.CROSS, AccountTypeEnum.ISOLATED].includes(type);
	const price = terminal.pair
		? formatNumber(terminal.pair.close, {
				useGrouping: true,
				maximumFractionDigits: terminal.pair.price_precision ?? 8,
		  })
		: 0;
	const name = terminal.pair?.symbol?.replace("_", "/") ?? "";
	const title = getPageTitle(
		terminal.pair
			? `${price} | ${name} | ${formatMessage(commonMessages.exchange)}`
			: `${formatMessage(commonMessages.exchange)}`,
	);
	const desc = getPageTitle(
		isMargin
			? formatMessage(commonMessages.exchange_page_description_margin)
			: terminal.pair
			? formatMessage(commonMessages.exchange_page_description, {
					base_currency: terminal.pair.base_currency_code,
					quote_currency: terminal.pair.quote_currency_code,
			  })
			: `${formatMessage(commonMessages.exchange)}`,
	);

	return (
		<Helmet
			title={title}
			meta={[
				{ name: "description", content: desc },
				{ name: "viewport", content: "width=device-width, initial-scale=1.0" },
				{ property: "og:title", content: title },
				{ property: "twitter:title", content: title },
				{ property: "og:description", content: desc },
				{ name: "twitter:description", content: desc },
			]}
		/>
	);
};

export default observer(Terminal);

import React, { memo } from "react";

const MarginCalls = () => (
	<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
		<path
			stroke="#fff"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth="1.5"
			d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z"
		/>
		<path
			stroke="#fff"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth="1.5"
			d="M12 18a6 6 0 100-12 6 6 0 000 12z"
		/>
		<path
			stroke="#fff"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth="1.5"
			d="M12 14a2 2 0 100-4 2 2 0 000 4z"
		/>
	</svg>
);

export default memo(MarginCalls);

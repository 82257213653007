import React, { FC, useCallback } from "react"
import { useLocation } from "react-router-dom"
import { useIntl } from "react-intl"
import { observer } from "mobx-react-lite"

import NavArrowRight from "assets/icons/header/NavArrowRight"
import messages from "messages/common"
import { useMst } from "models/Root"
import { routes } from "constants/routing"
import useLocaleNavigate from "hooks/useLocaleNavigate"
import { useTradingFees } from "services/TradingFeesService"
import security_messages from "messages/security"
import InternalLink from "components/InternalLink"
import styles from "components/NewUI/Header/Navigation/index.module.scss"
import useCopyClick from "hooks/useCopyClick"

const User: FC<{ isMobile?: boolean }> = ({ isMobile = false }) => {
	const { formatMessage } = useIntl()
	const {
		render,
		auth: { onLogout },
		account: { profileStatus },
	} = useMst()
	const { data: tradingFees } = useTradingFees()
	const localeNavigate = useLocaleNavigate()
	const { pathname } = useLocation()
	const copyAction = useCopyClick()

	const handleLogout = (e: React.MouseEvent): void => {
		e.preventDefault()
		onLogout().then(() => {
			localeNavigate(routes.login.redirect(pathname))
		})
	}

	const handleCopyId = useCallback(() => {
		if (profileStatus?.uid) {
			copyAction(profileStatus?.uid)
		}
	}, [profileStatus?.uid])

	return (
		<div
			className={`${!isMobile ? styles.nav__firstList : ""} ${
				!isMobile ? styles.nav__firstList___user : ""
			}`}
		>
			<div className={styles.nav__userData}>
				<div className={styles.nav__userDataTop}>
					<span className={styles.nav__userText}>
						{profileStatus?.username || profileStatus?.email}
					</span>
					<span>{tradingFees?.personal?.fee_tier?.name ?? "VIP ?"}</span>
				</div>

				<button
					type="button"
					onClick={handleCopyId}
					className={`${styles.nav__userDataBottom} ${styles.nav__userDataBottom___user}`}
				>
					<span>User ID</span>
					<span>{profileStatus?.uid}</span>
				</button>
			</div>

			{!profileStatus?.two_factor_enabled ? (
				<InternalLink
					to={routes.security.authenticator}
					className={styles.nav__userButtonTwoFactor}
				>
					<span>{formatMessage(security_messages.twoFA_enable_btn)}</span>
				</InternalLink>
			) : null}

			<ul className={styles.nav__userList}>
				{!(!profileStatus?.is_email_confirmed || profileStatus?.is_sub_account) ? (
					<li className={styles.nav__itemFirst}>
						<InternalLink to={routes.verification.root} className={styles.nav__userButton}>
							<span>{formatMessage(messages.verification)}</span>

							<NavArrowRight />
						</InternalLink>
					</li>
				) : null}

				{!(!render.referrals || profileStatus?.is_sub_account) ? (
					<li className={styles.nav__itemFirst}>
						<InternalLink to={routes.referrals.root} className={styles.nav__userButton}>
							<span>{formatMessage(messages.referrals)}</span>

							<NavArrowRight />
						</InternalLink>
					</li>
				) : null}

				{render.subAccounts && !profileStatus?.is_sub_account ? (
					<li className={styles.nav__itemFirst}>
						<InternalLink to={routes.subAccounts.balances} className={styles.nav__userButton}>
							<span>{formatMessage(messages.sub_accounts)}</span>

							<NavArrowRight />
						</InternalLink>
					</li>
				) : null}

				<li className={styles.nav__itemFirst}>
					<InternalLink to={routes.settings.root} className={styles.nav__userButton}>
						<span>{formatMessage(messages.settings)}</span>

						<NavArrowRight />
					</InternalLink>
				</li>

				<li className={styles.nav__itemFirst}>
					<InternalLink to={routes.security.root} className={styles.nav__userButton}>
						<span>{formatMessage(messages.security)}</span>

						<NavArrowRight />
					</InternalLink>
				</li>

				<li className={styles.nav__itemFirst}>
					<InternalLink to={routes.fees} className={styles.nav__userButton}>
						<span>{formatMessage(messages.fee)}</span>

						<NavArrowRight />
					</InternalLink>
				</li>

				<li className={styles.nav__itemFirst}>
					<InternalLink to={routes.api.root} className={styles.nav__userButton}>
						<span>{formatMessage(messages.api)}</span>

						<NavArrowRight />
					</InternalLink>
				</li>
			</ul>

			<button onClick={handleLogout} className={styles.nav__userButtonLogOut} type="button">
				<span>{formatMessage(messages.logout)}</span>
			</button>
		</div>
	)
}

export default observer(User)

import React from "react";
import { observer } from "mobx-react-lite";

import styles from "./index.module.scss";

interface BaseInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
	prefixIcon?: React.ReactElement;
	postfixIcon?: React.ReactElement;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	prefixText?: string | any;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	postfixText?: string | any;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onEnter?: any;
	error?: string;
}

const Input: React.FC<BaseInputProps> = ({
	prefixIcon,
	postfixIcon,
	prefixText,
	onEnter,
	postfixText,
	error = "",
	...props
}) => {
	const [isFocused, setIsFocused] = React.useState(false);
	const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.key === "Enter" && !!onEnter) {
			onEnter();
		}
	};

	const onFocus = (ev: React.FocusEvent<HTMLInputElement>) => {
		setIsFocused(true);

		if (props.onFocus) {
			return props.onFocus(ev);
		}

		return null;
	};

	const onBlur = (ev: React.FocusEvent<HTMLInputElement>) => {
		setIsFocused(false);

		if (props.onBlur) {
			return props.onBlur(ev);
		}

		return null;
	};

	return (
		<>
			<div
				className={`${styles.input} ${isFocused ? styles.input___focus : ""} ${
					props.disabled ? styles.input___disabled : ""
				} ${error !== "" ? styles.input___errorInput : ""}`}
			>
				<div className={styles.input__prefixWrapper}>
					{prefixIcon ? <div className={styles.input__prefixIcon}>{prefixIcon}</div> : null}

					{prefixText ? <div className={styles.input__prefixText}>{prefixText}</div> : null}
				</div>

				<input
					autoComplete="off"
					onKeyDown={handleKeyPress}
					className={styles.input__elem}
					{...props}
					onFocus={onFocus}
					onBlur={onBlur}
				/>

				<div className={styles.input__postfixWrapper}>
					{postfixIcon ? <div className={styles.input__postfixIcon}>{postfixIcon}</div> : null}

					{postfixText ? <div className={styles.input__postfixText}>{postfixText}</div> : null}
				</div>
			</div>

			{error !== "" ? <p className={styles.input__errorText}>{error}</p> : null}
		</>
	);
};

export default observer(Input);

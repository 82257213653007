import React, { FC, useCallback } from "react"
import { observer } from "mobx-react-lite"

import NavArrowRight from "assets/icons/header/NavArrowRight"
import ExternalImage from "components/NewUI/ExternalImage"
import styles from "components/NewUI/Header/Navigation/index.module.scss"

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Item: FC<{
	item: any
	handleLinkClick: (name: string) => void
	isRenderMargin?: boolean
}> = ({ item, handleLinkClick, isRenderMargin }) => {
	const onClick = useCallback(() => handleLinkClick(item.symbol), [item.symbol])

	return (
		<li className={styles.nav__tradingCoinsItem}>
			<button type="button" onClick={onClick} className={styles.nav__tradingCoinWrapper}>
				<ExternalImage
					className={styles.nav__tradingCoinImage}
					src={item.base_currency.image_svg}
					alt={item.base_currency.name}
				/>

				<div className={styles.nav__tradingCoinContent}>
					<span className={styles.nav__tradingCoinPair}>{item.label}</span>

					<div className={styles.nav__tradingCoinName}>
						<span className={styles.nav__tradingCoinNameText}>{item.base_currency.name}</span>

						{item.cross_margin_leverage !== 0 && isRenderMargin ? (
							<span className={styles.nav__tradingCoinMarginText}>
								{item.cross_margin_leverage}x
							</span>
						) : null}
					</div>
				</div>

				<NavArrowRight />
			</button>
		</li>
	)
}

export default observer(Item)

import React, { useMemo } from "react"
import { observer } from "mobx-react-lite"
import { useIntl } from "react-intl"

import styles from "components/NewUI/Terminal/FunctionalPanel/BorrowSize/index.module.scss"
import { formatNumberNoRounding } from "../../../../../utils/format"
import commonMessages from "../../../../../messages/common"

const BorrowSize: React.FC<{ balancesCrossBorrows: any[] }> = ({ balancesCrossBorrows }) => {
	const { formatMessage } = useIntl()
	const value = useMemo(
		() => balancesCrossBorrows.reduce((accumulator, item) => accumulator + +item.usdtBorrowed, 0),
		[balancesCrossBorrows],
	)

	return (
		<div className={styles.borrowSize}>
			<div className={styles.borrowSize__mainRow}>
				<p className={styles.borrowSize__mainText}>{formatMessage(commonMessages.allBorrowSize)}</p>

				<p className={styles.borrowSize__value}>≈{formatNumberNoRounding(+value, 2)} USDT</p>
			</div>

			{balancesCrossBorrows.length > 0
				? balancesCrossBorrows.map(item => (
						<div key={item.code} className={styles.borrowSize__row}>
							<p className={styles.borrowSize__rowText}>
								{item.code} {formatMessage(commonMessages.borrow)}
							</p>

							<p className={styles.borrowSize__value}>
								{formatNumberNoRounding(+item.borrowed, item.precision)} {item.code}
							</p>
						</div>
				  ))
				: null}
		</div>
	)
}

export default observer(BorrowSize)

import React, { FC } from "react"

import styles from "components/NewUI/Header/Logo/index.module.scss"
import LogoIcon from "assets/icons/Logo"
import { HeaderLogoTypes } from "types/header"
import InternalLink from "components/InternalLink"

const Logo: FC<HeaderLogoTypes> = ({ linkTo }) => (
	<InternalLink ariaLabel="BTC-Alpha" className={styles.logo} to={linkTo}>
		<LogoIcon />
	</InternalLink>
)

export default Logo

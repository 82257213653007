import React, { memo } from "react";

const Withdraw = () => (
	<svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M9.41 1.91a.833.833 0 011.179 0l4.166 4.167a.833.833 0 11-1.178 1.179l-2.744-2.744V12.5a.833.833 0 01-1.667 0V4.512L6.422 7.256a.833.833 0 11-1.179-1.179L9.41 1.911z"
			fill="#fff"
		/>
		<path
			d="M6.666 11.667c.46 0 .833.373.833.833a2.5 2.5 0 005 0c0-.46.373-.833.834-.833h2.222c.254 0 .472 0 .669.03a2.5 2.5 0 012.078 2.079c.031.196.03.414.03.668v.125c0 .397.001.692-.04.953a3.333 3.333 0 01-2.771 2.77c-.26.042-.555.042-.953.041H5.431c-.398 0-.692 0-.953-.04a3.333 3.333 0 01-2.77-2.771c-.042-.261-.042-.556-.042-.953v-.125c0-.254 0-.472.03-.668a2.5 2.5 0 012.079-2.078c.196-.032.415-.031.668-.031h2.223z"
			fill="#fff"
		/>
	</svg>
);

export default memo(Withdraw);

import React from "react"
import { observer } from "mobx-react-lite"

import styles from "components/NewUI/Terminal/Bottom/OrderHistory/index.module.scss"
import useOrderHistory from "hooks/newUI/terminal/useOrderHistory"
import SignInSignUp from "../SignInSignUp"
import ListHeadline from "../ListHeadline"
import List from "./List"
import LimitMarketStopButtons from "../LimitMarketStopButtons"

const OrderHistory: React.FC<{ isShowAll?: boolean }> = ({ isShowAll = false }) => {
	const {
		isAuthenticated,
		listScheme,
		listHeadline,
		list,
		pairAmountPrecision,
		pairPricePrecision,
		isClosedOrdersLoading,
		setFilterTypeOrderHistory,
		filterTypeOrderHistory,
		setCurrentOpenOrderHistoryOrderID,
		currentOpenOrderHistoryOrderID,
	} = useOrderHistory({ isShowAll })

	return (
		<div className={styles.orderHistory}>
			<LimitMarketStopButtons
				id="OrderHistory"
				setFilterType={setFilterTypeOrderHistory}
				filterType={filterTypeOrderHistory}
				isAuthenticated={isAuthenticated}
			/>

			{isAuthenticated ? (
				<List
					list={list}
					listScheme={listScheme}
					pairAmountPrecision={pairAmountPrecision}
					pairPricePrecision={pairPricePrecision}
					isClosedOrdersLoading={isClosedOrdersLoading}
					setCurrentOpenOrderHistoryOrderID={setCurrentOpenOrderHistoryOrderID}
					currentOpenOrderHistoryOrderID={currentOpenOrderHistoryOrderID}
				/>
			) : (
				<>
					<ListHeadline list={listHeadline} listScheme={listScheme} />

					<SignInSignUp />
				</>
			)}
		</div>
	)
}

export default observer(OrderHistory)

import React, { useState } from "react";
import { useIntl } from "react-intl";
import commonMessages from "messages/common";
import styles from "styles/pages/ProfileVerification.module.scss";
import verificationMessages from "messages/verification";
import Button from "components/UI/Button";
import Modal, { ActionGroup, Content, Footer } from "components/UI/Modal";
import errorHandler from "utils/errorHandler";
import { useNavigate } from "react-router-dom";
import VerificationService from "services/VerificationService";
import useWindowSize from "hooks/useWindowSize";
import { IShareDiiaDocumentEnum } from "types/verification";
import RadioChoice from "components/UI/Radio";

interface IProps {
	onClose: () => void;
}

const DiiaDocumentModal: React.FC<IProps> = ({ onClose }) => {
	const [document, setDocument] = useState<IShareDiiaDocumentEnum>(IShareDiiaDocumentEnum.PASSPORT);
	const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);

	const navigate = useNavigate();
	const { formatMessage } = useIntl();

	const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
		const value = e.target.value;
		setDocument(value as IShareDiiaDocumentEnum);
	};

	const handleSubmit = async (): Promise<void> => {
		try {
			setIsSubmitLoading(true);
			const data = await VerificationService.shareDiia({ document });
			const link = data?.deeplink;
			if (link) {
				navigate({
					pathname: `diia`,
					search: `?link=${link}&document=${document}`,
				});
				onClose();
			}
		} catch (err) {
			errorHandler(err);
		} finally {
			setIsSubmitLoading(false);
		}
	};

	return (
		<Modal
			isOpen
			label={formatMessage(verificationMessages.share_a_copy_of_your_documents)}
			onClose={onClose}
		>
			<Content className={styles.diia_modal_content}>
				<RadioChoice
					onChange={handleRadioChange}
					label={formatMessage(verificationMessages.passport)}
					value={document}
					name="document"
					choice={IShareDiiaDocumentEnum.PASSPORT}
				/>
				<RadioChoice
					onChange={handleRadioChange}
					label={formatMessage(verificationMessages.reference_internally_displaced_person)}
					value={document}
					name="document"
					choice={IShareDiiaDocumentEnum.REFERENCE_INTERNALLY_DISPLACED_PERSON}
				/>
				<RadioChoice
					onChange={handleRadioChange}
					label={formatMessage(verificationMessages.drive_license)}
					value={document}
					name="document"
					choice={IShareDiiaDocumentEnum.DRIVER_LICENSE}
				/>
			</Content>
			<Footer>
				<ActionGroup>
					<Button
						fullWidth
						variant="filled"
						color="primary"
						onClick={handleSubmit}
						isLoading={isSubmitLoading}
						label={formatMessage(commonMessages.submit)}
					/>
					<Button
						fullWidth
						variant="outlined"
						color="primary"
						onClick={onClose}
						label={formatMessage(commonMessages.back_btn)}
					/>
				</ActionGroup>
			</Footer>
		</Modal>
	);
};

export default DiiaDocumentModal;

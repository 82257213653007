import React from "react"
import { observer } from "mobx-react-lite"
import { useIntl } from "react-intl"

import { useMst } from "models/Root"
import financeMessages from "messages/finance"
import styles from "components/NewUI/Terminal/FunctionalPanel/DepositTransferRepay/index.module.scss"
import InternalLink from "components/InternalLink"
import { routes } from "constants/routing"
import commonMessages from "../../../../../messages/common"

const DepositTransferRepay: React.FC<{ openTransferAction: () => void }> = ({
	openTransferAction,
}) => {
	const { formatMessage } = useIntl()
	const {
		account: { isDepositEnabled, isTransferEnabled },
	} = useMst()

	return (
		<div className={styles.depositTransfer}>
			{isDepositEnabled ? (
				<InternalLink className={styles.depositTransfer__button} to={routes.profile.createDeposit}>
					{formatMessage(commonMessages.deposit)}
				</InternalLink>
			) : null}

			{isTransferEnabled ? (
				<button
					onClick={openTransferAction}
					type="button"
					className={styles.depositTransfer__button}
				>
					{formatMessage(financeMessages.transfer)}
				</button>
			) : null}
		</div>
	)
}

export default observer(DepositTransferRepay)

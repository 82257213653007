import { defineMessages } from "react-intl";

export default defineMessages({
	hero_title: {
		id: "app.homepage.hero.title",
		defaultMessage: "you are in control",
	},
	hero_subtitle: {
		id: "app.homepage.hero.subtitle",
		defaultMessage:
			"Digital assets exchange, which provides the simplicity and reliability of bying, selling and exchanging cryptocurrencies in seconds.",
	},
	hero_title_mobile: {
		id: "app.homepage.hero.hero_title_mobile",
		defaultMessage: "You are in",
	},
	hero_title_control: {
		id: "app.homepage.hero.hero_title_control",
		defaultMessage: "Control",
	},
	mobile_title: {
		id: "app.homepage.hero.mobile_title",
		defaultMessage: "Store and trade crypto using your smartphone!",
	},
	mobile_content: {
		id: "app.homepage.hero.mobile_content",
		defaultMessage:
			"Digital assets exchange, which provides the simplicity and reliability of bying, selling and exchanging cryptocurrencies in seconds.",
	},
	offer_title: {
		id: "app.homepage.hero.offer_title",
		defaultMessage: "What we offer:",
	},
	offer_card_spot_title: {
		id: "app.homepage.hero.offer_card_spot_title",
		defaultMessage: "Spot Trading",
	},
	offer_card_spot_content: {
		id: "app.homepage.hero.offer_card_spot_content",
		defaultMessage:
			"Buy and sell cryptocurrency at the current price. Build up strategies and learn to earn with the help of a basic trading terminal.",
	},
	offer_card_margin_title: {
		id: "app.homepage.hero.offer_card_margin_title",
		defaultMessage: "Margin Trading",
	},
	offer_card_margin_content: {
		id: "app.homepage.hero.offer_card_margin_content",
		defaultMessage: "Expand your earning potential and enjoy trading with up to 3x leverage.",
	},
	offer_card_referral_title: {
		id: "app.homepage.hero.offer_card_referral_title",
		defaultMessage: "ALP.COM Referral Program",
	},
	offer_card_referral_content: {
		id: "app.homepage.hero.offer_card_referral_content",
		defaultMessage:
			"Invite friends and earn cryptocurrency from their commissions. Expand your circle of referrals and become an ambassador of ALP.COM. Get unique opportunities and a reduced commission.",
	},
	offer_card_support_title: {
		id: "app.homepage.hero.offer_card_support_title",
		defaultMessage: "24/7 Support and Protection",
	},
	offer_card_support_content: {
		id: "app.homepage.hero.offer_card_support_content",
		defaultMessage:
			"Your assets are always safe. Trade using a safe and reliable API and cooperate with ALP.COM. After all, we are always ready to solve any issue for our users and partners.",
	},
	uah_content: {
		id: "app.homepage.hero.uah_content",
		defaultMessage:
			"Hello, new user! We have a gift for you! Get UAH to your ALP.COM account and donate to the heroes of the Ukraine Armed Forces together with us!",
	},
	uah_subtitle_honor: {
		id: "app.homepage.hero.uah_subtitle_honor",
		defaultMessage: "In honour of",
	},
	uah_subtitle_launch: {
		id: "app.homepage.hero.uah_subtitle_launch",
		defaultMessage: "ALP.cоm launch!",
	},
	uah_bonus: {
		id: "app.homepage.hero.uah_bonus",
		defaultMessage: "Get Bonus",
	},
	faq_title: {
		id: "app.homepage.hero.faq_title",
		defaultMessage: "Got and questions?",
	},
	faq_content: {
		id: "app.homepage.hero.faq_content",
		defaultMessage:
			"You can find the solution in the FAQ, or contact live support agent. We will provide assistance with pleasure!",
	},
	contact_support: {
		id: "app.homepage.hero.contact_support",
		defaultMessage: "Contact support",
	},
	faq_1_q: {
		id: "app.homepage.hero.faq_1_q",
		defaultMessage: "How to buy Bitcoin on the ALP.COM exchange?",
	},
	faq_1_a: {
		id: "app.homepage.hero.faq_1_a",
		defaultMessage:
			"It's simple - first you need to make a deposit, that is, put money into a crypto wallet. This can be done directly from your bank card. Then go to the trading terminal and click 'Buy', enter the desired amount of cryptocurrency and that's it! Congratulations, you are now a happy owner of Bitcoin!",
	},
	faq_2_q: {
		id: "app.homepage.hero.faq_2_q",
		defaultMessage: "What is the fee for trading cryptoassets?",
	},
	faq_2_a: {
		id: "app.homepage.hero.faq_2_a",
		defaultMessage:
			"The commission is calculated individually and depends on the currencies you trade, your trading volume and special commission offers that may take place on the platform. Therefore, its size is not universal for everyone.",
	},
	faq_3_q: {
		id: "app.homepage.hero.faq_3_q",
		defaultMessage: "How to become part of our crypto community?",
	},
	faq_3_a: {
		id: "app.homepage.hero.faq_3_a",
		defaultMessage:
			"If you have registered on ALP.COM and purchased cryptocurrency, the first step has already been taken. All that remains is to try to trade. And, of course, subscribe to current news, channels and chats in social networks to receive information about the cryptocurrency market and stay up to date with all the important news. Advanced level - attending conferences and crypto hangouts, where you can make friends with like-minded people.",
	},
	faq_4_q: {
		id: "app.homepage.hero.faq_4_q",
		defaultMessage: "Crypto wallet of the exchange - how to use it?",
	},
	faq_4_a: {
		id: "app.homepage.hero.faq_4_a",
		defaultMessage:
			"Even if a crypto wallet seems complicated, it really isn't. Especially the ALP.COM crypto-wallet, which has the functions of viewing available currencies, depositing, withdrawing and instant transfers to other wallets on the platform. There is also an opportunity to view the history of your transactions. In general, in terms of functionality and complexity, the crypto wallet is little different from the accounts of online banks that we are used to.",
	},
});

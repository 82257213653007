import React, { memo } from "react"

const Logo = () => (
	<svg width="138" height="16" viewBox="0 0 138 16" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M11.0809 1.24471L17.5666 12.1255C18.5706 13.8118 17.3457 15.9197 15.4181 15.9197L2.54709 16C0.579294 16 -0.645559 13.8921 0.35842 12.2058L6.70357 1.28486C7.68747 -0.421534 10.0769 -0.421536 11.0809 1.24471Z"
			fill="#4E54A6"
		/>
		<path
			d="M20.7521 2.71016C20.7521 1.22459 21.9569 0 23.4628 0H34.0247C35.5106 0 36.7354 1.20451 36.7354 2.71016V13.2697C36.7354 14.7553 35.5306 15.9799 34.0247 15.9799H23.4628C21.9769 15.9799 20.7521 14.7754 20.7521 13.2697V2.71016Z"
			fill="#FC3F3A"
		/>
		<path
			d="M52.325 13.1172H46.3071L45.2195 15.8718H41.0867L47.3222 1.73633H51.31L57.5454 15.8718H53.4126L52.325 13.1172ZM51.1649 10.1451L49.3523 5.65077L47.5397 10.1451H51.1649Z"
			fill="white"
		/>
		<path
			d="M58.7064 1.66406H62.6942V12.6825H69.4372V15.8721H58.6339V1.66406H58.7064Z"
			fill="white"
		/>
		<path
			d="M80.8931 2.31647C81.8357 2.7514 82.5607 3.33132 83.0683 4.12871C83.5758 4.9261 83.8658 5.86848 83.8658 6.88333C83.8658 7.97068 83.5758 8.91304 83.0683 9.63794C82.5607 10.4353 81.8357 11.0152 80.8931 11.4502C79.9505 11.8851 78.8629 12.1026 77.5578 12.1026H75.0927V15.7996H71.1049V1.66406H77.5578C78.8629 1.66406 79.9505 1.88153 80.8931 2.31647ZM79.153 8.4781C79.588 8.11565 79.8055 7.60821 79.8055 6.95581C79.8055 6.3034 79.588 5.79599 79.153 5.43354C78.7179 5.07109 78.1379 4.85361 77.2678 4.85361H75.0202V8.98553H77.2678C78.1379 8.98553 78.7179 8.84054 79.153 8.4781Z"
			fill="white"
		/>
		<path
			d="M83.687 15.6483C83.4762 15.4375 83.3356 15.1564 83.3356 14.805C83.3356 14.4537 83.4762 14.1726 83.687 13.9617C83.8979 13.7509 84.179 13.6104 84.5305 13.6104C84.882 13.6104 85.0928 13.7509 85.374 13.9617C85.5849 14.1726 85.7255 14.4537 85.7255 14.805C85.7255 15.1564 85.5849 15.4375 85.374 15.6483C85.1631 15.8592 84.882 15.9997 84.5305 15.9997C84.179 15.9997 83.8979 15.8592 83.687 15.6483Z"
			fill="#FC3F3A"
		/>
		<path
			d="M90.8194 14.946C89.8353 14.3838 89.0621 13.681 88.5701 12.6972C88.0077 11.7133 87.7266 10.6592 87.7266 9.46448C87.7266 8.26979 88.0077 7.14538 88.5701 6.2318C89.1324 5.24794 89.9056 4.54518 90.8194 3.98297C91.8034 3.42077 92.8578 3.20996 94.123 3.20996C95.1774 3.20996 96.1615 3.42078 97.005 3.84244C97.8484 4.26409 98.4811 4.89657 98.9731 5.6696L97.7079 6.5129C97.2861 5.88042 96.7941 5.45876 96.1615 5.10739C95.5288 4.82628 94.8259 4.61547 94.123 4.61547C93.2092 4.61547 92.4361 4.82629 91.7331 5.24795C91.0302 5.6696 90.4679 6.2318 90.0462 6.93455C89.6244 7.63731 89.4135 8.48062 89.4135 9.46448C89.4135 10.4483 89.6244 11.2916 90.0462 11.9944C90.4679 12.6972 91.0302 13.2594 91.7331 13.681C92.4361 14.1027 93.2795 14.3135 94.123 14.3135C94.8259 14.3135 95.5288 14.1729 96.1615 13.8918C96.7941 13.6107 97.2861 13.1188 97.7079 12.5566L98.9731 13.3999C98.4811 14.1729 97.8484 14.8054 97.005 15.2271C96.1615 15.6487 95.1774 15.8596 94.123 15.8596C92.8578 15.719 91.8034 15.4379 90.8194 14.946Z"
			fill="#E9E4E4"
		/>
		<path
			d="M103.895 14.8756C102.911 14.3134 102.208 13.6107 101.646 12.6268C101.083 11.643 100.802 10.5888 100.802 9.39416C100.802 8.19948 101.083 7.07506 101.646 6.16148C102.208 5.17763 102.981 4.47489 103.895 3.91268C104.879 3.35048 105.933 3.13965 107.128 3.13965C108.323 3.13965 109.378 3.42075 110.362 3.91268C111.346 4.47489 112.049 5.17763 112.611 6.16148C113.173 7.14534 113.454 8.19948 113.454 9.39416C113.454 10.5888 113.173 11.7133 112.611 12.6268C112.049 13.6107 111.346 14.3134 110.362 14.8756C109.378 15.4378 108.323 15.7189 107.128 15.7189C105.863 15.7189 104.809 15.4378 103.895 14.8756ZM109.448 13.6107C110.151 13.189 110.713 12.6268 111.065 11.9241C111.486 11.151 111.627 10.378 111.627 9.39416C111.627 8.48058 111.416 7.63727 111.065 6.86424C110.643 6.09121 110.151 5.52901 109.448 5.17763C108.745 4.75598 107.972 4.54516 107.058 4.54516C106.215 4.54516 105.371 4.75598 104.668 5.17763C103.965 5.59928 103.403 6.16148 103.051 6.86424C102.63 7.56699 102.419 8.4103 102.419 9.39416C102.419 10.3077 102.63 11.151 103.051 11.9241C103.473 12.6268 104.035 13.2593 104.668 13.6107C105.371 14.0323 106.144 14.2432 107.058 14.2432C107.972 14.2432 108.745 14.0323 109.448 13.6107Z"
			fill="#E9E4E4"
		/>
		<path
			d="M135.878 4.47485C136.791 5.38843 137.213 6.65339 137.213 8.41028V15.5784H135.526V8.55082C135.526 7.28586 135.245 6.30201 134.612 5.59926C133.98 4.8965 133.136 4.61541 132.012 4.61541C130.746 4.61541 129.692 5.03705 128.989 5.81008C128.216 6.58311 127.864 7.70753 127.864 9.11304V15.5784H126.177V8.55082C126.177 7.28586 125.896 6.30201 125.264 5.59926C124.631 4.8965 123.788 4.61541 122.593 4.61541C121.327 4.61541 120.343 5.03705 119.57 5.81008C118.797 6.58311 118.446 7.70753 118.446 9.11304V15.5784H116.759V3.2099H118.375V5.4587C118.797 4.68567 119.43 4.12346 120.203 3.70181C120.976 3.28016 121.89 3.06934 122.944 3.06934C123.998 3.06934 124.912 3.28016 125.615 3.70181C126.388 4.12346 126.951 4.82623 127.302 5.66954C127.724 4.82623 128.427 4.19374 129.27 3.77209C130.114 3.28016 131.098 3.06934 132.223 3.06934C133.769 3.13961 135.034 3.56126 135.878 4.47485Z"
			fill="#E9E4E4"
		/>
	</svg>
)

export default memo(Logo)

import React from "react";
import { observer } from "mobx-react-lite";
import { useIntl } from "react-intl";

import styles from "components/NewUI/Terminal/FunctionalPanel/ActionButton/index.module.scss";
import { TradeActionsEnum, TradeTypeEnum } from "types/exchange";
import messages from "messages/exchange";
import Spinner from "components/UI/Spinner";
import Skeleton from "react-loading-skeleton";

const ActionButton: React.FC<{
	tradeAction: TradeActionsEnum;
	tradeType: TradeTypeEnum;
	isOnSubmitLoading: boolean;
	baseCurrencyCode: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	handleSubmit?: any;
	isLoaded?: boolean;
}> = ({ tradeAction, tradeType, handleSubmit, baseCurrencyCode, isOnSubmitLoading, isLoaded }) => {
	const { formatMessage } = useIntl();

	return (
		<div className={styles.actionButton}>
			{isLoaded ? (
				<button
					onClick={handleSubmit}
					type="button"
					disabled={isOnSubmitLoading}
					className={`${styles.actionButton__button} ${
						tradeAction === TradeActionsEnum.SELL ? styles.actionButton__button___sell : ""
					}`}
				>
					<div className={styles.actionButton__loading}>
						<Spinner size={30} />
					</div>

					<span>
						{tradeAction === TradeActionsEnum.SELL
							? `${formatMessage(messages.sell)}${
									tradeType !== TradeTypeEnum.SPOT ? `/${formatMessage(messages.short)}` : ""
							  } ${baseCurrencyCode}`
							: `${formatMessage(messages.buy)}${
									tradeType !== TradeTypeEnum.SPOT ? `/${formatMessage(messages.long)}` : ""
							  } ${baseCurrencyCode}`}
					</span>
				</button>
			) : (
				<div style={{ width: "100%" }}>
					<Skeleton height={40} borderRadius={8} />
				</div>
			)}
		</div>
	);
};

export default observer(ActionButton);

import React from "react"
import { observer } from "mobx-react-lite"
import { useIntl } from "react-intl"

import Modal from "../../../UI/Modal"
import styles from "./index.module.scss"
import { TradeActionsEnum } from "../../../../types/exchange"
import messages from "../../../../messages/exchange"
import { OrderTypeEnum } from "../../../../types/orders"

const ConfirmMarginOrderModal: React.FC<{
	isOpen: boolean
	onClose: () => void
	orderPriceString: string
	orderQtyString: string
	orderValueString: string
	dailyInterestRate: string
	quoteCurrencyCode: string
	baseCurrencyCode: string
	stop_price: string
	marginLvl: number
	tradeAction: TradeActionsEnum
	submitAction: any
	tradeMode: OrderTypeEnum
}> = ({
	isOpen = false,
	onClose,
	tradeAction,
	orderPriceString,
	orderQtyString,
	orderValueString,
	dailyInterestRate,
	quoteCurrencyCode,
	baseCurrencyCode,
	marginLvl,
	submitAction,
	tradeMode,
	stop_price,
}) => {
	const { formatMessage } = useIntl()

	return (
		<Modal
			label={
				<span className={styles.confirmModal__label}>
					<span
						className={`${
							tradeAction === TradeActionsEnum.SELL
								? styles.confirmModal__label___sell
								: styles.confirmModal__label___buy
						}`}
					>
						{tradeMode === OrderTypeEnum.LIMIT ? "Limit " : ""}
						{tradeMode === OrderTypeEnum.MARKET ? "Market " : ""}
						{tradeMode === OrderTypeEnum.STOP_ORDER ? "Stop Order " : ""}
						{tradeAction === TradeActionsEnum.SELL
							? `${formatMessage(messages.sell)}/${formatMessage(messages.short)}`
							: `${formatMessage(messages.buy)}/${formatMessage(messages.long)}`}{" "}
					</span>

					<span>{baseCurrencyCode}</span>
				</span>
			}
			isOpen={isOpen}
			onClose={onClose}
			headerBorder={false}
		>
			<div className={styles.confirmModal__content}>
				<div className={styles.confirmModal__item}>
					<span className={styles.confirmModal__headline}>Order Type</span>

					<span className={styles.confirmModal__value}>Margin {marginLvl}X</span>
				</div>

				{tradeMode === OrderTypeEnum.STOP_ORDER ? (
					<div className={styles.confirmModal__item}>
						<span className={styles.confirmModal__headline}>Trigger Price</span>

						<span className={styles.confirmModal__value}>
							{stop_price} {quoteCurrencyCode}
						</span>
					</div>
				) : null}

				<div className={styles.confirmModal__item}>
					<span className={styles.confirmModal__headline}>Order Price</span>

					<span className={styles.confirmModal__value}>
						{tradeMode === OrderTypeEnum.MARKET ? "Market Price" : ""}
						{tradeMode === OrderTypeEnum.LIMIT || tradeMode === OrderTypeEnum.STOP_ORDER
							? `${orderPriceString} ${quoteCurrencyCode}`
							: ""}
					</span>
				</div>

				{orderQtyString !== "0" ? (
					<div className={styles.confirmModal__item}>
						<span className={styles.confirmModal__headline}>Quantity</span>

						<span className={styles.confirmModal__value}>
							{orderQtyString} {baseCurrencyCode}
						</span>
					</div>
				) : null}

				<div className={styles.confirmModal__item}>
					<span className={styles.confirmModal__headline}>Order Value</span>

					<span className={styles.confirmModal__value}>
						{orderValueString} {quoteCurrencyCode}
					</span>
				</div>

				{/*<div className={styles.confirmModal__item}>*/}
				{/*	<span className={styles.confirmModal__headline}>Amount to Borrow (Auto)</span>*/}

				{/*	<span className={styles.confirmModal__value}>0</span>*/}
				{/*</div>*/}

				<div className={styles.confirmModal__item}>
					<span className={styles.confirmModal__headline}>Daily Interest Rate</span>

					<span className={styles.confirmModal__value}>{dailyInterestRate}%</span>
				</div>

				<div className={styles.confirmModal__buttons}>
					<button className={styles.confirmModal__button} type="button" onClick={onClose}>
						Cancel
					</button>

					<button
						className={`${styles.confirmModal__button} ${styles.confirmModal__button___save}`}
						type="button"
						onClick={submitAction}
					>
						Confirm
					</button>
				</div>
			</div>
		</Modal>
	)
}

export default observer(ConfirmMarginOrderModal)

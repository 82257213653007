import ApiClient from "helpers/ApiClient";
import { useQuery } from "react-query";
import {
	ICharityStatus,
	IDonate,
	IDonationRequestBody,
	IDonationRequestResponse,
	IRatedDonate,
} from "types/charity";

const CharityService = {
	getCharityStatus: (): Promise<ICharityStatus> => ApiClient.get("web/charity/stop-war"),
	sendDonate: (body: IDonate) => ApiClient.post("web/charity/stop-war/donate", body),
	getPaymentMethods: () => ApiClient.get("web/charity/stop-war/payment-methods"),
	getCurrencies: () => ApiClient.get("web/charity/stop-war/currencies"),
	getTopDonates: (): Promise<IRatedDonate[]> => ApiClient.get("web/charity/stop-war/donations/top"),
	getLatestDonates: (): Promise<IRatedDonate[]> =>
		ApiClient.get("web/charity/stop-war/donations/latest"),
	sendDonationRequest: (body: IDonationRequestBody): Promise<IDonationRequestResponse> =>
		ApiClient.post("web/charity/stop-war/donation-request", body),
};

export default CharityService;

export const useDonates = () =>
	useQuery(["donates"], async () => {
		const latest = await CharityService.getLatestDonates();
		const top = await CharityService.getTopDonates();
		return {
			latest,
			top,
		};
	});

export const useCharityStatus = () =>
	useQuery(["charity-status"], async () => {
		const data = await CharityService.getCharityStatus();
		return data;
	});

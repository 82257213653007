import { useEffect, useMemo } from "react";
import { useIntl } from "react-intl";

import { ACCOUNT_TYPE } from "constants/exchange";
import useAccountType from "hooks/useAccountType";
import historyMessages from "messages/history";
import { useMst } from "models/Root";
import { queryVars } from "constants/query";

const usePositions = () => {
	const { formatMessage } = useIntl();
	const {
		global: { isAuthenticated },
		history: {
			isTradeHistoryLoading,
			positionsList,
			setFilterTypeTradeHistory,
			filterTypeTradeHistory,
		},
		terminal: { pairSymbol, pairAmountPrecision, pairPricePrecision },
	} = useMst();
	const type = useAccountType();
	const params = useMemo(
		() => ({
			[queryVars.pair]: pairSymbol,
			[queryVars.page_size]: 50,
			[queryVars.wallet_type]: ACCOUNT_TYPE[type],
		}),
		[pairSymbol, type],
	);

	const listScheme = [
		{
			arrCount: 1,
			id: 123,
			arrNames: [
				{
					headline: formatMessage(historyMessages.active_orders_pair),
					width: 168,
					asociate: "marginName",
				},
			],
			isScroll: false,
		},
		{
			arrCount: 11,
			id: 223,
			isScroll: true,
			arrNames: [
				{
					headline: formatMessage(historyMessages.active_orders_side),
					width: 148,
					asociate: "marginDirection",
				},
				{
					headline: "Base Price",
					width: 168,
					asociate: "basePrice",
				},
				{
					headline: "Amount",
					width: 168,
					asociate: "marginAmount",
				},
				{
					headline: "Index Price",
					width: 150,
					asociate: "indexPrice",
				},
				{
					headline: "Liquidation Price",
					width: 150,
					asociate: "liquidationPrice",
				},
				{
					headline: "P&L",
					width: 168,
					asociate: "pAndL",
				},
				{ headline: "P&L, %", width: 148, asociate: "pAndLPercent" },
				{ headline: "Filled Time", width: 168, asociate: "date" },
			],
		},
	];

	const listHeadline = listScheme.flatMap((item) => item.arrNames);

	// useEffect(() => {
	// 	if (isAuthenticated && pairSymbol) {
	// 		loadTradeHistory(params);
	// 	}
	// }, [isAuthenticated, pairSymbol, params]);

	return {
		listScheme,
		listHeadline,
		list: positionsList,
		isAuthenticated,
		pairAmountPrecision,
		pairPricePrecision,
		isTradeHistoryLoading,
		filterTypeTradeHistory,
		setFilterTypeTradeHistory,
	};
};

export default usePositions;

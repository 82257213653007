import React, { memo } from "react";

const Eye = () => (
	<svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M13.125 10a3.125 3.125 0 11-6.25 0 3.125 3.125 0 016.25 0z"
			fill="#EBEBF5"
			fillOpacity={0.6}
		/>
		<path
			d="M0 10s3.75-6.875 10-6.875S20 10 20 10s-3.75 6.875-10 6.875S0 10 0 10zm10 4.375a4.375 4.375 0 100-8.75 4.375 4.375 0 000 8.75z"
			fill="#EBEBF5"
			fillOpacity={0.6}
		/>
	</svg>
);

export default memo(Eye);

import React from "react";
import { observer } from "mobx-react-lite";

import styles from "components/NewUI/Terminal/Bottom/OpenOrders/List/index.module.scss";

const HeadlineItem: React.FC<{
	headline: string;
	width: number;
}> = ({ headline, width }) => (
	<li className={styles.list__headlineText} style={{ minWidth: `${width}px` }}>
		{headline}
	</li>
);

export default observer(HeadlineItem);

import { useEffect } from "react"

/**
 * Custom hook to reload the page after a period of user inactivity.
 * @param timeoutDuration Time in milliseconds after which to reload the page.
 */
const useIdlePageReload = (timeoutDuration: number): void => {
	useEffect(() => {
		let timeout: ReturnType<typeof setTimeout>

		const handleVisibilityChange = () => {
			if (document.visibilityState === "hidden") {
				timeout = setTimeout(() => {
					window.location.reload()
				}, timeoutDuration)
			} else {
				clearTimeout(timeout)
			}
		}

		document.addEventListener("visibilitychange", handleVisibilityChange)

		return () => {
			document.removeEventListener("visibilitychange", handleVisibilityChange)
			if (timeout) clearTimeout(timeout)
		}
	}, [timeoutDuration])
}

export default useIdlePageReload

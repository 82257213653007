/* eslint-disable */
import React from "react";
import styles from "styles/components/HomePage.module.scss";
import Plane from "assets/images/home_page/plane.svg";
import Margin from "assets/images/home_page/margin.svg";
import Referral from "assets/images/home_page/referral.svg";
import Support from "assets/images/home_page/support.svg";
import messages from "messages/home_page";
import { useIntl } from "react-intl";

const HomePageOffer: React.FC = () => {
	const { formatMessage } = useIntl();

	return (
		<div className={styles.Offer}>
			<h2 className={styles.Title}>{formatMessage(messages.offer_title)}</h2>
			<HomePageOfferCards />
		</div>
	);
};

export default HomePageOffer;

const HomePageOfferCards = () => (
	<div className={styles.HomePageOfferCards}>
		<HomePageOfferCardPlane />
		<HomePageOfferCardMargin />
		<HomePageOfferCardReferral />
		<HomePageOfferCardSupport />
	</div>
);

const HomePageOfferCardPlane = () => {
	const { formatMessage } = useIntl();
	return (
		<div className={styles.HomePageOfferCard}>
			<div className={styles.HomePageOfferCardEllipseContainer}>
				<Ellipse />
			</div>
			<img className={styles.Image} alt="coin" src={Plane} />
			<div className={styles.Content}>
				<div className={styles.CardTitle}>{formatMessage(messages.offer_card_spot_title)}</div>
				<div className={styles.CardText}>{formatMessage(messages.offer_card_spot_content)}</div>
			</div>
		</div>
	);
};

const HomePageOfferCardMargin = () => {
	const { formatMessage } = useIntl();
	return (
		<div className={styles.HomePageOfferCard}>
			<img className={styles.Image} alt="coin" src={Margin} />
			<div className={styles.Content}>
				<div className={styles.CardTitle}>{formatMessage(messages.offer_card_margin_title)}</div>
				<div className={styles.CardText}>{formatMessage(messages.offer_card_margin_content)}</div>
			</div>
		</div>
	);
};

const HomePageOfferCardReferral = () => {
	const { formatMessage } = useIntl();
	return (
		<div className={styles.HomePageOfferCard}>
			<img className={styles.Image} alt="coin" src={Referral} />
			<div className={styles.Content}>
				<div className={styles.CardTitle}>{formatMessage(messages.offer_card_referral_title)}</div>
				<div className={styles.CardText}>{formatMessage(messages.offer_card_referral_content)}</div>
			</div>
		</div>
	);
};

const HomePageOfferCardSupport = () => {
	const { formatMessage } = useIntl();
	return (
		<div className={styles.HomePageOfferCard}>
			<div className={styles.HomePageOfferCardEllipseContainer}>
				<Ellipse2 />
			</div>
			<img className={styles.Image} alt="coin" src={Support} />
			<div className={styles.Content}>
				<div className={styles.CardTitle}>{formatMessage(messages.offer_card_support_title)}</div>
				<div className={styles.CardText}>{formatMessage(messages.offer_card_support_content)}</div>
			</div>
		</div>
	);
};

const Ellipse = () => <div className={styles.Ellipse6} />;
const Ellipse2 = () => <div className={styles.Ellipse7} />;

import React, { FC } from "react"
import { observer } from "mobx-react-lite"

import Logo from "assets/icons/auth/Logo"
import styles from "./Header.module.scss"

const Header: FC<{ headline: string; description: string; link?: React.ReactNode }> = ({
	headline,
	description,
	link = null,
}) => (
	<>
		<div className={styles.header__logoWrapper}>
			<Logo />
		</div>

		<div className={styles.header__titleWrapper}>
			<p className={styles.header__title}>{headline}</p>
		</div>

		<div className={styles.header__descriptionWrapper}>
			<p className={styles.header__description}>
				{description} {link}
			</p>
		</div>
	</>
)

export default observer(Header)

import React, { useEffect } from "react";
import { useIntl } from "react-intl";
import { observer } from "mobx-react-lite";

import commonMessages from "messages/common";
import { useMst } from "models/Root";
import messages from "messages/exchange";
import styles from "../index.module.scss";
import Item from "./Item";

const CoinsHeader: React.FC = () => {
	const {
		tickers: {
			filter: { setSort, sort },
		},
	} = useMst();
	const [sortName, sortValue] = sort.split(".");
	const { formatMessage } = useIntl();

	const columns = [
		{
			label: formatMessage(commonMessages.coin),
			name: "symbol",
		},
		{
			label: formatMessage(messages.order_price),
			name: "close",
		},
		{
			label: `24H ${formatMessage(commonMessages.change)}`,
			name: "change_percent",
		},
	];

	return (
		<div className={styles.search__coinsHeader}>
			{columns.map(({ name, label }) => (
				<Item
					key={`${name}-coin-header`}
					name={name}
					label={label}
					handleFilterChange={setSort}
					sortValue={sortValue}
					sortName={sortName}
				/>
			))}
		</div>
	);
};

export default observer(CoinsHeader);

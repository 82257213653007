import React from "react"
import { observer } from "mobx-react-lite"
import Skeleton from "react-loading-skeleton"
import { useIntl } from "react-intl"

import useFavorites from "hooks/newUI/terminal/useFavorites"
import Star from "assets/icons/terminal/Star"
import ArrowRight from "assets/icons/terminal/ArrowRight"
import Dots from "assets/icons/terminal/Dots"
import styles from "./index.module.scss"
import Item from "./Item"
import commonMessages from "../../../../messages/common"
import InternalLink from "../../../InternalLink"
import { routes } from "../../../../constants/routing"

const Favorites: React.FC = () => {
	const { formatMessage } = useIntl()
	const {
		ref,
		handleArrowLeftClick,
		openSearchAction,
		toggleOpenDots,
		dropdownRef,
		isOpenDots,
		showArrows,
		showLeftArrow,
		handleArrowRightClick,
		showRightArrow,
		isAuthenticated,
		favoriteList,
		handleLinkClick,
		isLoading,
		setFavoriteFilterTypePercentValueAction,
		setFavoriteFilterTypeValueAction,
		setFavoriteFilterTypePercentAction,
		favoriteFilterType,
		FavoriteFilterTypeEnum,
	} = useFavorites()

	return (
		<section className={styles.favorites}>
			<h2 className="visually-hidden">Favorites</h2>

			<div className={styles.favorites__iconContainer}>
				<Star isChecked />
			</div>

			<div className={styles.favorites__listContainer}>
				<div ref={ref} className={styles.favorites__listWrapper}>
					{isLoading ? (
						<div className={styles.favorites__skeletonWrapper}>
							<Skeleton height={22} width={130} borderRadius={8} />

							<Skeleton height={22} width={130} borderRadius={8} />

							<Skeleton height={22} width={130} borderRadius={8} />

							<Skeleton height={22} width={130} borderRadius={8} />

							<Skeleton height={22} width={130} borderRadius={8} />
						</div>
					) : (
						<>
							<button
								type="button"
								aria-label="to left"
								onClick={handleArrowLeftClick}
								className={`${styles.favorites__listButtonLeft} ${
									showArrows && showLeftArrow ? styles.favorites__listButtonLeft___show : ""
								}`}
							>
								<ArrowRight />
							</button>

							<button
								type="button"
								aria-label="to right"
								onClick={handleArrowRightClick}
								className={`${styles.favorites__listButtonRight} ${
									showArrows && showRightArrow ? styles.favorites__listButtonRight___show : ""
								}`}
							>
								<ArrowRight />
							</button>

							{favoriteList.length > 0 ? (
								<ul className={styles.favorites__list}>
									{favoriteList.map(item => (
										<Item
											key={item.label}
											item={item}
											favoriteFilterType={favoriteFilterType}
											handleLinkClick={handleLinkClick}
										/>
									))}
								</ul>
							) : (
								<p className={styles.favorites__listEmpty}>
									{formatMessage(commonMessages.thereAreNoFavoritesCryptocurrenciesYet)}{" "}
									<button type="button" onClick={openSearchAction}>
										{formatMessage(commonMessages.clickHere)}
									</button>
									{!isAuthenticated ? (
										<>
											<span>or</span>

											<InternalLink to={routes.login.root}>
												<span>sign in</span>
											</InternalLink>
										</>
									) : (
										""
									)}
									.
								</p>
							)}
						</>
					)}
				</div>
			</div>

			{favoriteList.length > 0 ? (
				<div ref={dropdownRef} className={styles.favorites__dotsWrapper}>
					<button
						className={styles.favorites__dotsButton}
						type="button"
						onClick={toggleOpenDots}
						aria-label="Toggle open favorites dropdown"
					>
						<Dots />
					</button>

					<ul
						className={`${styles.favorites__dotsList} ${
							isOpenDots ? styles.favorites__dotsList___open : ""
						}`}
					>
						<li
							className={`${styles.favorites__dotsItem} ${
								favoriteFilterType === FavoriteFilterTypeEnum.PERCENT
									? styles.favorites__dotsItem___active
									: ""
							}`}
						>
							<button
								type="button"
								onClick={setFavoriteFilterTypePercentAction}
								className={styles.favorites__dotsItemButton}
							>
								Percent
							</button>
						</li>

						<li
							className={`${styles.favorites__dotsItem} ${
								favoriteFilterType === FavoriteFilterTypeEnum.VALUE
									? styles.favorites__dotsItem___active
									: ""
							}`}
						>
							<button
								type="button"
								onClick={setFavoriteFilterTypeValueAction}
								className={styles.favorites__dotsItemButton}
							>
								Price
							</button>
						</li>

						<li
							className={`${styles.favorites__dotsItem} ${
								favoriteFilterType === FavoriteFilterTypeEnum.PERCENT_VALUE
									? styles.favorites__dotsItem___active
									: ""
							}`}
						>
							<button
								type="button"
								onClick={setFavoriteFilterTypePercentValueAction}
								className={styles.favorites__dotsItemButton}
							>
								Price / Percent
							</button>
						</li>
					</ul>
				</div>
			) : null}
		</section>
	)
}

export default observer(Favorites)

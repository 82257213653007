import React, { memo } from "react";

const ArrowSort = () => (
	<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="none" viewBox="0 0 10 10">
		<path
			fill="#fff"
			fillRule="evenodd"
			d="M2.206 3.455a.417.417 0 01.59 0L5 5.661l2.205-2.206a.417.417 0 11.59.59l-2.5 2.5a.417.417 0 01-.59 0l-2.5-2.5a.417.417 0 010-.59z"
			clipRule="evenodd"
		/>
	</svg>
);

export default memo(ArrowSort);

import React, { FC, memo } from "react";

import styles from "components/NewUI/Header/Navigation/index.module.scss";
import { HeaderNavInnerItemTypes } from "types/header";
import NavArrowRight from "assets/icons/header/NavArrowRight";
import InternalLink from "components/InternalLink";

const Item: FC<HeaderNavInnerItemTypes> = ({
	link,
	title,
	subtitle,
	SecondLvl,
	defaultActive,
	disabled,
}) => (
	<li
		className={`${styles.nav__itemFirst} ${defaultActive ? styles.nav__itemFirst___active : ""} ${
			disabled ? styles.nav__itemFirst___disabled : ""
		}`}
	>
		<InternalLink to={link} className={styles.nav__linkFirst}>
			<span className={styles.nav__linkFirstHeadline}>
				<span>{title}</span>

				<NavArrowRight />
			</span>

			<span className={styles.nav__linkFirstText}>{subtitle}</span>
		</InternalLink>

		{SecondLvl}
	</li>
);

export default memo(Item);

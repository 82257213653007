import React, { useEffect, useRef } from "react";
import { createPortal } from "react-dom";

const TooltipPortal: React.FC<{ children: React.ReactNode }> = ({ children }) => {
	const el = useRef(document.createElement("div"));

	useEffect(() => {
		const portalRoot = document.getElementById("portalRoot") || document.body;

		portalRoot.appendChild(el.current);
		return () => {
			portalRoot.removeChild(el.current);
		};
	}, []);

	return createPortal(children, el.current);
};

export default TooltipPortal;

import React from "react";
import { observer } from "mobx-react-lite";
import Skeleton from "react-loading-skeleton";

import OrderAll from "assets/icons/terminal/OrderAll";
import OrderRed from "assets/icons/terminal/OrderRed";
import OrderGreen from "assets/icons/terminal/OrderGreen";
import styles from "../index.module.scss";
import Precision from "./Precision";

const Filter: React.FC<{
	setCurrentListType: (value: string) => void;
	currentListType: string;
	isLoaded: boolean;
	precisionArray: [number, string][];
	orderBookPrecision: number;
	setOrderBookPrecision: (v: number) => void;
}> = ({
	setCurrentListType,
	currentListType,
	precisionArray,
	orderBookPrecision,
	setOrderBookPrecision,
	isLoaded,
}) => (
	<>
		<input
			className={`visually-hidden ${styles.orders__filterRadio}`}
			type="radio"
			name="Order"
			id="AllOrders"
			value="AllOrders"
			onChange={() => setCurrentListType("AllOrders")}
			checked={currentListType === "AllOrders"}
			aria-label="Order books for buys and sells"
		/>

		<input
			className={`visually-hidden ${styles.orders__filterRadio}`}
			type="radio"
			name="Order"
			id="RedOrders"
			value="RedOrders"
			onChange={() => setCurrentListType("RedOrders")}
			checked={currentListType === "RedOrders"}
			aria-label="Sales order book"
		/>

		<input
			className={`visually-hidden ${styles.orders__filterRadio}`}
			type="radio"
			name="Order"
			id="GreenOrders"
			value="GreenOrders"
			onChange={() => setCurrentListType("GreenOrders")}
			checked={currentListType === "GreenOrders"}
			aria-label="Buy order book"
		/>

		<div className={styles.orders__filters}>
			{/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
			<label
				className={styles.orders__filterLabel}
				htmlFor="AllOrders"
				aria-label="Order books for buys and sells"
			>
				<OrderAll />
			</label>
			{/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
			<label
				className={styles.orders__filterLabel}
				htmlFor="RedOrders"
				aria-label="Sales order book"
			>
				<OrderRed />
			</label>
			{/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
			<label
				className={styles.orders__filterLabel}
				htmlFor="GreenOrders"
				aria-label="Buy order book"
			>
				<OrderGreen />
			</label>

			{isLoaded ? (
				<Precision
					list={precisionArray}
					orderBookPrecision={orderBookPrecision}
					setOrderBookPrecision={setOrderBookPrecision}
				/>
			) : (
				<div className={styles.orders__precisionSkeleton}>
					<Skeleton height={32} borderRadius={8} />
				</div>
			)}
		</div>
	</>
);

export default observer(Filter);

import React, { FC } from "react"

import { HeaderNavItemTypes, HeaderNavigationTypes } from "types/header"
import styles from "components/NewUI/Header/Navigation/index.module.scss"
import NavItem from "./NavItem"

const Navigation: FC<HeaderNavigationTypes> = ({ navArray }) => (
	<nav className={styles.nav}>
		<ul className={styles.nav__list}>
			{navArray.map((item: HeaderNavItemTypes) => (
				<NavItem
					key={`headerNav-${item.id}`}
					onClick={item.onClick}
					formatMessage={item.formatMessage}
					Icon={item.Icon}
					ariaLabel={item.ariaLabel}
					linkTo={item.linkTo}
					id={item.id}
					list={item.list}
					disabled={item.disabled}
					defaultActive={item.defaultActive}
					count={item.count}
				>
					{/* Children is the dropdown menu of a specific element */}
					{item.children}
				</NavItem>
			))}
		</ul>
	</nav>
)

export default Navigation

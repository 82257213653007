import ApiClient from "helpers/ApiClient"
import {
	IRegisterCompleteResponse,
	IRegisterJuridicalInfoResponse,
	IRegisterPersonalInfoResponse,
	IRegisterResponse,
	IRegisterBody,
	IRegisterSendPinCodeBody,
	IRegisterJuridicalInfoBody,
	IRegisterPersonalInfoBody,
} from "types/register"

const RegisterService = {
	register: (body: IRegisterBody): Promise<IRegisterResponse> =>
		ApiClient.post("web/register/email", body),
	registerPinCode: (body: IRegisterSendPinCodeBody, slug: string): Promise<IRegisterResponse> =>
		ApiClient.post(`web/register/${slug}/pincode`, body),
	registerResendPinCode: (slug: string): Promise<IRegisterResponse> =>
		ApiClient.post(`web/register/${slug}/resend-pincode`),
	registerPersonalInfo: (
		body: IRegisterPersonalInfoBody,
		slug: string,
	): Promise<IRegisterPersonalInfoResponse> =>
		ApiClient.post(`web/register/${slug}/personal-info`, body),
	registerJuridicalInfo: (
		body: IRegisterJuridicalInfoBody,
		slug: string,
	): Promise<IRegisterJuridicalInfoResponse> =>
		ApiClient.post(`web/register/${slug}/juridical-info`, body),
	registerComplete: (slug: string): Promise<IRegisterCompleteResponse> =>
		ApiClient.post(`web/register/${slug}/complete`),
}

export default RegisterService

import React, { useCallback, useEffect } from "react"
import { observer } from "mobx-react-lite"
import { useIntl } from "react-intl"

import { useMst } from "models/Root"
import Input from "../Input"
import Header from "../Header"
import Button from "../Button"
import styles from "./RegJuridicalForm.module.scss"
import CountrySelect from "../../CountrySelect/CountrySelect"

const RegJuridicalForm: React.FC = () => {
	const { formatMessage } = useIntl()
	const {
		register: {
			companyID,
			setCompanyID,
			companyName,
			setCompanyName,
			beneficeFirstName,
			setBeneficeFirstName,
			beneficeLastName,
			setBeneficeLastName,
			beneficePosition,
			setBeneficePosition,
			resetRegisterJuridicalState,
			onSubmitJuridicalStep,
			isLoading,
			errors,
			countryCode,
			setCountryCode,
		},
		global: { locale },
	} = useMst()

	const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault()

		onSubmitJuridicalStep(formatMessage).then(() => null)
	}

	const handleCountryChange = useCallback((_: any, value: any) => {
		setCountryCode(value ?? "")
	}, [])

	useEffect(() => () => resetRegisterJuridicalState(), [])

	return (
		<div className={styles.regJuridicalForm}>
			<Header
				headline="Almost Done"
				description="Please complete the form to create your account."
			/>

			<div className={styles.regJuridicalForm__wrapper}>
				<form onSubmit={onSubmit}>
					<div className={styles.regJuridicalForm__inputTopWrapper}>
						<Input
							id="reg-company-name"
							label="Company Name"
							handleChange={setCompanyName}
							value={companyName}
							autoFocus
							placeholder="Company Name"
							error={errors.companyName}
						/>
					</div>

					<div className={styles.regJuridicalForm__inputWrapper}>
						<Input
							id="reg-company-id"
							label="Company ID"
							handleChange={setCompanyID}
							value={companyID}
							placeholder="Enter ID"
							error={errors.companyID}
						/>
					</div>

					<div className={styles.regJuridicalForm__countryWrapper}>
						<CountrySelect
							value={countryCode}
							label="Company Jurisdiction"
							name="companyJurisdiction"
							locale={locale}
							onSelect={handleCountryChange}
							error={errors.countryCode}
						/>
					</div>

					<div className={styles.regJuridicalForm__inputDoubleWrapper}>
						<Input
							id="reg-benefice-first-name"
							label="Benefice First Name"
							handleChange={setBeneficeFirstName}
							value={beneficeFirstName}
							placeholder="First Name"
							error={errors.beneficeFirstName}
						/>

						<Input
							id="reg-benefice-last-name"
							label="Last Name"
							handleChange={setBeneficeLastName}
							value={beneficeLastName}
							placeholder="Last Name"
							error={errors.beneficeLastName}
						/>
					</div>

					<div className={styles.regJuridicalForm__inputWrapper}>
						<Input
							id="reg-benefice-position"
							label="Benefice Position"
							handleChange={setBeneficePosition}
							value={beneficePosition}
							placeholder="Position"
							error={errors.beneficePosition}
						/>
					</div>

					<div className={styles.regJuridicalForm__buttonWrapper}>
						<Button isLoading={isLoading} type="submit">
							Finish
						</Button>
					</div>
				</form>
			</div>
		</div>
	)
}

export default observer(RegJuridicalForm)

import config from "helpers/config";
import React, { FC, ImgHTMLAttributes } from "react";

interface IProps extends ImgHTMLAttributes<HTMLImageElement> {
	src: string;
	alt: string;
}

const ExternalImage: FC<IProps> = ({ src, alt, ...rest }) => (
	<img src={`${config.serverStaticPrefix}${src}`} alt={alt} {...rest} />
);

export default ExternalImage;

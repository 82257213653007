import React, { useCallback, useState } from "react"
import { observer } from "mobx-react-lite"
import { useIntl } from "react-intl"
import { toast } from "react-toastify"

import errorHandler from "utils/errorHandler"
import styles from "components/NewUI/Terminal/Bottom/OpenOrders/List/index.module.scss"
import ArrowRight from "assets/icons/terminal/ArrowRight"
import { OrderSideEnum, OrderTypeEnum } from "types/orders"
import messages from "messages/history"
import messagesExchange from "messages/exchange"
import ExchangeService from "services/ExchangeService"
import Spinner from "components/UI/Spinner"
import EditOrderIcon from "../../../../../../../assets/icons/terminal/EditOrder"
import Cross from "../../../../../../../assets/icons/terminal/Cross"
import CheckMarkIcon from "../../../../../../../assets/icons/terminal/CheckMark"
import { useOnClickOutside } from "../../../../../../../hooks/useOnClickOutside"

const Row: React.FC<{
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	item: any
	id: number
	namesArr: { headline: string; width: number; asociate: string }[]
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	handleArrowRightClick: any
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	handleArrowLeftClick: any
	showArrows: boolean
	showLeftArrow: boolean
	showRightArrow: boolean
	isScroll?: boolean
	pairPricePrecision: number
	pairAmountPrecision: number
}> = ({
	item,
	id,
	namesArr,
	handleArrowLeftClick,
	showArrows,
	showLeftArrow,
	showRightArrow,
	handleArrowRightClick,
	isScroll,
	pairPricePrecision,
	pairAmountPrecision,
}) => {
	const rowRef = React.useRef<HTMLLIElement>(null)
	const { formatMessage, formatNumber } = useIntl()
	const [isCancelLoading, setIsCancelLoading] = useState(false)
	const [isEdit, setIsEdit] = useState(false)
	const [orderQty, setOrderQty] = useState(item.orderQty)
	const [orderPrice, setOrderPrice] = useState(item.orderPrice)
	const [baseCurrencyCode, quoteCurrencyCode] = item.name.split("/")

	const editAction = useCallback(() => {
		setIsEdit(true)
	}, [])

	const closeEditAction = useCallback(() => {
		setOrderPrice(item.orderPrice)
		setOrderQty(item.orderQty)
		setIsEdit(false)
	}, [item.orderQty, item.orderPrice])

	useOnClickOutside(rowRef, closeEditAction)

	const onChangeOrderQtyAction = (e: React.ChangeEvent<HTMLInputElement>) =>
		setOrderQty(e.target.value.replace(/[^\d.]/g, ""))

	const onChangeOrderPriceAction = (e: React.ChangeEvent<HTMLInputElement>) =>
		setOrderPrice(e.target.value.replace(/[^\d.]/g, ""))

	const submitEditAction = useCallback(() => {
		setIsEdit(false)
	}, [item.orderId])

	const cancelOrder = useCallback(async () => {
		if (!isCancelLoading) {
			try {
				setIsCancelLoading(true)

				await ExchangeService.cancelOrder(item.orderId)

				toast(
					<>
						<i className="ai ai-check_outline" />
						{formatMessage(messages.order_was_cancelled)}
					</>,
				)
			} catch (err) {
				errorHandler(err, false)
				setIsCancelLoading(false)
			}
		}
	}, [isCancelLoading, item.orderId])

	return (
		<li
			key={`${id}${item.orderId}-list-item`}
			className={`${styles.list__item} ${
				item.direction.toUpperCase() === OrderSideEnum.SELL ? styles.list__item___sell : ""
			}`}
			ref={rowRef}
		>
			{isScroll ? (
				<>
					<button
						type="button"
						aria-label="to left"
						onClick={handleArrowLeftClick}
						className={`${styles.list__itemButtonLeft} ${
							showArrows && showLeftArrow ? styles.list__itemButtonLeft___show : ""
						}`}
					>
						<ArrowRight />
					</button>

					<button
						type="button"
						aria-label="to right"
						onClick={handleArrowRightClick}
						className={`${styles.list__itemButtonRight} ${
							showArrows && showRightArrow ? styles.list__itemButtonRight___show : ""
						}`}
					>
						<ArrowRight />
					</button>
				</>
			) : null}

			{namesArr.map(nameItem => (
				<div
					key={`${id}${nameItem.asociate}-item`}
					className={`${styles.list__itemElem} ${
						nameItem.asociate === "direction" ? styles.list__itemElem___direction : ""
					} ${nameItem.asociate === "action" ? styles.list__itemElem___action : ""}`}
					style={{ minWidth: `${nameItem.width}px` }}
				>
					{nameItem.asociate === "orderValue" ? (
						<span className={styles.list__itemWithCurrency}>
							<span>
								{formatNumber(item.orderValue, {
									useGrouping: false,
									minimumFractionDigits: pairPricePrecision,
									maximumFractionDigits: pairPricePrecision,
								})}
							</span>

							<span className={styles.list__itemCurrency}>{quoteCurrencyCode}</span>
						</span>
					) : null}

					{nameItem.asociate === "orderPrice" ? (
						<span className={styles.list__itemWithCurrency}>
							<span>
								{formatNumber(item.orderPrice, {
									useGrouping: false,
									minimumFractionDigits: pairPricePrecision,
									maximumFractionDigits: pairPricePrecision,
								})}
							</span>

							<span className={styles.list__itemCurrency}>{quoteCurrencyCode}</span>
						</span>
					) : null}

					{nameItem.asociate === "orderQty" ? (
						<span className={styles.list__itemWithCurrency}>
							<span>
								{formatNumber(item.orderQty, {
									useGrouping: false,
									minimumFractionDigits: pairAmountPrecision,
									maximumFractionDigits: pairAmountPrecision,
								})}
							</span>

							<span className={styles.list__itemCurrency}>{baseCurrencyCode}</span>
						</span>
					) : null}

					{nameItem.asociate === "orderPriceOpenOrder" ? (
						<span className={styles.list__itemWithCurrency}>
							{isEdit ? (
								<>
									<button
										onClick={closeEditAction}
										type="button"
										className={styles.list__itemCrossButton}
									>
										<Cross />
									</button>

									<input
										className={styles.list__itemEditInput}
										type="number"
										onChange={onChangeOrderPriceAction}
										value={orderPrice}
										autoComplete="off"
										placeholder="--"
									/>

									<button
										onClick={submitEditAction}
										type="button"
										className={styles.list__itemCheckButton}
									>
										<CheckMarkIcon />
									</button>
								</>
							) : (
								<>
									{/*<button*/}
									{/*	onClick={editAction}*/}
									{/*	type="button"*/}
									{/*	className={styles.list__itemEditButton}*/}
									{/*>*/}
									{/*	<EditOrderIcon />*/}
									{/*</button>*/}

									<span>
										{formatNumber(item.orderPrice, {
											useGrouping: false,
											minimumFractionDigits: pairPricePrecision,
											maximumFractionDigits: pairPricePrecision,
										})}
									</span>

									<span className={styles.list__itemCurrency}>{quoteCurrencyCode}</span>
								</>
							)}
						</span>
					) : null}

					{nameItem.asociate === "orderQtyOpenOrder" ? (
						<span className={styles.list__itemWithCurrency}>
							{isEdit ? (
								<>
									<button
										onClick={closeEditAction}
										type="button"
										className={styles.list__itemCrossButton}
									>
										<Cross />
									</button>

									<input
										autoComplete="off"
										className={`${styles.list__itemEditInput} ${styles.list__itemEditInput___qty}`}
										type="number"
										onChange={onChangeOrderQtyAction}
										value={orderQty}
										placeholder="--"
									/>

									<button
										onClick={submitEditAction}
										type="button"
										className={styles.list__itemCheckButton}
									>
										<CheckMarkIcon />
									</button>
								</>
							) : (
								<>
									{/*<button*/}
									{/*	onClick={editAction}*/}
									{/*	type="button"*/}
									{/*	className={styles.list__itemEditButton}*/}
									{/*>*/}
									{/*	<EditOrderIcon />*/}
									{/*</button>*/}

									<span>
										{formatNumber(item.orderQty, {
											useGrouping: false,
											minimumFractionDigits: pairAmountPrecision,
											maximumFractionDigits: pairAmountPrecision,
										})}
									</span>

									<span className={styles.list__itemCurrency}>{baseCurrencyCode}</span>
								</>
							)}
						</span>
					) : null}

					{nameItem.asociate === "filledQty" ? (
						<span className={styles.list__itemWithCurrency}>
							<span>
								{formatNumber(item.filledQty, {
									useGrouping: false,
									minimumFractionDigits: pairAmountPrecision,
									maximumFractionDigits: pairAmountPrecision,
								})}
							</span>

							<span className={styles.list__itemCurrency}>{baseCurrencyCode}</span>
						</span>
					) : null}

					{nameItem.asociate === "unFilledQty" ? (
						<span className={styles.list__itemWithCurrency}>
							<span>
								{formatNumber(item.unFilledQty, {
									useGrouping: false,
									minimumFractionDigits: pairAmountPrecision,
									maximumFractionDigits: pairAmountPrecision,
								})}
							</span>

							<span className={styles.list__itemCurrency}>{baseCurrencyCode}</span>
						</span>
					) : null}

					{nameItem.asociate === "name" ? <span>{item.name}</span> : null}

					{nameItem.asociate === "direction" ? (
						<span>
							{formatMessage(
								item.direction.toUpperCase() === OrderSideEnum.SELL
									? messages.orders_table_type_1
									: messages.orders_table_type_2,
							)}
							{item.orderStatus === "PENDING" ? " (Pending)" : ""}
						</span>
					) : null}

					{nameItem.asociate === "orderType" ? (
						<span className={styles.list__itemOrderType}>
							{item.orderType === OrderTypeEnum.LIMIT
								? formatMessage(messagesExchange.order_type_limit)
								: ""}

							{item.orderType === OrderTypeEnum.MARKET
								? formatMessage(messagesExchange.order_type_market)
								: ""}

							{item.orderType === OrderTypeEnum.STOP_ORDER ||
							item.orderType === OrderTypeEnum.STOP_LIMIT
								? formatMessage(messagesExchange.order_type_stop_order)
								: ""}
						</span>
					) : null}

					{nameItem.asociate === "orderTime" ? <span>{item.orderTime}</span> : null}

					{nameItem.asociate === "orderId" ? <span>{item.orderId}</span> : null}

					{nameItem.asociate === "action" ? (
						<button
							onClick={cancelOrder}
							disabled={isCancelLoading}
							type="button"
							className={styles.list__itemButton}
						>
							<div className={styles.list__itemButtonLoader}>
								<Spinner size={18} />
							</div>

							<span>{item.action}</span>
						</button>
					) : null}
				</div>
			))}
		</li>
	)
}

export default observer(Row)

import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";

import useScrollArrows from "hooks/newUI/terminal/useScrollArrows";
import styles from "components/NewUI/Terminal/Bottom/OpenOrders/List/index.module.scss";
import HeadlineItem from "../HeadlineItem";
import Row from "../Row";

const Section: React.FC<{
	list: unknown[];
	arrNames: { headline: string; width: number; asociate: string }[];
	arrCount: number;
	id: number;
	isScroll?: boolean;
	pairAmountPrecision: number;
	pairPricePrecision: number;
}> = ({ list, arrNames, arrCount, isScroll, id, pairAmountPrecision, pairPricePrecision }) => {
	const namesArr = [...arrNames].splice(0, arrCount);
	const {
		ref,
		handleArrowLeftClick,
		showArrows,
		showLeftArrow,
		handleArrowRightClick,
		showRightArrow,
	} = useScrollArrows(undefined, false, false);

	return (
		<div
			className={`${styles.list__listWrapper}  ${
				showArrows ? styles.list__listWrapper___arrows : ""
			} ${isScroll ? styles.list__listWrapper___scroll : ""}`}
		>
			{isScroll ? (
				<>
					<button
						type="button"
						aria-label="to left"
						onClick={handleArrowLeftClick}
						className={`${styles.list__listButtonLeft} ${
							showArrows && showLeftArrow ? styles.list__listButtonLeft___show : ""
						}`}
					/>

					<button
						type="button"
						aria-label="to right"
						onClick={handleArrowRightClick}
						className={`${styles.list__listButtonRight} ${
							showArrows && showRightArrow ? styles.list__listButtonRight___show : ""
						}`}
					/>
				</>
			) : null}

			<div
				ref={isScroll ? ref : undefined}
				className={`${styles.list__listContainer} ${
					isScroll ? styles.list__listContainer___scroll : ""
				}`}
			>
				<ul className={styles.list__headline}>
					{namesArr.map(({ headline, width }) => (
						<HeadlineItem
							key={`${id}${headline}-headline-item`}
							headline={headline}
							width={width}
						/>
					))}
				</ul>

				{list.length > 0 ? (
					<ul className={styles.list__items}>
						{/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
						{list.map((item: any) => (
							<div key={`${id}${item.id}-${item.orderId}-trade-history-item`}>
								<Row
									handleArrowLeftClick={handleArrowLeftClick}
									showArrows={showArrows}
									showLeftArrow={showLeftArrow}
									showRightArrow={showRightArrow}
									handleArrowRightClick={handleArrowRightClick}
									key={`${id}${item.orderId}-list-item`}
									item={item}
									id={id}
									isScroll={isScroll}
									namesArr={namesArr}
									pairAmountPrecision={pairAmountPrecision}
									pairPricePrecision={pairPricePrecision}
								/>
							</div>
						))}
					</ul>
				) : null}
			</div>
		</div>
	);
};

export default observer(Section);

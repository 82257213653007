import React, { memo } from "react"
import Skeleton from "react-loading-skeleton"
import { useIntl } from "react-intl"

import historyMessages from "messages/history"
import commonMessages from "messages/common"
import styles from "../index.module.scss"

const Headline: React.FC<{ quoteCurrencyCode: string; baseCurrencyCode: string }> = ({
	quoteCurrencyCode,
	baseCurrencyCode,
}) => {
	const { formatMessage } = useIntl()

	return (
		<div className={styles.recentTrades__containerHeader}>
			<span className={styles.recentTrades__headerText}>
				{`${formatMessage(historyMessages.orders_table_price)}`} (
				{quoteCurrencyCode || <Skeleton width={26} height={11} borderRadius={2} />})
			</span>

			<span className={styles.recentTrades__headerText}>
				{`${formatMessage(commonMessages.quantity)}`} (
				{baseCurrencyCode || <Skeleton width={22} height={11} borderRadius={2} />})
			</span>

			<span className={styles.recentTrades__headerText}>
				{formatMessage(commonMessages.timestamp)}
			</span>
		</div>
	)
}

export default memo(Headline)

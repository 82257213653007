import React from "react"
import { Helmet } from "react-helmet"
import { useIntl } from "react-intl"
import { observer } from "mobx-react-lite"

import { getPageTitle } from "helpers/global"
import messages from "messages/common"

const Register: React.FC = () => {
	const { formatMessage } = useIntl()
	const title = getPageTitle(formatMessage(messages.register))
	const description = getPageTitle(formatMessage(messages.register_page_description))

	return (
		<Helmet
			title={title}
			meta={[
				{ name: "description", content: description },
				{ property: "og:title", content: title },
				{ property: "twitter:title", content: title },
				{ property: "og:description", content: description },
				{ name: "twitter:description", content: description },
			]}
		/>
	)
}

export default observer(Register)

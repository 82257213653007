/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useState } from "react"
import { components } from "react-select"
import cn from "classnames"

import SelectCheck from "assets/icons/SelectCheck"
import countries from "./countries"
import Select from "../Select"
import styles from "./CountrySelect.module.scss"
//
interface IDynamicImgProps {
	className?: string
	alt: string
	src: string
}

const DynamicImg: React.FC<IDynamicImgProps> = ({ className, alt, src }) => {
	const [icon, setIcon] = useState()

	useEffect(() => {
		import(
			/* webpackPrefetch: true */ /* webpackPreload: true */ /* webpackMode: "lazy-once" */ /* webpackChunkName: "flag-image" */ `./flags/${src}.svg`
		).then(module => setIcon(module.default))
	}, [])

	return <img src={icon} alt={alt} className={className} loading="lazy" width="24" height="24" />
}

const excludeCountries = [
	"aq",
	"bv",
	"bn",
	"cx",
	"cc",
	"gf",
	"tf",
	"gp",
	"gy",
	"hm",
	"va",
	"yt",
	"nc",
	"re",
	"sh",
	"lc",
	"pm",
	"vc",
	"gs",
	"sj",
	"tl",
	"um",
	"vg",
	"wf",
	"eh",
	"ax",
	"bq",
	"bl",
	"mf",
	"xk",
]

const buildCountriesList = (
	locale: string,
	overwriteCountries?: string[],
): { label: string; value: string }[] => {
	const countriesData = countries(locale)
	const list = Object.keys(countriesData)
		.filter(c => (overwriteCountries ? overwriteCountries.includes(c) : true))
		.map(c => ({
			label: countriesData[c],
			value: c.toLowerCase(),
		}))

	return list.filter(c => excludeCountries.indexOf(c.value) === -1)
}

const Option = React.memo(
	(props: any) => {
		const { data, isSelected } = props
		const value = data.value.toLowerCase()

		return (
			<components.Option {...props}>
				<div
					className={`${styles.option_content} ${
						isSelected ? styles.option_content__selected : ""
					}`}
				>
					{value ? (
						<div className={styles.img_block} key={value}>
							<DynamicImg src={value} alt={value} />
						</div>
					) : (
						<div className={styles.empty_flag}>
							<i className="ai ai-web_outlined" />
						</div>
					)}

					<span>{data.label}</span>

					{isSelected ? (
						<div className={styles.option_check}>
							<SelectCheck />
						</div>
					) : null}
				</div>
			</components.Option>
		)
	},
	(prevProps, nextProps) => prevProps.label === nextProps.label,
)

const SingleValue = (props: any) => {
	const { data } = props
	const value = data.value.toLowerCase()

	return (
		<components.SingleValue {...props} className={styles.single_value}>
			<div className={cn(styles.option_content, styles.value)}>
				<div className={styles.img_block} key={value}>
					<DynamicImg src={value} alt={value} />
				</div>

				{data.label}
			</div>
		</components.SingleValue>
	)
}

const PlaceholderCustom = (props: any) => (
	<components.Placeholder
		{...props}
		className={cn(styles.placeholder_comp_container, props.selectProps.error && styles.error)}
	>
		<div className={styles.placeholder_comp}>
			<div className={styles.img_block}>
				<i className="ai ai-web_outlined" />
			</div>
		</div>

		{props.children}
	</components.Placeholder>
)

const ValueContainer = ({ children, ...props }: any) => (
	<components.ValueContainer {...props} className={styles.value_container}>
		{!children?.[0] ? (
			<div className={styles.img_block}>
				<i className="ai ai-web_outlined" />
			</div>
		) : null}

		{children}
	</components.ValueContainer>
)

const SelectComponents = {
	Option,
	SingleValue,
	Placeholder: PlaceholderCustom,
	ValueContainer,
	IndicatorSeparator: () => null,
}

const CountrySelect: React.FC<any> = props => {
	const list = buildCountriesList(props.locale, props.countries)
	const value: any = list.find(item => item.value === props.value?.toLowerCase())

	const handleSelectChange = (e: any) => {
		const { name, onSelect } = props
		onSelect(name, (e && e.value.toUpperCase()) || null)
	}

	return (
		<Select
			{...props}
			options={list}
			isClearable
			value={value || ""}
			onChange={handleSelectChange}
			instanceId={props.name}
			label={props.label}
			error={props.error}
			components={SelectComponents}
			placeholder={props.placeholder}
			labeledAbsolute
		/>
	)
}

export default CountrySelect

/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useEffect, useRef } from "react"
import { Chart, registerables } from "chart.js"
import styles from "styles/components/MiniChart.module.scss"
import cn from "classnames"
import { IPromotedPairCandle } from "types/home"

Chart.register(...registerables)

interface IMiniChartProps {
	width?: number
	className?: string
	candles: IPromotedPairCandle[]
}

const MiniChart: React.FC<IMiniChartProps> = ({ candles, className, width }) => {
	const chartRef = useRef(null)
	const data: number[] = []
	const labels: number[] = []

	candles.forEach(candle => {
		data.push(candle.price)
		labels.push(candle.price)
	})

	useEffect(() => {
		if (chartRef.current) {
			// eslint-disable-next-line no-new
			new Chart(chartRef.current, {
				type: "line",
				options: {
					responsive: true,
					scales: {
						x: {
							display: false,
						},
						y: {
							display: false,
						},
					},
					plugins: {
						legend: {
							display: false,
						},
					},
					elements: {
						point: {
							radius: 0,
						},
					},
				},
				data: {
					labels,
					datasets: [
						{
							data,
							backgroundColor: "transparent",
							borderColor: "#4E55A2",
							borderWidth: width ?? 2,
						},
					],
				},
			})
		}
	}, [chartRef])

	return (
		<div className={cn(styles.top_pairs_mini_chart, className ?? "")}>
			<canvas className={styles.top_pairs_mini_chart_canvas} ref={chartRef} />
		</div>
	)
}

export default MiniChart

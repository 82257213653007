import React, { FC, memo } from "react"
import dayjs from "dayjs"

import styles from "components/NewUI/Header/Navigation/index.module.scss"
import Key from "assets/icons/header/Key"
import { routes } from "constants/routing"
import InternalLink from "components/InternalLink"

const Item: FC<{ title: string; created_at: string; uuid: string; readAt: null | string }> = ({
	title,
	created_at,
	uuid,
	readAt,
}) => (
	<li className={styles.nav__itemFirst}>
		<InternalLink
			to={routes.profile.getNotificationDetails(uuid, false)}
			className={`${styles.nav__notificationsButton} ${
				readAt === null ? styles.nav__notificationsButton___unRead : ""
			}`}
		>
			<Key />

			<div className={styles.nav__notificationsContent}>
				<span className={styles.nav__notificationsMessage}>{title}</span>

				<span className={styles.nav__notificationsDate}>{dayjs(created_at).fromNow()}</span>
			</div>
		</InternalLink>
	</li>
)

export default memo(Item)

import React from "react";
import { observer } from "mobx-react-lite";

import styles from "components/NewUI/Terminal/Bottom/ListHeadline/index.module.scss";

const ListHeadline: React.FC<{
	list: { headline: string; width: number; asociate: string }[];
	listScheme: { arrCount: number; id: number; isScroll?: boolean }[];
}> = ({ list, listScheme }) => (
	<div className={styles.listHeadline}>
		{listScheme.map(({ id, arrCount, isScroll }) => (
			<div
				key={`${id}-ListHeadline-list`}
				className={`${styles.listHeadline__listWrapper} ${
					isScroll ? styles.listHeadline__listWrapper___scroll : ""
				}`}
			>
				<ul key={`${id}-ListHeadline-list`} className={styles.listHeadline__list}>
					{list.splice(0, arrCount).map(({ headline, width }) => (
						<li
							key={`${headline}-ListHeadline-item`}
							className={styles.listHeadline__item}
							style={{ width: `${width}px` }}
						>
							{headline}
						</li>
					))}
				</ul>
			</div>
		))}
	</div>
);

export default observer(ListHeadline);

import React, { memo } from "react"

const Google = () => (
	<svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
		<path
			d="M19.3 10.2084C19.3 9.55837 19.2417 8.93337 19.1333 8.33337H10.5V11.8834H15.4333C15.2167 13.025 14.5667 13.9917 13.5917 14.6417V16.95H16.5667C18.3 15.35 19.3 13 19.3 10.2084Z"
			fill="#4285F4"
		/>
		<path
			d="M10.5 19.1667C12.975 19.1667 15.05 18.35 16.5667 16.95L13.5917 14.6417C12.775 15.1917 11.7333 15.525 10.5 15.525C8.11665 15.525 6.09165 13.9167 5.36665 11.75H2.31665V14.1167C3.82498 17.1083 6.91665 19.1667 10.5 19.1667Z"
			fill="#34A853"
		/>
		<path
			d="M5.36659 11.7417C5.18325 11.1917 5.07492 10.6084 5.07492 10.0001C5.07492 9.39172 5.18325 8.80839 5.36659 8.25839V5.89172H2.31659C1.69159 7.12506 1.33325 8.51672 1.33325 10.0001C1.33325 11.4834 1.69159 12.8751 2.31659 14.1084L4.69159 12.2584L5.36659 11.7417Z"
			fill="#FBBC05"
		/>
		<path
			d="M10.5 4.48337C11.85 4.48337 13.05 4.95004 14.0083 5.85004L16.6333 3.22504C15.0417 1.74171 12.975 0.833374 10.5 0.833374C6.91665 0.833374 3.82498 2.89171 2.31665 5.89171L5.36665 8.25837C6.09165 6.09171 8.11665 4.48337 10.5 4.48337Z"
			fill="#EA4335"
		/>
	</svg>
)

export default memo(Google)

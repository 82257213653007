import React from "react"
import { observer } from "mobx-react-lite"

import styles from "components/NewUI/Terminal/Bottom/TradeHistory/List/index.module.scss"
import Section from "./Section"

const List: React.FC<{
	list: unknown[]
	listScheme: {
		arrCount: number
		id: number
		isScroll?: boolean
		arrNames: { headline: string; width: number; asociate: string }[]
	}[]
	handleMarginRepay: (currency: string, asset: string) => void
}> = ({ list, listScheme, handleMarginRepay }) => (
	<>
		<div className={styles.list}>
			{listScheme.map(({ id, arrCount, isScroll, arrNames }) => (
				<Section
					key={`${id}-list`}
					list={list}
					arrNames={arrNames}
					arrCount={arrCount}
					isScroll={isScroll}
					id={id}
					handleMarginRepay={handleMarginRepay}
				/>
			))}
		</div>

		{list.length === 0 ? <div className={styles.list__empty}>You have no borrows.</div> : null}
	</>
)

export default observer(List)

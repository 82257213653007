import React, { useState } from "react";
import { useIntl } from "react-intl";
import styles from "styles/pages/ProfileVerification.module.scss";
import messages from "messages/verification";
import Button from "components/UI/Button";
import QRCode from "components/UI/QRCode";
import icon404 from "assets/images/common/404.svg";
import VerificationService from "services/VerificationService";
import errorHandler from "utils/errorHandler";
import { useNavigate } from "react-router-dom";
import { IShareDiiaDocumentEnum } from "types/verification";
import useWindowSize from "hooks/useWindowSize";

interface IProps {
	diiaLink: string;
	document: IShareDiiaDocumentEnum;
}

const DiiaForm: React.FC<IProps> = ({ diiaLink, document }) => {
	const { formatMessage } = useIntl();
	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const { tablet } = useWindowSize();

	const handleRefreshQRCode = async () => {
		try {
			setIsLoading(true);
			const data = await VerificationService.shareDiia({ document });
			const link = data?.deeplink;
			if (link) {
				navigate({
					search: `?link=${link}&document=${document}`,
				});
			}
		} catch (err) {
			errorHandler(err);
		} finally {
			setIsLoading(false);
		}
	};

	const handleQRCodeClick = () => {
		if (diiaLink && tablet) {
			window.open(diiaLink, "_blank", "noopener");
		}
	};

	return (
		<div className={styles.verification_diia}>
			{diiaLink ? (
				<>
					<div className={styles.verification_diia_steps}>
						<div className={styles.verification_diia_step}>
							1. {formatMessage(messages.open_diia_app)}
						</div>
						<div className={styles.verification_diia_step}>
							2. {formatMessage(messages.open_a_qr_code_scanner)}
						</div>
						<div className={styles.verification_diia_step}>
							3. {formatMessage(messages.scan_the_provided_qr_code)}
						</div>
					</div>
					<div className={styles.verification_diia_qr_code} onClick={handleQRCodeClick}>
						{tablet && <span>{formatMessage(messages.click_on_the_qr_code)}</span>}
						<QRCode value={diiaLink} size={250} opened />
					</div>
				</>
			) : (
				<div className={styles.verification_diia_error}>
					<span>{formatMessage(messages.try_to_get_a_new_qr_code)}</span>
					<img src={icon404} alt="Not found" height="150" />
				</div>
			)}
			<Button
				type="button"
				iconCode="change"
				variant="text"
				isLoading={isLoading}
				onClick={handleRefreshQRCode}
				label={formatMessage(messages.get_a_new_qr_code)}
			/>
		</div>
	);
};

export default DiiaForm;

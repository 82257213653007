import { IEventArgs } from "types/authenticatorSetup";

export const ReactGaOpenArgs: IEventArgs = {
	category: "Security",
	action: "setup2fa:open",
	label: "Open 2fa setup",
	nonInteraction: false,
};

export const ReactGaQrCodeArgs: IEventArgs = {
	category: "Security",
	action: "setup2fa:qrcode",
	label: "Open 2fa qrcode",
	nonInteraction: false,
};

export const ReactGaFormArgs: IEventArgs = {
	category: "Security",
	action: "setup2fa:form",
	label: "Open 2fa form",
	nonInteraction: false,
};

export const ReactGaCompleteArgs: IEventArgs = {
	category: "Security",
	action: "setup2fa:complete",
	label: "Complete 2fa setup",
	nonInteraction: false,
};

import React from "react"
import styles from "./CheckBox.module.scss"

export interface ICheckbox {
	name: string
	checked: boolean
	children?: React.ReactNode
	onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
	disabled?: boolean
	required?: boolean
	errors?: string
}

const CheckBox: React.FC<ICheckbox> = ({
	name,
	disabled = false,
	checked = false,
	children,
	onChange,
	required = false,
	errors,
}) => (
	<div className={styles.checkBox}>
		<label className={styles.checkBox__label} htmlFor={`checkbox-${name}`}>
			<input
				id={`checkbox-${name}`}
				type="checkbox"
				name={name}
				onChange={onChange}
				required={required}
				disabled={disabled}
				checked={checked}
				className={styles.checkBox__input}
			/>

			<span
				className={`${styles.checkBox__checkmark} ${
					checked ? styles.checkBox__checkmark___checked : ""
				} ${errors ? styles.checkBox__checkmark___errors : ""}`}
			/>

			<span className={styles.checkBox__content}>{children}</span>
		</label>
	</div>
)

export default CheckBox

import React, { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { observer } from "mobx-react-lite"
import cookies from "js-cookie"

import AuthLayout from "layouts/AuthLayout"
import LoadingSpinner from "components/UI/LoadingSpinner"
import HelmetLogin from "components/NewUI/Helmets/Login"
import LoginForm from "components/NewUI/Auth/LoginForm"
import { useMst } from "models/Root"
import useParamQuery from "hooks/useSearchQuery"
import { routes, URL_VARS } from "constants/routing"
import { getRedirectPathname } from "helpers/getRedirectPathname"
import config from "helpers/config"

const Login: React.FC = () => {
	const {
		global: { isAuthenticated, locale },
	} = useMst()
	const query = useParamQuery()
	const navigate = useNavigate()

	useEffect(() => {
		const sessionCookieName = cookies.get(config.sessionCookieName)
		const csrfCookieName = cookies.get(config.csrfCookieName)

		if (isAuthenticated && csrfCookieName && sessionCookieName) {
			const redirectQuery = query.get(URL_VARS.REDIRECT)
			const pathname = redirectQuery ? getRedirectPathname(redirectQuery) : ""

			navigate(pathname || `/${locale}${routes.profile.wallets}`)
		}
	}, [isAuthenticated])

	if (isAuthenticated) {
		return <LoadingSpinner />
	}

	return (
		<AuthLayout>
			<HelmetLogin />

			<LoginForm />
		</AuthLayout>
	)
}

export default observer(Login)

import React, { useMemo, useState } from "react"
import { observer } from "mobx-react-lite"
import { useIntl } from "react-intl"

import { useMst } from "models/Root"
import messages from "messages/common"
import List from "./List"
import styles from "./index.module.scss"
import ListHeader from "./ListHeader"
import Filter from "./Filter"
import CurrentPrice from "./CurrentPrice"

const Orders: React.FC = () => {
	const { formatMessage, formatNumber } = useIntl()
	const [currentListType, setCurrentListType] = useState("AllOrders")
	const {
		terminal: {
			pair,
			orderBookPrecision,
			recentTradeDiff,
			setOrderBookPrecision,
			precisionArray,
			baseCurrencyCode,
			quoteCurrencyCode,
			isLoaded,
			pairPricePrecision,
			sellList,
			buyList,
		},
	} = useMst()
	const price = useMemo(() => {
		if (pair?.close)
			return formatNumber(pair.close, {
				useGrouping: true,
				maximumFractionDigits: pairPricePrecision,
				minimumFractionDigits: pairPricePrecision,
			})

		return "0"
	}, [pair?.close, formatNumber, pairPricePrecision])

	return (
		<section className={styles.orders}>
			<h2 className="visually-hidden">{formatMessage(messages.orderbook)}</h2>

			<div className={styles.orders__header}>
				<button className={styles.orders__headerTabButton} type="button">
					<span>{formatMessage(messages.orderbook)}</span>
				</button>
			</div>

			{/* The positions of the elements are crucial, as they are styled in a hierarchical order. If you change the HTML, also adjust the CSS. */}
			<div className={styles.orders__content}>
				<Filter
					setCurrentListType={setCurrentListType}
					currentListType={currentListType}
					precisionArray={precisionArray}
					orderBookPrecision={orderBookPrecision}
					setOrderBookPrecision={setOrderBookPrecision}
					isLoaded={isLoaded}
				/>

				<div className={styles.orders__container}>
					<ListHeader quoteCurrencyCode={quoteCurrencyCode} baseCurrencyCode={baseCurrencyCode} />

					<div className={styles.orders__lists}>
						<List
							isSell
							list={sellList}
							decimalPlaces={pair && precisionArray[0][0] ? precisionArray[0][0] : 2}
							currentListType={currentListType}
						/>

						<CurrentPrice isLoaded={isLoaded} recentTradeDiff={recentTradeDiff} price={price} />

						<ListHeader
							quoteCurrencyCode={quoteCurrencyCode}
							baseCurrencyCode={baseCurrencyCode}
							isBuy
						/>

						<List
							decimalPlaces={pair && precisionArray[0][0] ? precisionArray[0][0] : 2}
							list={buyList}
							currentListType={currentListType}
						/>
					</div>
				</div>
			</div>
		</section>
	)
}

export default observer(Orders)

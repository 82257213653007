import React, { memo } from "react";

const MarginInfo = () => (
	<svg width={14} height={14} fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M7 .583a6.417 6.417 0 100 12.834A6.417 6.417 0 007 .583zm0 3.5A.583.583 0 107 5.25h.006a.583.583 0 100-1.167H7zM7.583 7a.583.583 0 00-1.167 0v2.334a.583.583 0 001.167 0V7z"
			fill="#EBEBF5"
			fillOpacity={0.6}
		/>
	</svg>
);

export default memo(MarginInfo);

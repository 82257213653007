import React, { memo } from "react";

const Dots = () => (
	<svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M6.667 8a1.333 1.333 0 112.666 0 1.333 1.333 0 01-2.667 0zM6.667 3.333a1.333 1.333 0 112.666 0 1.333 1.333 0 01-2.667 0zM6.667 12.667a1.333 1.333 0 112.666 0 1.333 1.333 0 01-2.667 0z"
			fill="#EBEBF5"
			fillOpacity={0.6}
		/>
	</svg>
);

export default memo(Dots);

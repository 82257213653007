import { useEffect } from "react";

const useBodyOverflowHidden = (shouldHideOverflow: boolean) => {
	useEffect(() => {
		// Save the current overflow value for the html element
		const originalOverflow = document.documentElement.style.overflow;

		// Set overflow:hidden if needed
		if (shouldHideOverflow) {
			document.documentElement.style.overflow = "hidden";
		}

		// Restore the style to its original value on unmount or state change
		return () => {
			document.documentElement.style.overflow = originalOverflow;
		};
	}, [shouldHideOverflow]); // Re-run the effect only when shouldHideOverflow changes
};

export default useBodyOverflowHidden;

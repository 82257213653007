import { useEffect, useMemo } from "react"
import { useIntl } from "react-intl"

import { ACCOUNT_TYPE } from "constants/exchange"
import useAccountType from "hooks/useAccountType"
import { useMst } from "models/Root"
import { queryVars } from "constants/query"
import historyMessages from "../../../messages/history"

const useOpenOrder = ({ isShowAll = false }) => {
	const { formatMessage } = useIntl()
	const {
		global: { isAuthenticated },
		history: {
			loadOpenedOrders,
			setFilteringSymbol,
			openedOrdersList,
			setFilterTypeOpenedOrders,
			filterTypeOpenedOrders,
			isOpenedOrdersLoading,
		},
		terminal: { pairSymbol, setShowAllOpenedOrders, pairAmountPrecision, pairPricePrecision },
	} = useMst()
	const type = useAccountType()
	const params = useMemo(
		() => ({
			[queryVars.pair]: !isShowAll ? pairSymbol : undefined,
			[queryVars.page_size]: 50,
			[queryVars.wallet_type]: ACCOUNT_TYPE[type],
		}),
		[pairSymbol, type, isShowAll],
	)

	const listScheme = [
		{
			arrCount: 1,
			id: 1,
			arrNames: [
				{
					headline: formatMessage(historyMessages.active_orders_pair),
					width: 120,
					asociate: "name",
				},
			],
			isScroll: false,
		},
		{
			arrCount: 9,
			id: 2,
			isScroll: true,
			arrNames: [
				{
					headline: formatMessage(historyMessages.active_orders_side),
					width: 120,
					asociate: "direction",
				},
				{ headline: "Order Type", width: 100, asociate: "orderType" },
				{ headline: "Order Value", width: 150, asociate: "orderValue" },
				{ headline: "Order Price", width: 140, asociate: "orderPriceOpenOrder" },
				{ headline: "Order Q-ty", width: 168, asociate: "orderQtyOpenOrder" },
				{ headline: "Filled Q-ty", width: 168, asociate: "filledQty" },
				{ headline: "Unfilled Q-ty", width: 168, asociate: "unFilledQty" },
				{ headline: "Order Time", width: 168, asociate: "orderTime" },
				{ headline: "Order ID", width: 110, asociate: "orderId" },
			],
		},
		{
			arrCount: 1,
			id: 3,
			isScroll: false,
			arrNames: [{ headline: "Action", width: 80, asociate: "action" }],
		},
	]

	const listHeadline = listScheme.flatMap(item => item.arrNames)

	useEffect(() => {
		if (isAuthenticated && pairSymbol) {
			setShowAllOpenedOrders(false)
			setFilteringSymbol("")
			loadOpenedOrders(params)
		}
	}, [isAuthenticated, pairSymbol, params, isShowAll])

	return {
		isAuthenticated,
		listScheme,
		list: openedOrdersList,
		listHeadline,
		pairAmountPrecision,
		pairPricePrecision,
		setFilterTypeOpenedOrders,
		filterTypeOpenedOrders,
		isOpenedOrdersLoading,
	}
}

export default useOpenOrder

import React, { useCallback } from "react"
import { observer } from "mobx-react-lite"
import { useLocation, useNavigate } from "react-router"

import { useMst } from "models/Root"
import useBodyOverflowHidden from "hooks/newUI/useOverflowHidden"
import { TradeTypeEnum } from "types/exchange"
import { queryVars } from "constants/query"
import { routes } from "constants/routing"
import { VERIFICATION_LEVELS } from "constants/exchange"
import useLocaleNavigate from "hooks/useLocaleNavigate"
import errorHandler from "utils/errorHandler"
import MarginRiskModal from "../../../Terminal/modals/MarginRiskModal"

const RiskMarginModal: React.FC = () => {
	const {
		terminal: { isMarginAccepted, setMarginRiskStatus },
		tickers: {
			filter: { setTradeType },
		},
		finance: { marginRequiredVerificationLevel: requiredVerificationLevel },
	} = useMst()
	const navigate = useNavigate()
	const localeNavigate = useLocaleNavigate()
	const location = useLocation()

	useBodyOverflowHidden(!isMarginAccepted)

	const onClose = useCallback(() => {
		setTradeType(TradeTypeEnum.SPOT)
		navigate(`${location.pathname}?${queryVars.type}=${TradeTypeEnum.SPOT}`)
	}, [navigate, location.pathname, setTradeType])

	const handleSubmit = async (): Promise<void> => {
		if (requiredVerificationLevel) {
			onClose()
			localeNavigate(
				routes.verification.getVerificationType(VERIFICATION_LEVELS[requiredVerificationLevel]),
			)
		} else {
			try {
				await setMarginRiskStatus()
			} catch (err) {
				errorHandler(err)
			}
		}
	}
	return (
		<>
			{!isMarginAccepted ? (
				<MarginRiskModal
					onClose={onClose}
					onCloseAccepted={handleSubmit}
					requiredVerificationLevel={requiredVerificationLevel}
				/>
			) : null}
			<span />
		</>
	)
}

export default observer(RiskMarginModal)

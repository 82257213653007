import React, { useCallback } from "react"
import { observer } from "mobx-react-lite"
import { useIntl } from "react-intl"

import styles from "components/NewUI/Terminal/Bottom/Borrows/List/index.module.scss"
import ArrowRight from "assets/icons/terminal/ArrowRight"
import Spinner from "components/UI/Spinner"

const Row: React.FC<{
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	item: any
	id: number
	namesArr: { headline: string; width: number; asociate: string }[]
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	handleArrowRightClick: any
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	handleArrowLeftClick: any
	showArrows: boolean
	showLeftArrow: boolean
	showRightArrow: boolean
	isScroll?: boolean
	handleMarginRepay: (currency: string, asset: string) => void
}> = ({
	item,
	id,
	namesArr,
	handleArrowLeftClick,
	showArrows,
	showLeftArrow,
	showRightArrow,
	handleArrowRightClick,
	isScroll,
	handleMarginRepay,
}) => {
	const { formatNumber } = useIntl()

	const repay = useCallback(() => handleMarginRepay(item.code, ""), [item.code, handleMarginRepay])

	return (
		<li key={`${id}${item.orderId}-list-item`} className={styles.list__item}>
			{isScroll ? (
				<>
					<button
						type="button"
						aria-label="to left"
						onClick={handleArrowLeftClick}
						className={`${styles.list__itemButtonLeft} ${
							showArrows && showLeftArrow ? styles.list__itemButtonLeft___show : ""
						}`}
					>
						<ArrowRight />
					</button>

					<button
						type="button"
						aria-label="to right"
						onClick={handleArrowRightClick}
						className={`${styles.list__itemButtonRight} ${
							showArrows && showRightArrow ? styles.list__itemButtonRight___show : ""
						}`}
					>
						<ArrowRight />
					</button>
				</>
			) : null}

			{namesArr.map(nameItem => (
				<div
					key={`${id}${nameItem.asociate}-item`}
					className={`${styles.list__itemElem} ${
						nameItem.asociate === "direction" ? styles.list__itemElem___direction : ""
					} ${nameItem.asociate === "action" ? styles.list__itemElem___action : ""}`}
					style={{ minWidth: `${nameItem.width}px` }}
				>
					{nameItem.asociate === "currency" ? (
						<span className={styles.list__currency}>
							<img className={styles.list__currencyImage} src={item.image_svg} alt={item.code} />

							<span className={styles.list__currencyContent}>
								<span className={styles.list__currencyName}>{item.name}</span>
								<span className={styles.list__currencySymbol}>{item.code}</span>
							</span>
						</span>
					) : null}

					{nameItem.asociate === "borrowedAmount" ? (
						<span className={styles.list__itemWithCurrency}>
							<span>
								{formatNumber(item.borrowed, {
									useGrouping: true,
									minimumFractionDigits: item.precision,
									maximumFractionDigits: item.precision,
								})}
							</span>

							<span className={styles.list__itemCurrency}>{item.code}</span>
						</span>
					) : null}

					{nameItem.asociate === "hourlyBorrowRate" ? (
						<span className={styles.list__itemWithCurrency}>
							<span>{(item.interest_rate * 100) / 24}%</span>
						</span>
					) : null}

					{nameItem.asociate === "maxBorrowingAmount" ? (
						<span className={styles.list__itemWithCurrency}>
							<span>
								{formatNumber(item.max_loan_amount, {
									useGrouping: true,
									minimumFractionDigits: 0,
									maximumFractionDigits: item.precision,
								})}
							</span>

							<span className={styles.list__itemCurrency}>{item.code}</span>
						</span>
					) : null}

					{nameItem.asociate === "action" ? (
						<button onClick={repay} type="button" className={styles.list__itemButton}>
							<div className={styles.list__itemButtonLoader}>
								<Spinner size={18} />
							</div>

							<span>Repay</span>
						</button>
					) : null}
				</div>
			))}
		</li>
	)
}

export default observer(Row)

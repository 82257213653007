import React from "react"
import { observer } from "mobx-react-lite"

import { useMst } from "models/Root"
import { TradeTypeEnum } from "types/exchange"
import styles from "components/NewUI/Terminal/Bottom/index.module.scss"
import Header from "./Header"
import OpenOrders from "./OpenOrders"
import OrderHistory from "./OrderHistory"
import TradeHistory from "./TradeHistory"
import Positions from "./Positions"
import Funds from "./Funds"
import Borrows from "./Borrows"

const Bottom: React.FC = () => {
	const {
		global: { isAuthenticated },
		tickers: { filter },
		history: { openedOrdersCount, setCurrentOpenTab, currentOpenTab },
	} = useMst()
	const [isShowAll, handleShowAll] = React.useState(false)

	return (
		<section className={styles.bottom}>
			<h2 className="visually-hidden">Bottom</h2>

			<Header
				openedOrdersCount={openedOrdersCount}
				isAuthenticated={isAuthenticated}
				currentValue={currentOpenTab}
				handleChange={setCurrentOpenTab}
				isMargin={filter.tradeType === TradeTypeEnum.CROSS}
				isShowAll={isShowAll}
				handleShowAll={handleShowAll}
			/>

			<OpenOrders isOpen={currentOpenTab === "OpenOrdersTab"} isShowAll={isShowAll} />

			{currentOpenTab === "OrderHistoryTab" ? <OrderHistory isShowAll={isShowAll} /> : null}

			{currentOpenTab === "TradeHistoryTab" ? <TradeHistory isShowAll={isShowAll} /> : null}

			{currentOpenTab === "PositionsTab" ? <Positions /> : null}

			{currentOpenTab === "BorrowsTab" ? <Borrows /> : null}

			{currentOpenTab === "FundsTab" ? <Funds /> : null}
		</section>
	)
}

export default observer(Bottom)

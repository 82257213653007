import { matchPath } from "react-router-dom"

import { routes } from "../constants/routing"

export const getRedirectPathname = (redirectQuery: string): string => {
	for (const route of Object.values(routes)) {
		if (typeof route === "object") {
			for (const pattern of Object.values(route)) {
				if (typeof pattern === "string") {
					const path =
						matchPath(pattern, redirectQuery) ?? matchPath(`/:locale${pattern}`, redirectQuery)
					if (path) {
						return path.pathname
					}
				}
			}
		} else if (typeof route === "string") {
			const path = matchPath(route, redirectQuery) ?? matchPath(`/:locale${route}`, redirectQuery)
			if (path) {
				return path.pathname
			}
		}
	}

	return ""
}

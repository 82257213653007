import React, { FC, useCallback, useEffect } from "react"
import { useIntl } from "react-intl"
import { observer } from "mobx-react-lite"

import styles from "components/NewUI/Header/Navigation/index.module.scss"
import InternalLink from "components/InternalLink"
import commonMessages from "messages/common"
import { routes } from "constants/routing"
import Eye from "assets/icons/header/Eye"
import { useMst } from "models/Root"
import { FORMAT_NUMBER_OPTIONS_BTC, FORMAT_NUMBER_OPTIONS_USDT } from "constants/format"
import { STARS_STR } from "constants/wallet"
import useAutoFetch from "hooks/useAutoFetch"
import HiddenEye from "assets/icons/header/HiddenEye"

const WalletValue: FC = () => {
	const { formatMessage, formatNumber } = useIntl()
	const {
		account: { loadRates, totalBalance },
		global: { isWSDown },
		finance: { isBalancesVisible, setIsBalancesVisible },
	} = useMst()

	const toggleBalancesVisibility = useCallback(() => {
		setIsBalancesVisible(!isBalancesVisible)
	}, [isBalancesVisible])

	useEffect(() => {
		loadRates().then(() => null)
	}, [])

	useAutoFetch(loadRates, isWSDown)

	return (
		<div className={styles.nav__userData}>
			<div className={styles.nav__userDataTop}>
				<span className={styles.nav__userText}>{formatMessage(commonMessages.assetsOverview)}</span>

				<button type="button" aria-label="show/hide balances" onClick={toggleBalancesVisibility}>
					{isBalancesVisible ? <HiddenEye /> : <Eye />}
				</button>
			</div>

			<InternalLink
				to={routes.profile.wallets}
				className={`${styles.nav__userDataBottom} ${styles.nav__userDataBottom___money}`}
			>
				<p className={styles.nav__userDataBottomMoneyText}>
					{isBalancesVisible
						? formatNumber(totalBalance?.USDT ?? 0, FORMAT_NUMBER_OPTIONS_USDT)
						: STARS_STR}{" "}
					<span className={styles.nav__userDataBottomCurrencyText}>USDT</span>
				</p>
				<p className={styles.nav__userDataBottomBTCText}>
					≈{" "}
					{isBalancesVisible
						? formatNumber(totalBalance?.BTC ?? 0, FORMAT_NUMBER_OPTIONS_BTC)
						: STARS_STR}{" "}
					BTC
				</p>
			</InternalLink>
		</div>
	)
}

export default observer(WalletValue)

import React, { memo } from "react";
import Skeleton from "react-loading-skeleton";

import styles from "../index.module.scss";

const CurrentPrice: React.FC<{
	isLoaded: boolean;
	recentTradeDiff: number;
	price: string;
}> = ({ isLoaded, recentTradeDiff, price }) => (
	<div className={styles.orders__currentPrice}>
		<span
			className={`${styles.orders__currentPriceText} ${
				recentTradeDiff !== 0
					? recentTradeDiff > 0
						? styles.orders__currentPriceText___green
						: styles.orders__currentPriceText___red
					: ""
			}`}
		>
			{isLoaded ? price : <Skeleton width={81} height={20} borderRadius={2} />}
		</span>

		<span className={styles.orders__currentPriceText2}>
			{isLoaded ? `≈ ${price} USD` : <Skeleton width={90} height={20} borderRadius={2} />}
		</span>
	</div>
);

export default memo(CurrentPrice);

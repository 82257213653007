import React, { useEffect } from "react";
import MainLayout from "layouts/MainLayout";
import { Helmet } from "react-helmet";
import styles from "styles/pages/VerificationDiiaSuccess.module.scss";
import messages from "messages/verification";
import { useParams } from "react-router-dom";
import { getPageTitle } from "helpers/global";
import { useIntl } from "react-intl";
import SuccessScreen from "components/UI/SuccessScreen";
import Button from "components/UI/Button";
import CheckMark from "components/UI/CheckMark";
import InternalLink from "components/InternalLink";

const VerificationDiiaSuccess: React.FC = () => {
	const { formatMessage } = useIntl();
	const text = getPageTitle(formatMessage(messages.verification_diia_success_title));

	return (
		<MainLayout>
			<Helmet
				title={text}
				meta={[
					{ name: "description", content: text },
					{ property: "og:title", content: text },
					{ property: "twitter:title", content: text },
					{ property: "og:description", content: text },
					{ name: "twitter:description", content: text },
				]}
			/>
			<div className={styles.wrapper}>
				<div className={styles.container}>
					<CheckMark />
					<span>{formatMessage(messages.verification_diia_success_title)}</span>
					<InternalLink to="/trade">
						<Button
							fullWidth
							variant="outlined"
							color="primary"
							label={formatMessage(messages.verification_diia_success_action)}
						/>
					</InternalLink>
				</div>
			</div>
		</MainLayout>
	);
};

export default VerificationDiiaSuccess;

import classnames from "classnames";
import { Faq, Hero, Mobile, Offer, TopPairs, News } from "components/HomePage";
import LandingLayout from "layouts/LandingLayout";
import messages from "messages/home";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useIntl } from "react-intl";
import { SkeletonTheme } from "react-loading-skeleton";
import styles from "styles/pages/HomePage.module.scss";

const HomePage: React.FC = () => {
	const { formatMessage } = useIntl();
	const title = formatMessage(messages.page_title);
	const description = formatMessage(messages.page_description);

	useEffect(() => {
		document.body.setAttribute("data-v", "");
		setTimeout(() => {
			document.body.setAttribute("data-v", "theme");
		});

		return () => {
			document.body.removeAttribute("data-v");
		};
	}, []);

	return (
		<LandingLayout className={classnames(styles.landing)}>
			<Helmet
				title={title}
				meta={[
					{ property: "og:title", content: title },
					{ name: "description", content: description },
					{ property: "twitter:title", content: title },
					{ name: "twitter:description", content: description },
					{ property: "og:description", content: description },
				]}
			/>
			<SkeletonTheme baseColor="#12172ec7" highlightColor="#12172ec7">
				<div className={classnames(styles.container)}>
					<div className={styles.limiter}>
						<Hero />
						<Mobile />
						<News />
						<TopPairs />
						<Offer />
						<Faq />
					</div>
				</div>
			</SkeletonTheme>
		</LandingLayout>
	);
};

export default HomePage;

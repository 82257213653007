import React, { ChangeEvent, useCallback } from "react";
import { useIntl } from "react-intl";
import { observer } from "mobx-react-lite";

import Input from "components/NewUI/Input";
import messages from "messages/exchange";
import commonMessages from "messages/common";
import { TradeActionsEnum } from "types/exchange";
import styles from "components/NewUI/Terminal/FunctionalPanel/OrderPriceQty/index.module.scss";
import FormBottomArrow from "assets/icons/terminal/FormBottomArrow";
import FormTopArrow from "assets/icons/terminal/FormTopArrow";
import Skeleton from "react-loading-skeleton";

const OrderPriceQty: React.FC<{
	tradeAction: TradeActionsEnum;
	changeOrderQty: (value: string) => void;
	changeOrderPrice: (value: string) => void;
	blurOrderPrice: (value: string) => void;
	blurOrderQty: (value: string) => void;
	quoteCurrencyCode: string;
	price: string;
	baseCurrencyCode: string;
	amount: string;
	recentTradeAction: (percent: number) => void;
	bidTradeAction: () => void;
	askTradeAction: () => void;
	handleSubmit?: () => void;
	orderPriceError: string;
	qtyError: string;
	isLoaded?: boolean;
}> = ({
	tradeAction,
	quoteCurrencyCode,
	price,
	recentTradeAction,
	bidTradeAction,
	askTradeAction,
	baseCurrencyCode,
	amount,
	handleSubmit,
	changeOrderQty,
	changeOrderPrice,
	blurOrderPrice,
	blurOrderQty,
	orderPriceError,
	qtyError,
	isLoaded,
}) => {
	const { formatMessage } = useIntl();

	const onChangeOrderPrice = (ev: ChangeEvent<HTMLInputElement>) =>
		changeOrderPrice(ev.target.value);

	const onBlurOrderPrice = (ev: ChangeEvent<HTMLInputElement>) => blurOrderPrice(ev.target.value);

	const onChangeOrderQty = (ev: ChangeEvent<HTMLInputElement>) => changeOrderQty(ev.target.value);

	const onBlurOrderQty = (ev: ChangeEvent<HTMLInputElement>) => blurOrderQty(ev.target.value);

	const bidAction = (ev: React.MouseEvent<HTMLButtonElement>) => {
		ev.preventDefault();

		bidTradeAction();
	};

	const askAction = (ev: React.MouseEvent<HTMLButtonElement>) => {
		ev.preventDefault();

		askTradeAction();
	};

	const action05 = useCallback(
		(ev: React.MouseEvent<HTMLButtonElement>) => {
			ev.preventDefault();

			recentTradeAction(0.5);
		},
		[recentTradeAction],
	);

	const action1 = useCallback(
		(ev: React.MouseEvent<HTMLButtonElement>) => {
			ev.preventDefault();

			recentTradeAction(1);
		},
		[recentTradeAction],
	);

	const action5 = useCallback(
		(ev: React.MouseEvent<HTMLButtonElement>) => {
			ev.preventDefault();

			recentTradeAction(5);
		},
		[recentTradeAction],
	);

	return (
		<div className={styles.orderPriceQty}>
			{/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
			<label className={styles.orderPriceQty__price}>
				<span className={styles.orderPriceQty__labelText}>
					{formatMessage(messages.order_price)}
				</span>

				{isLoaded ? (
					<Input
						name={`${tradeAction}-price`}
						type="text"
						postfixText={quoteCurrencyCode}
						placeholder={Number(price) === 0 ? "0" : ""}
						value={Number(price) === 0 ? "" : price}
						onChange={onChangeOrderPrice}
						onBlur={onBlurOrderPrice}
						onEnter={handleSubmit}
						error={orderPriceError}
					/>
				) : (
					<Skeleton height={40} borderRadius={8} />
				)}
			</label>

			<ul className={styles.orderPriceQty__buttons}>
				<li className={styles.orderPriceQty__buttonItem}>
					<button onClick={bidAction} className={styles.orderPriceQty__button} type="button">
						BID
					</button>
				</li>

				<li className={styles.orderPriceQty__buttonItem}>
					<button onClick={askAction} className={styles.orderPriceQty__button} type="button">
						ASK
					</button>
				</li>

				<li className={styles.orderPriceQty__buttonItem}>
					<button onClick={action05} className={styles.orderPriceQty__button} type="button">
						<span>0.5%</span>{" "}
						{tradeAction === TradeActionsEnum.SELL ? <FormBottomArrow /> : <FormTopArrow />}
					</button>
				</li>

				<li className={styles.orderPriceQty__buttonItem}>
					<button onClick={action1} className={styles.orderPriceQty__button} type="button">
						<span>1%</span>{" "}
						{tradeAction === TradeActionsEnum.SELL ? <FormBottomArrow /> : <FormTopArrow />}
					</button>
				</li>

				<li className={styles.orderPriceQty__buttonItem}>
					<button onClick={action5} className={styles.orderPriceQty__button} type="button">
						<span>5%</span>{" "}
						{tradeAction === TradeActionsEnum.SELL ? <FormBottomArrow /> : <FormTopArrow />}
					</button>
				</li>
			</ul>

			{/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
			<label className={styles.orderPriceQty__qty}>
				<span className={styles.orderPriceQty__labelText}>
					{formatMessage(commonMessages.quantity)}
				</span>

				{isLoaded ? (
					<Input
						name={`${tradeAction}-amount`}
						type="text"
						postfixText={baseCurrencyCode}
						placeholder={Number(amount) === 0 ? "0" : ""}
						value={amount}
						onChange={onChangeOrderQty}
						onBlur={onBlurOrderQty}
						onEnter={handleSubmit}
						error={qtyError}
					/>
				) : (
					<Skeleton height={40} borderRadius={8} />
				)}
			</label>
		</div>
	);
};

export default observer(OrderPriceQty);

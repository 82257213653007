import React, { memo } from "react"

const Burger = () => (
	<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="none" viewBox="0 0 36 36">
		<path
			stroke="#4E55A2"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth="2"
			d="M10 18h16.5M10 12h16.5M10 24h16.5"
		/>
	</svg>
)

export default memo(Burger)

import { types as t, Instance } from "mobx-state-tree";
import { TerminalModelNamesEnum } from "types/models";
import { TradeActionsEnum } from "types/exchange";

export const Pair = t
	.model(TerminalModelNamesEnum.PAIR, {
		id: t.string,
		label: t.string,
		amount_precision: t.number,
		base_currency_name: t.string,
		quote_currency_name: t.string,
		maker_fee_rate: t.number,
		maximum_order_size: t.number,
		minimum_order_size: t.number,
		minimum_order_value: t.number,
		price_precision: t.number,
		close: t.number,
		base_currency_code: t.string,
		cross_margin_leverage: t.number,
		isolated_margin_leverage: t.number,
		quote_currency_code: t.string,
		symbol: t.string,
		taker_fee_rate: t.number,
		low: t.number,
		high: t.number,
		base_volume: t.number,
		change: t.number,
		change_percent: t.number,
	})
	.views((self) => ({
		get fullName() {
			return `${self.base_currency_name}/${self.quote_currency_name}`;
		},
		get baseCurrencyCode() {
			return self.base_currency_code || "";
		},
		get quoteCurrencyCode() {
			return self.quote_currency_code || "";
		},
	}))
	.actions((self) => ({
		getCurrencyCodeBySide(side: TradeActionsEnum, isOpposite: boolean | undefined = false) {
			if (isOpposite) {
				return side === TradeActionsEnum.BUY ? self.baseCurrencyCode : self.quoteCurrencyCode;
			}

			return side === TradeActionsEnum.BUY ? self.quoteCurrencyCode : self.baseCurrencyCode;
		},
	}));

export interface IPair extends Instance<typeof Pair> {}

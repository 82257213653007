import Accordion from "components/UI/Accordion";
import React from "react";
/* eslint-disable */
import BackgroundImg from "assets/images/home_page/background-4.png";
import styles from "styles/components/HomePage.module.scss";
import classnames from "classnames";
import message from "messages/home_page";
import { useIntl } from "react-intl";
import Button from "components/UI/Button";
import InternalLink from "components/InternalLink";
import useWindowSize from "hooks/useWindowSize";

const HomePageFaq: React.FC = () => (
	<>
		<HomePageFaqBackground />
		<div className={styles.Faq}>
			<FaqCard />
		</div>
	</>
);

export default HomePageFaq;

const HomePageFaqBackground: React.FC = () => (
	<div className={styles.Background}>
		<div className={styles.BackgroundContainer}>
			<img
				className={classnames(styles.BackgroundImage, styles.Faq)}
				alt="Mountains"
				loading="lazy"
				src={BackgroundImg}
			/>
		</div>
	</div>
);

const FaqCard: React.FC = () => {
	const { formatMessage } = useIntl();
	const { mobile } = useWindowSize();

	return (
		<div className={styles.FaqCard}>
			<div className={styles.FaqCardHeader}>
				<div className={styles.FaqCardHeaderContent}>
					<div className={styles.FaqCardTitle}>{formatMessage(message.faq_title)}</div>
					<div className={styles.FaqCardText}>{formatMessage(message.faq_content)}</div>
				</div>
				<InternalLink to="/support/request" className={styles.FaqLink}>
					<Button
						iconCode="support_new"
						label={formatMessage(message.contact_support)}
						fullWidth={mobile}
					/>
				</InternalLink>
			</div>
			<div className={styles.FaqCardContent}>
				<Accordion
					className={styles.Accordion}
					classNameRow={styles.AccordionRow}
					classNameContent={styles.AccordionContent}
					plain
					sections={[
						{
							label: formatMessage(message.faq_1_q),
							value: formatMessage(message.faq_1_a),
						},
						{
							label: formatMessage(message.faq_2_q),
							value: formatMessage(message.faq_2_a),
						},
						{
							label: formatMessage(message.faq_3_q),
							value: formatMessage(message.faq_3_a),
						},
						{
							label: formatMessage(message.faq_4_q),
							value: formatMessage(message.faq_4_a),
						},
					]}
				/>
			</div>
		</div>
	);
};

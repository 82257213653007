import React from "react";

import styles from "./index.module.scss";

export enum TooltipArrowEnum {
	TOP_LEFT = "top-left",
	TOP_RIGHT = "top-right",
	TOP_CENTER = "top-center",

	BOTTOM_LEFT = "bottom-left",
	BOTTOM_RIGHT = "bottom-right",
	BOTTOM_CENTER = "bottom-center",

	LEFT_TOP = "left-top",
	LEFT_CENTER = "left-center",
	LEFT_BOTTOM = "left-bottom",

	RIGHT_TOP = "right-top",
	RIGHT_CENTER = "right-center",
	RIGHT_BOTTOM = "right-bottom",
}

export const TooltipModal: React.FC<{
	children: React.ReactNode;
	content: React.ReactNode;
	arrowDirection: TooltipArrowEnum;
	width?: number;
}> = ({ children, arrowDirection, content, width }) => (
	<div className={styles.tooltipModal}>
		<div className={styles.tooltipModal__modalChildren}>{children}</div>

		<div
			className={`${styles.tooltipModal__modal} ${
				arrowDirection === TooltipArrowEnum.TOP_LEFT ? styles.tooltipModal__modal___topLeft : ""
			} ${
				arrowDirection === TooltipArrowEnum.TOP_RIGHT ? styles.tooltipModal__modal___topRight : ""
			} ${
				arrowDirection === TooltipArrowEnum.TOP_CENTER ? styles.tooltipModal__modal___topCenter : ""
			} ${
				arrowDirection === TooltipArrowEnum.BOTTOM_LEFT
					? styles.tooltipModal__modal___bottomLeft
					: ""
			} ${
				arrowDirection === TooltipArrowEnum.BOTTOM_RIGHT
					? styles.tooltipModal__modal___bottomRight
					: ""
			} ${
				arrowDirection === TooltipArrowEnum.BOTTOM_CENTER
					? styles.tooltipModal__modal___bottomCenter
					: ""
			} ${
				arrowDirection === TooltipArrowEnum.LEFT_TOP ? styles.tooltipModal__modal___leftTop : ""
			} ${
				arrowDirection === TooltipArrowEnum.LEFT_CENTER
					? styles.tooltipModal__modal___leftCenter
					: ""
			} ${
				arrowDirection === TooltipArrowEnum.LEFT_BOTTOM
					? styles.tooltipModal__modal___leftBottom
					: ""
			} ${
				arrowDirection === TooltipArrowEnum.RIGHT_TOP ? styles.tooltipModal__modal___rightTop : ""
			} ${
				arrowDirection === TooltipArrowEnum.RIGHT_CENTER
					? styles.tooltipModal__modal___rightCenter
					: ""
			} ${
				arrowDirection === TooltipArrowEnum.RIGHT_BOTTOM
					? styles.tooltipModal__modal___rightBottom
					: ""
			}`}
			style={width ? { width: `${width}px` } : {}}
		>
			{content}
		</div>
	</div>
);

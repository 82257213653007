import React from "react";
import { observer } from "mobx-react-lite";
import Skeleton from "react-loading-skeleton";

import styles from "components/NewUI/Terminal/FunctionalPanel/MarginSelector/index.module.scss";
import MarginSelectorArrow from "assets/icons/terminal/MarginSelectorArrow";

const MarginSelector: React.FC<{
	isLoaded?: boolean;
	value: number;
	setOpenMarginModal: (val: boolean) => void;
}> = ({ isLoaded, value, setOpenMarginModal }) => {
	const toggleOpenAction = () => setOpenMarginModal(true);

	return (
		<div className={styles.marginSelector}>
			<div className={styles.marginSelector__selectorWrapper}>
				{isLoaded ? (
					<button
						onClick={toggleOpenAction}
						type="button"
						className={styles.marginSelector__selector}
					>
						<div className={styles.marginSelector__selectorContent}>
							<p className={styles.marginSelector__selectorText}>Cross</p>

							<p className={styles.marginSelector__selectorValueText}>{value}x</p>
						</div>

						<MarginSelectorArrow />
					</button>
				) : (
					<div className={styles.marginSelector__selectorSkeleton}>
						<Skeleton height={40} borderRadius={8} />
					</div>
				)}
			</div>
		</div>
	);
};

export default observer(MarginSelector);

export const riskScoreList = {
	0: "#b3b9c2",
	1: "#72ab46",
	2: "#9ecf78",
	3: "#c0d853",
	4: "#d7ea85",
	5: "#fbbc05",
	6: "#f9c74f",
	7: "#f8961e",
	8: "#f3722c",
	9: "#f94144",
	10: "#ff2a00",
};

import { defineMessages } from "react-intl";

export default defineMessages({
	security: {
		id: "app.components.security",
		defaultMessage: "Security",
	},
	sessions: {
		id: "app.components.security.sessions",
		defaultMessage: "Sessions",
	},
	ip_white_list: {
		id: "app.components.security.ip_white_list",
		defaultMessage: "Login whitelist",
	},
	twoFA: {
		id: "app.components.security.twoFA",
		defaultMessage: "Two factor auth",
	},
	password_change: {
		id: "app.components.security.password_change",
		defaultMessage: "Password change",
	},
	date: {
		id: "app.components.security.sessions_table.date",
		defaultMessage: "Date",
	},
	ip: {
		id: "app.components.security.sessions_table.ip",
		defaultMessage: "IP address",
	},
	country: {
		id: "app.components.security.sessions_table.country",
		defaultMessage: "Country",
	},
	device: {
		id: "app.components.security.sessions_table.device",
		defaultMessage: "Device",
	},
	os: {
		id: "app.components.security.sessions_table.os",
		defaultMessage: "OS",
	},
	os_browser: {
		id: "app.components.security.sessions_table.os_browser",
		defaultMessage: "OS/Browser",
	},
	status: {
		id: "app.components.security.sessions_table.status",
		defaultMessage: "Status",
	},
	status_active: {
		id: "app.components.security.sessions_table.status_active",
		defaultMessage: "Active",
	},
	status_finished: {
		id: "app.components.security.sessions_table.status_finished",
		defaultMessage: "Finished",
	},
	pass_change: {
		id: "app.components.security.pass_change",
		defaultMessage: "Password change",
	},
	pass_change_desc: {
		id: "app.components.security.pass_change_desc",
		defaultMessage:
			"Please enter your old password, for security's sake, and then enter your new password twice so we can verify you typed it incorrectly.",
	},
	old_pass: {
		id: "app.components.security.old_pass",
		defaultMessage: "Old password",
	},
	master_pass: {
		id: "app.components.security.master_pass",
		defaultMessage: "Master-account password",
	},
	new_pass: {
		id: "app.components.security.new_pass",
		defaultMessage: "New password",
	},
	help1: {
		id: "app.components.security.help1",
		defaultMessage: "Your password can't be too similar to your other personal information.",
	},
	help2: {
		id: "app.components.security.help2",
		defaultMessage: "Your password must contain at least 8 characters.",
	},
	help3: {
		id: "app.components.security.help3",
		defaultMessage: "Your password can't be a commonly used password.",
	},
	help4: {
		id: "app.components.security.help4",
		defaultMessage: "Your password can't be entirely numeric.",
	},
	new_pass_confirm: {
		id: "app.components.security.new_pass_confirm",
		defaultMessage: "New password confirmation",
	},
	change_pass_btn: {
		id: "app.components.security.change_pass_btn",
		defaultMessage: "Change my password",
	},
	ip_white_list_state: {
		id: "app.components.security.ip_white_list_state",
		defaultMessage: "IP whitelist state: ",
	},
	ip_white_list_state_enabled: {
		id: "app.components.security.ip_white_list_state.enabled",
		defaultMessage: "Enabled",
	},
	ip_white_list_state_disabled: {
		id: "app.components.security.ip_white_list_state.disabled",
		defaultMessage: "Disabled",
	},
	ip_white_list_state_btn_disable: {
		id: "app.components.security.ip_white_list_state_btn_disable",
		defaultMessage: "Disable",
	},
	ip_white_list_state_btn_enable: {
		id: "app.components.security.ip_white_list_state_btn_enable",
		defaultMessage: "Enable",
	},
	ip_white_list_state_warn: {
		id: "app.components.security.ip_white_list_state_warn",
		defaultMessage: "Warning! ",
	},
	ip_white_list_state_warn_1: {
		id: "app.components.security.ip_white_list_state_warn_1",
		defaultMessage:
			"Please note that the settings will be applied the next time you log into your account. All of the old unsecured sessions (including the current one) will still have access to your account from any address.",
	},
	ip_white_list_state_warn_2: {
		id: "app.components.security.ip_white_list_state_warn_2",
		defaultMessage: "You need to create at least one ip to use IP white list ",
	},
	ip_white_list_btn_add: {
		id: "app.components.security.ip_white_list_btn_add",
		defaultMessage: "Add",
	},
	ip_address_label: {
		id: "app.components.security.ip_address_label",
		defaultMessage: "Ip address",
	},
	twoFA_p1: {
		id: "app.components.security.twoFA_p1",
		defaultMessage: "Tokens will be generated by your token generator.",
	},
	backup_tokens: {
		id: "app.components.security.backup_tokens",
		defaultMessage: "Backup Tokens",
	},
	twoFA_p2: {
		id: "app.components.security.twoFA_p2",
		defaultMessage:
			"If you don't have any device with you, you can access your account using backup tokens. You have 10 backup tokens remaining.",
	},
	twoFA_show_codes_btn: {
		id: "app.components.security.twoFA_show_codes_btn",
		defaultMessage: "show codes",
	},
	disable_twoFA: {
		id: "app.components.security.disable_twoFA",
		defaultMessage: "Disable Two-Factor Authentication",
	},
	disable_twoFA_desc: {
		id: "app.components.security.disable_twoFA_desc",
		defaultMessage:
			"However we strongly discourage you to do so, you can also disable two-factor authentication for your account.",
	},
	white_ip_create: {
		id: "app.components.security.white_ip_create",
		defaultMessage: "IP successfully added",
	},
	white_ip_delete: {
		id: "app.components.security.white_ip_delete",
		defaultMessage: "IP successfully deleted",
	},
	white_ip_enable: {
		id: "app.components.security.white_ip_enabled",
		defaultMessage: "White IP list enabled",
	},
	white_ip_disable: {
		id: "app.components.security.white_ip_disable",
		defaultMessage: "White IP list disabled",
	},
	password_changed_successfully: {
		id: "app.components.security.password_changed_successfully",
		defaultMessage: "Password changed",
	},
	disable_twoFA_h: {
		id: "app.components.security.disable_twoFA_h",
		defaultMessage: "Disable Two-factor Authentication",
	},
	disable_twoFA_p1: {
		id: "app.components.security.disable_twoFA_p1",
		defaultMessage:
			"You are about to disable two-factor authentication. This compromises your account security, are you sure?",
	},
	disable_twoFA_p2: {
		id: "app.components.security.disable_twoFA_p2",
		defaultMessage: "Please enter your token, which had been generated by Authenticator app",
	},
	disable_twoFA_checkbox_label: {
		id: "app.components.security.disable_twoFA_chackbox_label",
		defaultMessage: "Yes, I am sure and understand",
	},
	disable_twoFA_token: {
		id: "app.components.security.disable_twoFA_token",
		defaultMessage: "Token",
	},
	twoFA_disabled: {
		id: "app.components.security.twoFA_disabled",
		defaultMessage: "Two-factor Authentication was disabled",
	},
	twoFA_enabled: {
		id: "app.components.security.twoFA_enabled",
		defaultMessage: "Two-factor Authentication was enabled",
	},
	twoFA_disabled_desc: {
		id: "app.components.security.twoFA_disabled_desc",
		defaultMessage:
			"Two-factor authentication is not enabled for your account. Enable two-factor authentication for enhanced account security.",
	},
	twoFA_enable_btn: {
		id: "app.components.security.twoFA_enable_btn",
		defaultMessage: "Enable Two-factor Authentication",
	},
	twoFA_enable_p1: {
		id: "app.components.security.twoFA_enable_p1",
		defaultMessage:
			"To start using a token generator, please use your smartphone to scan the QR code below. For example, use Google Authenticator. Then, enter the token generated by the app.",
	},
	twoFA_enable_p2: {
		id: "app.components.security.twoFA_enable_p2",
		defaultMessage:
			"TIf you cannot scan QR-code you may use following core entering it to application manually. Do not expose it to third persons - it may cause privacy problems.",
	},
	twoFA_enable_p3: {
		id: "app.components.security.twoFA_enable_p3",
		defaultMessage:
			"If everything entered right application generates new code every 30 seconds, enter it below to confirm 2FA activation. Note, if time in your smartphone not accurate code will be wrong. We'd recommend use auto time settings.",
	},
	twoFA_enable_next_btn: {
		id: "app.components.security.twoFA_enable_next_btn",
		defaultMessage: "Next",
	},
	twoFA_disabled_page_header: {
		id: "app.components.security.twoFA_disabled_page_header",
		defaultMessage: "Two-factor Authentication successful disabled",
	},
	twoFA_disabled_page_desc: {
		id: "app.components.security.twoFA_disabled_page_desc",
		defaultMessage: "Now you account less secure than before!",
	},
	twoFA_enabled_page_header: {
		id: "app.components.security.twoFA_enabled_page_header",
		defaultMessage: "Two-factor Authentication successful enabled",
	},
	twoFA_enabled_page_desc: {
		id: "app.components.security.twoFA_enabled_page_desc",
		defaultMessage: "Now you account more secure than before!",
	},
	back_up_tokens: {
		id: "app.components.security.back_up_tokens",
		defaultMessage: "Backup tokens",
	},
	back_up_tokens_desc: {
		id: "app.components.security.back_up_tokens_desc",
		defaultMessage:
			"Backup tokens can be used when main and backup phones are not available. Tokens below can be used for authorization check.",
	},
	generate_backup_tokens: {
		id: "app.components.security.generate_backup_tokens",
		defaultMessage: "Generate tokens",
	},
	save_back_up_tokens: {
		id: "app.components.security.save_back_up_tokens",
		defaultMessage: "Print these codes and save them in a safe place.",
	},
	enabled: {
		id: "app.components.security.enabled",
		defaultMessage: "Enabled",
	},
	white_list_description: {
		id: "app.components.security.white_list_description",
		defaultMessage: "You can limit login into your account from only IP addresses from this list",
	},
	disabled: {
		id: "app.components.security.disabled",
		defaultMessage: "Disabled",
	},
	ip_white_list_duplicate_error: {
		id: "app.components.security.ip_white_list_duplicate_error",
		defaultMessage: "IP already exist",
	},
	show_active_sessions: {
		id: "app.components.security.show_active_sessions",
		defaultMessage: "Show active",
	},
	show_all_sessions: {
		id: "app.components.security.show_all_sessions",
		defaultMessage: "Show all",
	},
	close_all_active_sessions: {
		id: "app.components.security.close_all_active_sessions",
		defaultMessage: "Close other active sessions",
	},
	close_session: {
		id: "app.components.security.close",
		defaultMessage: "Close",
	},
	session_closed: {
		id: "app.components.security.session_closed",
		defaultMessage: "Session closed",
	},
	add_ip_to_white_list: {
		id: "app.components.security.add_ip_to_white_list",
		defaultMessage: "Press Enter to add ip to white list",
	},
	disabling_2fa_will_only_be_possible_for_verified_users: {
		id: "app.components.security.disabling_2fa_will_only_be_possible_for_verified_users",
		defaultMessage: "Please note that disabling 2FA will only be possible for verified users.",
	},
	wallets_white_list: {
		id: "app.components.security.wallets_whitelist",
		defaultMessage: "Wallets whitelist",
	},
	social_networks: {
		id: "app.components.security.social_networks",
		defaultMessage: "Social networks",
	},
	social_networks_settings: {
		id: "app.components.security.social_networks_settings",
		defaultMessage: "Social networks settings",
	},
	sign_in: {
		id: "app.components.security.sign_in",
		defaultMessage: "Sign in",
	},
	email_address: {
		id: "app.components.security.email_address",
		defaultMessage: "E-mail address",
	},
	this_name_is_displayed_on_the_public_pages_and_chat: {
		id: "app.components.security.this_name_is_displayed_on_the_public_pages_and_chat",
		defaultMessage: "This name is displayed on the public pages",
	},
	account_security: {
		id: "app.components.security.account_security",
		defaultMessage: "Account Security",
	},
	unavailable_for_your_account: {
		id: "app.components.security.unavailable_for_your_account",
		defaultMessage: "Unavailable for your account",
	},
	the_feature_will_be_available_soon: {
		id: "app.components.security.the_feature_will_be_available_soon",
		defaultMessage: "The feature will be available soon",
	},
	use_google_authenticator: {
		id: "app.components.security.use_google_authenticator",
		defaultMessage: "Use Google Authenticator",
	},
	use_alp_authenticator: {
		id: "app.components.security.use_alp_authenticator",
		defaultMessage: "Use ALP Authenticator",
	},
	download_and_install_the_app: {
		id: "app.components.security.download_and_install_the_app",
		defaultMessage: "Download and install the app",
	},
	scan_the_qr_code: {
		id: "app.components.security.scan_the_qr_code",
		defaultMessage: "Scan the QR Code",
	},
	confirm_the_code: {
		id: "app.components.security.confirm_the_code",
		defaultMessage: "Confirm the code",
	},
	your_account_is_now_securely_protected: {
		id: "app.components.security.your_account_is_now_securely_protected",
		defaultMessage: "Congratulations, your account is now securely protected",
	},
	here_you_can_customize_your_login_settings: {
		id: "app.components.security.here_you_can_customize_your_login_settings",
		defaultMessage:
			"Here you can customize your Login settings and significantly increase the security level of your account",
	},
	restricted_access_to_only_trusted_addresses: {
		id: "app.components.security.restricted_access_to_only_trusted_addresses",
		defaultMessage: "Restricted access to only trusted IP addresses",
	},
	ip_address_or_ip_segment: {
		id: "app.components.security.ip_address_or_ip_segment",
		defaultMessage: "IP address or IP segment",
	},
	ip_on_this_account: {
		id: "app.components.security.ip_on_this_account",
		defaultMessage: "IP on this account",
	},
	add_ip: {
		id: "app.components.security.add_ip",
		defaultMessage: "Add IP",
	},
	email_hint: {
		id: "app.components.security.email_hint",
		defaultMessage: "To change email, you need to contact Support center",
	},
});

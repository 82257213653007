import React, { memo } from "react";

const Notifications = () => (
	<svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M7.171 16.948a.833.833 0 011.176-.073c.441.39 1.019.625 1.654.625.634 0 1.212-.235 1.653-.625a.833.833 0 011.103 1.25A4.154 4.154 0 0110 19.167a4.154 4.154 0 01-2.757-1.042.833.833 0 01-.073-1.177zM5.876 2.542a5.833 5.833 0 019.958 4.125c0 2.427.61 4.036 1.25 5.064l.01.016c.297.478.532.857.691 1.132.08.138.152.27.206.389.026.059.056.131.08.21.02.065.056.204.042.374-.009.114-.032.309-.144.51a1.172 1.172 0 01-.885.594h-.003a3.833 3.833 0 01-.403.033c-.28.01-.666.01-1.148.01H4.47c-.48 0-.867 0-1.146-.01a3.835 3.835 0 01-.403-.033h-.004a1.172 1.172 0 01-1.03-1.104 1.02 1.02 0 01.043-.374c.024-.079.054-.151.08-.21.054-.118.127-.251.206-.39.159-.274.394-.653.692-1.131l.01-.016c.64-1.028 1.25-2.637 1.25-5.064 0-1.548.614-3.031 1.708-4.125z"
			fill="#fff"
		/>
	</svg>
);

export default memo(Notifications);

import React, { useEffect, useMemo, useRef } from "react"
import { observer } from "mobx-react-lite"
import Skeleton from "react-loading-skeleton"

import { useMst } from "models/Root"
import styles from "../index.module.scss"
import Item from "./Item"

const List: React.FC<{
	isSell?: boolean
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	list: any[]
	decimalPlaces: number
	currentListType: string
}> = ({ isSell = false, list = [], decimalPlaces, currentListType }) => {
	const listRef = useRef<HTMLDivElement>(null)
	const {
		terminal: { pair, tradeForm, isLoaded },
	} = useMst()

	const maxDepth = useMemo(
		() => (list.length > 7 ? list[7]?.orderDepth : list[list.length - 1]?.orderDepth),
		[list],
	)

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const handleOrderClick = (item: any) => {
		if (item) {
			tradeForm.setClickedOrder(item)
		}
	}

	useEffect(() => {
		if (listRef.current) {
			listRef.current.scrollTop = 0
		}
	}, [currentListType])

	return (
		<div
			ref={listRef}
			className={`${styles.orders__listWrapper} ${isSell ? styles.orders__listWrapper___red : ""} ${
				list.length > 0 ? "" : styles.orders__listWrapper___loader
			}`}
		>
			{!isLoaded ? (
				<>
					<div className={styles.orders__skiletonWrapper}>
						<Skeleton width={300} height={24} borderRadius={0} />
					</div>
					<div className={styles.orders__skiletonWrapper}>
						<Skeleton width={300} height={24} borderRadius={0} />
					</div>
					<div className={styles.orders__skiletonWrapper}>
						<Skeleton width={300} height={24} borderRadius={0} />
					</div>
					<div className={styles.orders__skiletonWrapper}>
						<Skeleton width={300} height={24} borderRadius={0} />
					</div>
					<div className={styles.orders__skiletonWrapper}>
						<Skeleton width={300} height={24} borderRadius={0} />
					</div>
					<div className={styles.orders__skiletonWrapper}>
						<Skeleton width={300} height={24} borderRadius={0} />
					</div>
					<div className={styles.orders__skiletonWrapper}>
						<Skeleton width={300} height={24} borderRadius={0} />
					</div>
					<div className={styles.orders__skiletonWrapper}>
						<Skeleton width={300} height={24} borderRadius={0} />
					</div>
				</>
			) : list.length > 0 ? (
				<ul className={`${styles.orders__list} ${isSell ? styles.orders__list___red : ""}`}>
					{list
						.map((item, index: number) => (
							<Item
								key={`${index}-item`}
								item={item}
								maxDepth={maxDepth}
								amount_precision={pair?.amount_precision ?? 6}
								decimalPlaces={decimalPlaces}
								isSell={isSell}
								quote_currency_code={pair?.quote_currency_code}
								base_currency_code={pair?.base_currency_code}
								handleOrderClick={handleOrderClick}
							/>
						))
						.reverse()}
				</ul>
			) : null}
		</div>
	)
}

export default observer(List)

import React, { FC } from "react";

import styles from "components/NewUI/Header/Navigation/index.module.scss";
import useLanguages from "hooks/newUI/header/useLanguages";
import Item from "./Item";

const Languages: FC = () => {
	const { appLocales, handleLocaleChange, globalLocale } = useLanguages();

	return (
		<ul className={`${styles.nav__firstList} ${styles.nav__firstList___lang}`}>
			{appLocales.map((localeObj) => (
				<Item
					key={localeObj.name}
					isChecked={localeObj.value === globalLocale}
					handleChange={handleLocaleChange}
					value={localeObj.value}
					label={localeObj.name}
				/>
			))}
		</ul>
	);
};

export default Languages;

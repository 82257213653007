import React, { useCallback } from "react";

import styles from "components/NewUI/Header/Navigation/index.module.scss";

const Tab: React.FC<{
	quotedCurrency: string;
	name: string;
	label: string;
	setQuotedCurrency: (name: string) => void;
	isMargin: boolean;
}> = ({ quotedCurrency, name, label, setQuotedCurrency, isMargin }) => {
	const onChange = useCallback(() => setQuotedCurrency(name), [name]);

	return (
		<li>
			<input
				className={`visually-hidden ${styles.nav__tradingListTabRadio}`}
				type="radio"
				name={`HeaderTab${isMargin ? "Margin" : ""}`}
				id={`${label}HeaderTab${isMargin ? "Margin" : ""}`}
				value={`${label}HeaderTab${isMargin ? "Margin" : ""}`}
				onChange={onChange}
				checked={quotedCurrency === name}
			/>

			{/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
			<label
				className={styles.nav__tradingListTabButton}
				htmlFor={`${label}HeaderTab${isMargin ? "Margin" : ""}`}
			>
				<span>{label}</span>
			</label>
		</li>
	);
};

export default Tab;

import { defineMessages } from "react-intl";

export default defineMessages({
	uah_givaway: {
		id: "app.uah_giveaway.uah_givaway",
		defaultMessage: "UAH Giveaway",
	},
	page_description: {
		id: "app.uah_giveaway.page_description",
		defaultMessage: "Distribution of UAH - 1 000 000 UAH Giveaway. Promotion for new users ALP.COM",
	},
	dobryy_den: {
		id: "app.uah_giveaway.dobryy_den",
		defaultMessage: "Dobryy den`, everybody",
	},
	we_are_giving_away: {
		id: "app.uah_giveaway.we_are_giving_away",
		defaultMessage: "We are giving away {value}",
	},
	in_honour_of_launch: {
		id: "app.uah_giveaway.in_honour_of_launch",
		defaultMessage: "In honor of ALP.COM launch",
	},
	we_have_a_present_for_you: {
		id: "app.uah_giveaway.we_have_a_present_for_you",
		defaultMessage:
			"Welcome, new user, we have a present for you! Get UAH to your ALP.COM account and donate it to UAF heroes with us!",
	},
	donation_history: {
		id: "app.uah_giveaway.donation_history",
		defaultMessage: "Donation history",
	},
	top_donations: {
		id: "app.uah_giveaway.top_donations",
		defaultMessage: "TOP Donations",
	},
	donations_table_date: {
		id: "app.uah_giveaway.donations_table_date",
		defaultMessage: "Date",
	},
	donations_table_uid: {
		id: "app.uah_giveaway.donations_table_uid",
		defaultMessage: "UID",
	},
	donations_table_comment: {
		id: "app.uah_giveaway.donations_table_comment",
		defaultMessage: "Comment",
	},
	donations_table_uah_amount: {
		id: "app.uah_giveaway.donations_table_uah_amount",
		defaultMessage: "UAH Amount",
	},
	donations_count: {
		id: "app.uah_giveaway.donations_count",
		defaultMessage: "More than {count} people donated for the UAF",
	},
	we_have_already_given_away: {
		id: "app.uah_giveaway.we_have_already_given_away",
		defaultMessage: "We have already given away more than {value} to our users",
	},
	your_current_balance: {
		id: "app.uah_giveaway.your_current_balance",
		defaultMessage: "Your current balance: {value}",
	},
	bonus_uah_amount: {
		id: "app.uah_giveaway.bonus_uah_amount",
		defaultMessage: "Bonus UAH amount: {value}",
	},
	get_bonus: {
		id: "app.uah_giveaway.get_bonus",
		defaultMessage: "Get BONUS",
	},
	donate_to_uaf: {
		id: "app.uah_giveaway.donate_to_uaf",
		defaultMessage: "Donate to UAF",
	},
	share_with_friends: {
		id: "app.uah_giveaway.share_with_friends",
		defaultMessage: "{share} with friends and get more UAH!",
	},
	share_with_friends_share: {
		id: "app.uah_giveaway.share_with_friends_share",
		defaultMessage: "Share",
	},
	share_reward: {
		id: "app.uah_giveaway.share_reward",
		defaultMessage:
			"We reward you with 25 UAH for each friend, who registers using your referral link and completes KYC.",
	},
	get_uahs_on_your_account: {
		id: "app.uah_giveaway.get_extra_uah_for_account",
		defaultMessage: "Get UAHs on your {department} account or donate to Ukrainian Armed Forces",
	},
	get_extra_uah_for_account: {
		id: "app.uah_giveaway.get_extra_uah_for_account",
		defaultMessage: "Get extra UAH for account",
	},
	invite_a_friend: {
		id: "app.uah_giveaway.invite_a_friend",
		defaultMessage: "Invite a friend",
	},
	referral_link: {
		id: "app.uah_giveaway.referral_link",
		defaultMessage: "Referral Link",
	},
	lets_help_together: {
		id: "app.uah_giveaway.lets_help_together",
		defaultMessage: "Let’s help the UAF together!",
	},
	you_can_also_donate_a_part_of_your_uah: {
		id: "app.uah_giveaway.you_can_also_donate_a_part_of_your_uah",
		defaultMessage:
			"You can also donate a part of your UAH to help the UAF to make our victory closer!",
	},
	our_users_donate: {
		id: "app.uah_giveaway.our_users_donate",
		defaultMessage: "Our users donated",
	},
	donations_for_uaf: {
		id: "app.uah_giveaway.donations_for_uaf",
		defaultMessage: "{value} donations for UAF",
	},
	giveaway_rules: {
		id: "app.uah_giveaway.giveaway_rules",
		defaultMessage: "UAH Giveaway rules by {department}",
	},
	rules_1: {
		id: "app.uah_giveaway.rules_1",
		defaultMessage:
			"Each Ukrainian user who managed to register and confirmed his identity receives 150 UAH to his account. To do this, just set the 'Take UAH' parameter.",
	},
	rules_2: {
		id: "app.uah_giveaway.rules_2",
		defaultMessage:
			"Giveaway participants must have Ukrainian citizenship and confirm it during the KYC process.",
	},
	rules_3: {
		id: "app.uah_giveaway.rules_3",
		defaultMessage:
			"The promotion is not limited in time and will be active until we distribute {value}.",
	},
	rules_4: {
		id: "app.uah_giveaway.rules_4",
		defaultMessage:
			"It is possible to receive an {additional_uah} UAH for each invited referral if he confirms Ukrainian citizenship in the KYC process.",
	},
	rules_4_additional_uah: {
		id: "app.uah_giveaway.rules_4_additional_uah",
		defaultMessage: "additional 25",
	},
	rules_5: {
		id: "app.uah_giveaway.rules_5",
		defaultMessage: "The user has the right to dispose of {bonus_uah} at his own discretion.",
	},
	rules_5_bonus_uah: {
		id: "app.uah_giveaway.rules_5_bonus_uah",
		defaultMessage: "bonus UAH",
	},
	rules_6: {
		id: "app.uah_giveaway.rules_6",
		defaultMessage: "Be subscribed to our Telegram channel",
	},
	get_uahs: {
		id: "app.uah_giveaway.get_uahs",
		defaultMessage: "Get UAHs",
	},
	on_your_account: {
		id: "app.uah_giveaway.on_your_account",
		defaultMessage: "on your ALP.COM account or donate to Ukrainian Armed Forces",
	},
	funds_credited: {
		id: "app.uah_giveaway.funds_credited",
		defaultMessage: "Funds credited",
	},
	personal_uah_balance: {
		id: "app.uah_giveaway.personal_uah_balance",
		defaultMessage: "Personal UAH balance",
	},
	minimal_donation_amount: {
		id: "app.uah_giveaway.minimal_donation_amount",
		defaultMessage: "Minimal donation amount",
	},
	donate_to_ukrainian_armed_forces: {
		id: "app.uah_giveaway.donate_to_ukrainian_armed_forces",
		defaultMessage: "Donate to Ukrainian Armed Forces",
	},
	remind_me_later: {
		id: "app.uah_giveaway.remind_me_later",
		defaultMessage: "Remind me later",
	},
	verification_progress_title: {
		id: "app.uah_giveaway.verification_progress_title",
		defaultMessage: "You are at the verification stage",
	},
	verification_progress_desc: {
		id: "app.uah_giveaway.verification_progress_desc",
		defaultMessage:
			"At the moment, you are still undergoing verification and will be able to receive funds after successful completion.",
	},
	you_are_not_verified: {
		id: "app.uah_giveaway.you_are_not_verified",
		defaultMessage: "You are not verified",
	},
	to_receive_a_bonus_you_need_to_pass_verification: {
		id: "app.uah_giveaway.to_receive_a_bonus_you_need_to_pass_verification",
		defaultMessage:
			"To receive a bonus, you need to pass the first level of verification. It won't take much time and will allow you to use the exchange safely :)",
	},
	withdraw_funds_on_balance: {
		id: "app.uah_giveaway.withdraw_funds_on_balance",
		defaultMessage: "Withdraw the funds on {department} balance",
	},
	go_to_trade: {
		id: "app.uah_giveaway.go_to_trade",
		defaultMessage: "Go to trade",
	},
	view_balance: {
		id: "app.uah_giveaway.view_balance",
		defaultMessage: "View balance",
	},
	funds_received: {
		id: "app.uah_giveaway.funds_received",
		defaultMessage: "Funds received",
	},
	funds_received_desc: {
		id: "app.uah_giveaway.funds_received_desc",
		defaultMessage:
			"You have successfully received bonus {currency} into your account. To view your balance, go to your wallet.",
	},
	you_are_not_yet_authorized: {
		id: "app.uah_giveaway.you_are_not_yet_authorized",
		defaultMessage: "You are not yet authorized",
	},
	you_are_not_yet_authorized_desc: {
		id: "app.uah_giveaway.you_are_not_yet_authorized_desc",
		defaultMessage:
			"To continue working with our exchange and receive a bonus, please register or enter your personal account and 1 level of verification for comfortable work.",
	},
	giveaway_is_over: {
		id: "app.uah_giveaway.giveaway_is_over",
		defaultMessage:
			"The Giveaway is over, all funds were collected and sent to the {united_24} fund",
	},
});

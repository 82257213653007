import React from "react"
import { observer } from "mobx-react-lite"

import styles from "components/NewUI/Terminal/Bottom/LimitMarketStopButtons/index.module.scss"
import { useIntl } from "react-intl"
import commonMessages from "../../../../../messages/common"

const LimitMarketStopButtons: React.FC<{
	id: string
	isAuthenticated: boolean
	setFilterType: (filterType: string) => void
	filterType: string
}> = ({ id, isAuthenticated, filterType, setFilterType }) => {
	const { formatMessage } = useIntl()

	const setLimitMarket = () => setFilterType("LIMIT_MARKET")

	const setStopLimitMarket = () => setFilterType("STOP_LIMIT")

	return (
		<div className={styles.buttons}>
			<label className={styles.buttons__label} htmlFor={`${id}LimitMarketStopButtons`}>
				<input
					className={`visually-hidden ${styles.buttons__radio}`}
					type="radio"
					id={`${id}LimitMarketStopButtons`}
					value="LIMIT_MARKET"
					name={`${id}LimitMarketStopButtons`}
					disabled={!isAuthenticated}
					onChange={setLimitMarket}
					checked={filterType === "LIMIT_MARKET"}
				/>

				<span className={styles.buttons__labelText}>
					{formatMessage(commonMessages.limitMarketOrders)}
				</span>
			</label>

			<label className={styles.buttons__label} htmlFor={`${id}StopLimit`}>
				<input
					className={`visually-hidden ${styles.buttons__radio}`}
					type="radio"
					id={`${id}StopLimit`}
					value="STOP_LIMIT"
					disabled={!isAuthenticated}
					name={`${id}LimitMarketStopButtons`}
					onChange={setStopLimitMarket}
					checked={filterType === "STOP_LIMIT"}
				/>

				<span className={styles.buttons__labelText}>
					{formatMessage(commonMessages.stopLimitOrders)}
				</span>
			</label>
		</div>
	)
}

export default observer(LimitMarketStopButtons)

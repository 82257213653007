import React, { memo, FC } from "react";

const Star: FC<{ isChecked?: boolean }> = ({ isChecked = false }) => (
	<svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M8.43 1.184a1 1 0 00-.86 0 1.04 1.04 0 00-.43.414c-.068.11-.138.25-.205.388L5.497 4.898l-3.214.47a5.167 5.167 0 00-.433.075 1.04 1.04 0 00-.526.281 1 1 0 00-.266.82c.035.258.18.441.261.536.084.099.197.208.306.315L3.951 9.66l-.549 3.198c-.026.151-.052.306-.063.435-.01.125-.02.36.105.588a1 1 0 00.697.506c.256.048.476-.034.591-.082.12-.05.26-.123.395-.194L8 12.6l2.874 1.511c.136.071.274.145.394.194.116.048.335.13.592.082a1 1 0 00.696-.506 1.04 1.04 0 00.105-.588 5.197 5.197 0 00-.062-.434l-.549-3.2 2.325-2.264c.11-.107.222-.216.306-.315a1.04 1.04 0 00.261-.537 1 1 0 00-.265-.819 1.04 1.04 0 00-.527-.281 5.165 5.165 0 00-.432-.075l-3.215-.47-1.437-2.912c-.068-.137-.137-.278-.205-.388a1.04 1.04 0 00-.43-.414z"
			fill={isChecked ? "#FFD60A" : "#2C2C2E"}
		/>
	</svg>
);

export default memo(Star);

import React from "react"
import { observer } from "mobx-react-lite"
import { useIntl } from "react-intl"

import styles from "components/NewUI/Terminal/FunctionalPanel/Balances/index.module.scss"
import exchangeMessage from "messages/exchange"
import { useMst } from "models/Root"

const Balances: React.FC = () => {
	const { formatMessage, formatNumber } = useIntl()
	const {
		terminal: { quoteCurrencyCode, baseCurrencyCode },
		account: { quoteCurrencyBalance, baseCurrencyBalance },
	} = useMst()

	return (
		<div className={styles.balances}>
			<div className={styles.balances__row}>
				<p className={styles.balances__text}>
					{quoteCurrencyCode} {formatMessage(exchangeMessage.balance)}
				</p>

				<span className={styles.balances__amount}>
					<span>
						{quoteCurrencyBalance
							? formatNumber(quoteCurrencyBalance.available, {
									useGrouping: true,
									minimumFractionDigits: quoteCurrencyBalance.precision ?? 2,
									maximumFractionDigits: quoteCurrencyBalance.precision ?? 8,
							  })
							: "--"}
					</span>

					<span className={styles.balances__currency}>{quoteCurrencyCode}</span>
				</span>
			</div>

			<div className={styles.balances__row}>
				<p className={styles.balances__text}>
					{baseCurrencyCode} {formatMessage(exchangeMessage.balance)}
				</p>

				<span className={styles.balances__amount}>
					<span>
						{baseCurrencyBalance
							? formatNumber(baseCurrencyBalance.available, {
									useGrouping: true,
									minimumFractionDigits: baseCurrencyBalance.precision ?? 2,
									maximumFractionDigits: baseCurrencyBalance.precision ?? 8,
							  })
							: "--"}
					</span>

					<span className={styles.balances__currency}>{baseCurrencyCode}</span>
				</span>
			</div>
		</div>
	)
}

export default observer(Balances)

import React, { useState } from "react"
import { observer } from "mobx-react-lite"
import { useIntl } from "react-intl"
import dayjs from "dayjs"

import { queryVars } from "constants/query"
import messages from "messages/common"
import AppleBtnImg from "assets/images/common/app-store.svg"
import GoogleBtnImg from "assets/images/common/google-play.svg"
import newsMessages from "messages/news"
import socialListingMessages from "messages/listing"
import feesMessages from "messages/fees_trading"
import styles from "./index.module.scss"
import Burger from "../../../../assets/icons/header/Burger"
import MobileProfile from "../../../../assets/icons/header/MobileProfile"
import { routes, URL_VARS } from "../../../../constants/routing"
import InternalLink from "../../../InternalLink"
import CrossBurger from "../../../../assets/icons/header/CrossBurger"
import { useMst } from "../../../../models/Root"
import useLocalStorage from "../../../../hooks/useLocalStorage"
import { TERMINAL_LATEST_PAIR_CACHE_KEY } from "../../../../utils/cacheKeys"
import config from "../../../../helpers/config"
import useOverflowHidden from "../../../../hooks/newUI/useOverflowHidden"
import Logo from "../../../../assets/icons/Logo"
import WalletValue from "../Navigation/Wallet/WalletValue"
import { socialNetworks } from "../../../../constants/socialNetworks"
import NavArrowRight from "../../../../assets/icons/header/NavArrowRight"
import User from "../Navigation/User"

const MobileBurger = () => {
	const {
		global: { isAuthenticated, locale },
		account: { profileStatus },
		render,
	} = useMst()
	const currentYear = dayjs().year()
	const [isOpenBurger, setIsOpenBurger] = useState(false)
	const [isOpenProfile, setIsOpenProfile] = useState(false)
	useOverflowHidden(isOpenBurger)
	useOverflowHidden(isOpenProfile)
	const { formatMessage } = useIntl()
	const [latestTerminalPair] = useLocalStorage(
		TERMINAL_LATEST_PAIR_CACHE_KEY,
		config.defaultTerminalPair,
	)

	const onChangeHeaderBurgerCheckbox = (ev: React.ChangeEvent<HTMLInputElement>) =>
		setIsOpenBurger(ev.target.checked)

	const onChangeHeaderProfileCheckbox = (ev: React.ChangeEvent<HTMLInputElement>) =>
		setIsOpenProfile(ev.target.checked)

	const mainMenuItems = [
		{
			link: routes.trade.getPair(latestTerminalPair),
			label: formatMessage(messages.exchange),
			icon: <i className="ai ai-exchange" />,
		},
		{
			link: `/${URL_VARS.TRADE}/${config.defaultTerminalMarginPair}?${queryVars.layout}=advanced&${queryVars.type}=cross`,
			label: "Cross Margin",
			icon: <i className="ai ai-exchange" />,
			forbid: !render.margin,
		},
		// {
		// 	link: `/${URL_VARS.TRADE}/${config.defaultTerminalMarginPair}?${queryVars.layout}=advanced&${queryVars.type}=isolated`,
		// 	label: "Isolated Margin",
		// 	icon: <i className="ai ai-exchange" />,
		// 	forbid: !render.margin,
		// },
		{
			link: routes.aml,
			label: formatMessage(messages.aml),
			icon: <i className="ai ai-file_text" />,
			forbid: !render.transactionsMonitoring,
		},
		{
			link: routes.staking.root,
			label: formatMessage(messages.staking),
			icon: <i className="ai ai-layers" />,
			forbid: !render.staking,
		},
		{
			link: routes.competitions.root,
			label: formatMessage(messages.competitions),
			icon: <i className="ai ai-cup" />,
			forbid: !render.competitions,
		},
		{
			link: "/halving",
			label: formatMessage(messages.halving_header),
			icon: <i className="ai ai-btc" />,
		},
		{
			link: URL_VARS.ROOT,
			label: formatMessage(messages.home),
			icon: <i className="ai ai-home" />,
		},
		{
			link: routes.buyCrypto.getPair("USD_BTC"),
			label: formatMessage(messages.buy_crypto),
			icon: <i className="ai ai-btc" />,
			forbid: !render.buyCrypto || (isAuthenticated && profileStatus?.is_sub_account),
		},
		{
			link: routes.tradingFees,
			label: formatMessage(feesMessages.trading_fees),
			icon: <i className="ai ai-fees" />,
		},
		// {
		// 	link: "/alp-coin",
		// 	label: "ALP Coin",
		// 	icon: <i className="ai ai-alp" />,
		// },
		{
			link: routes.news.root,
			label: formatMessage(newsMessages.news),
			icon: <i className="ai ai-news" />,
			forbid: !render.news,
		},
		{
			link: routes.support.root,
			label: formatMessage(messages.support),
			icon: <i className="ai ai-support" />,
			forbid: !render.supportCenter,
		},
		{
			link: routes.socialListing.root,
			label: formatMessage(socialListingMessages.social_listing_header),
			icon: <i className="ai ai-alc" />,
			forbid: !render.socialListing,
		},
		{
			link: routes.listing.root,
			label: formatMessage(messages.listing),
			icon: <i className="ai ai-listing" />,
			forbid: !render.listing,
		},
	]

	return (
		<div className={styles.mobileBurger}>
			{isAuthenticated ? (
				<>
					<InternalLink
						className={styles.mobileBurger__depositButton}
						to={routes.profile.createDeposit}
					>
						Deposit
					</InternalLink>

					<div className={styles.mobileBurger__profile}>
						<input
							id="headerProfile"
							className={styles.mobileBurger__profileCheckbox}
							type="checkbox"
							onChange={onChangeHeaderProfileCheckbox}
						/>

						{/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
						<label htmlFor="headerProfile" className={styles.mobileBurger__profileButton}>
							<MobileProfile />
						</label>

						<div className={styles.mobileBurger__profileContent}>
							{/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
							<label htmlFor="headerProfile" className={styles.mobileBurger__closeArea} />

							<div className={styles.mobileBurger__burgerContentWrapper}>
								<div className={styles.mobileBurger__header}>
									<div className={styles.mobileBurger__headerLogo}>
										<Logo />
									</div>

									{/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
									<label htmlFor="headerProfile" className={styles.mobileBurger__burgerButton}>
										<CrossBurger />
									</label>
								</div>

								<div className={styles.mobileBurger__profileContainer}>
									<User isMobile />
								</div>
							</div>
						</div>
					</div>
				</>
			) : (
				<InternalLink className={styles.mobileBurger__depositButton} to={routes.login.root}>
					Sign in
				</InternalLink>
			)}

			<input
				id="headerBurger"
				className={styles.mobileBurger__burgerCheckbox}
				type="checkbox"
				onChange={onChangeHeaderBurgerCheckbox}
			/>

			{/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
			<label htmlFor="headerBurger" className={styles.mobileBurger__burgerButton}>
				<Burger />
			</label>

			<div className={styles.mobileBurger__burgerContent}>
				{/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
				<label htmlFor="headerBurger" className={styles.mobileBurger__closeArea} />

				<div className={styles.mobileBurger__burgerContentWrapper}>
					<div className={styles.mobileBurger__header}>
						<div className={styles.mobileBurger__headerLogo}>
							<Logo />
						</div>

						{/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
						<label htmlFor="headerBurger" className={styles.mobileBurger__burgerButton}>
							<CrossBurger />
						</label>
					</div>

					<div className={styles.mobileBurger__content}>
						{isAuthenticated ? (
							<WalletValue />
						) : (
							<div className={styles.mobileBurger__auth}>
								<InternalLink className={styles.mobileBurger__signInButton} to={routes.login.root}>
									Sign in
								</InternalLink>

								<InternalLink
									className={styles.mobileBurger__signUpButton}
									to={routes.register.root}
								>
									Sign up
								</InternalLink>
							</div>
						)}

						<div
							className={`${styles.mobileBurger__menuList} ${
								isAuthenticated ? styles.mobileBurger__menuList___authenticated : null
							}`}
						>
							{mainMenuItems.map(item =>
								item.forbid ? null : (
									<InternalLink
										className={styles.mobileBurger__menuItem}
										key={item.label}
										to={item.link}
									>
										<span>
											<span className={styles.mobileBurger__menuIcon}>{item.icon}</span>

											<span className={styles.mobileBurger__menuName}>{item.label}</span>
										</span>

										<NavArrowRight />
									</InternalLink>
								),
							)}

							<div className={styles.mobileBurger__socials}>
								{socialNetworks(locale).map(social => (
									<a
										key={social.label}
										href={social.link}
										target="_blank"
										rel="noopener noreferrer"
										aria-label={`${social.label} ${config.department}`}
									>
										<i className={`ai ai-${social.icon}`} />
									</a>
								))}
							</div>

							{render.mobileApp ? (
								<div className={styles.mobileBurger__apps}>
									<a className={styles.mobileBurger__appLink} href={config.mobileAppAppStore}>
										<img src={AppleBtnImg} alt="logo" width="140" height="50" />
									</a>

									<a className={styles.mobileBurger__appLink} href={config.mobileAppGooglePlay}>
										<img src={GoogleBtnImg} alt="logo" width="140" height="50" />
									</a>
								</div>
							) : null}

							<span className={styles.mobileBurger__address}>
								© {currentYear} {config.department}, {config.departmentAddress}
							</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default observer(MobileBurger)

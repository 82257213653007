import { useEffect, useMemo } from "react"
import { useIntl } from "react-intl"

import { ACCOUNT_TYPE } from "constants/exchange"
import useAccountType from "hooks/useAccountType"
import historyMessages from "messages/history"
import { useMst } from "models/Root"
import { queryVars } from "constants/query"

const useTradeHistory = ({ isShowAll = true }) => {
	const { formatMessage } = useIntl()
	const {
		global: { isAuthenticated },
		history: {
			loadTradeHistory,
			isTradeHistoryLoading,
			tradeHistoryList,
			setFilterTypeTradeHistory,
			filterTypeTradeHistory,
		},
		terminal: { pairSymbol, pairAmountPrecision, pairPricePrecision },
	} = useMst()
	const type = useAccountType()
	const params = useMemo(
		() => ({
			[queryVars.pair]: !isShowAll ? pairSymbol : undefined,
			[queryVars.page_size]: 50,
			[queryVars.wallet_type]: ACCOUNT_TYPE[type],
		}),
		[pairSymbol, type, isShowAll],
	)

	const listScheme = [
		{
			arrCount: 1,
			id: 123,
			arrNames: [
				{
					headline: formatMessage(historyMessages.active_orders_pair),
					width: 110,
					asociate: "name",
				},
			],
			isScroll: false,
		},
		{
			arrCount: 11,
			id: 223,
			isScroll: true,
			arrNames: [
				{
					headline: formatMessage(historyMessages.active_orders_side),
					width: 80,
					asociate: "direction",
				},
				{
					headline: "Trade Type",
					width: 100,
					asociate: "tradeType",
				},
				{
					headline: "Order Type",
					width: 80,
					asociate: "orderType",
				},
				{
					headline: "Filled Value",
					width: 168,
					asociate: "filledValue",
				},
				{
					headline: "Filled Price",
					width: 168,
					asociate: "filledPrice",
				},
				{
					headline: "Filled Q-ty",
					width: 125,
					asociate: "filledQty",
				},
				{
					headline: "Trading Fee",
					width: 168,
					asociate: "tradingFees",
				},
				{ headline: "Order Qty", width: 168, asociate: "orderQty" },
				{ headline: formatMessage(historyMessages.state), width: 110, asociate: "orderStatus" },
				{ headline: "Filled Time", width: 168, asociate: "date" },
				{ headline: "Transaction ID", width: 100, asociate: "tradeId" },
				{ headline: "Order ID", width: 100, asociate: "orderId" },
			],
		},
	]

	const listHeadline = listScheme.flatMap(item => item.arrNames)

	useEffect(() => {
		if (isAuthenticated && pairSymbol) {
			loadTradeHistory(params)
		}
	}, [isAuthenticated, pairSymbol, params, isShowAll])

	return {
		listScheme,
		listHeadline,
		list: tradeHistoryList,
		isAuthenticated,
		pairAmountPrecision,
		pairPricePrecision,
		isTradeHistoryLoading,
		filterTypeTradeHistory,
		setFilterTypeTradeHistory,
	}
}

export default useTradeHistory

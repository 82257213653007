import React, { FC } from "react"

const SelectCheck: FC = () => (
	<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M16.6666 5L7.49992 14.1667L3.33325 10"
			stroke="##4E55A2"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
)

export default SelectCheck

import React, { memo } from "react";

const Key = () => (
	<svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M12.5 1.667a5.833 5.833 0 00-5.782 6.617 12.148 12.148 0 01.053.428l-.008.008c-.04.045-.099.103-.203.208L2.3 13.187l-.03.03c-.112.112-.253.252-.358.424-.092.15-.159.312-.2.482-.047.196-.046.395-.046.554v1.516c0 .21 0 .415.014.589.016.19.053.415.168.641.16.314.415.569.728.729.226.115.451.152.642.167.173.015.378.014.588.014h2.027c.46 0 .833-.373.833-.833v-.833H7.5c.46 0 .833-.373.833-.834V15h.833a.833.833 0 00.59-.244l1.315-1.316c.105-.105.164-.163.209-.204l.008-.007.038.003c.085.008.202.024.39.05A5.833 5.833 0 1012.5 1.667zm0 5c.214 0 .426.08.589.244a.828.828 0 01.244.589.833.833 0 101.667 0c0-.639-.245-1.28-.733-1.768A2.495 2.495 0 0012.5 5a.833.833 0 000 1.667z"
			fill="#fff"
		/>
	</svg>
);

export default memo(Key);

import { types as t, Instance } from "mobx-state-tree"
import { TradeActionsEnum } from "types/exchange"

const MarginCurrencyStatusCurrency = t.model({
	brand_color: t.string,
	code: t.string,
	image_png: t.maybeNull(t.string),
	image_svg: t.maybeNull(t.string),
	is_fiat: t.boolean,
	name: t.string,
	precision: t.number,
})

const MarginCurrencyStatusPair = t.model({
	amount_precision: t.maybeNull(t.number),
	base_currency_code: t.maybeNull(t.string),
	name: t.maybeNull(t.string),
	price_precision: t.maybeNull(t.number),
	quote_currency_code: t.maybeNull(t.string),
	symbol: t.maybeNull(t.string),
})

const MarginCurrencyStatus = t.model({
	wallet_type: t.number,
	balance: t.number,
	borrowable: t.number,
	borrowed: t.number,
	currency: MarginCurrencyStatusCurrency,
	debt_mc: t.number,
	interest: t.number,
	interest_rate: t.number,
	leverage: t.number,
	margin_level: t.number,
	pair: t.maybeNull(MarginCurrencyStatusPair),
	price: t.number,
	reserve: t.number,
	total_mc: t.number,
	transferable: t.number,
})

export interface IMarginCurrencyStatus extends Instance<typeof MarginCurrencyStatus> {}

export const MarginCurrency = t
	.model({
		base: t.optional(t.maybeNull(MarginCurrencyStatus), null),
		quote: t.optional(t.maybeNull(MarginCurrencyStatus), null),
	})
	.views(self => ({
		get availableBase() {
			return self.base ? self.base.balance - self.base.reserve : 0
		},
		get availableQuote() {
			return self.quote ? self.quote.balance - self.quote.reserve : 0
		},
		get quoteCurrencyPrecision() {
			return self.quote ? self.quote.currency.precision : 8
		},
		get baseCurrencyPrecision() {
			return self.base ? self.base.currency.precision : 8
		},
		get quoteCurrencyCode() {
			return self.quote ? self.quote.currency.code : ""
		},
		get baseCurrencyCode() {
			return self.base ? self.base.currency.code : ""
		},
	}))
	.views(self => ({
		get availablePlusBorrowableBase() {
			// @ts-ignore
			return self.base ? self.availableBase + self.base.borrowable : 0
		},
		get availablePlusBorrowableQuote() {
			// @ts-ignore
			return self.quote ? self.availableQuote + self.quote.borrowable : 0
		},
	}))
	.views(self => ({
		getMarginCurrencyPrecisionBySide(side: TradeActionsEnum) {
			return side === TradeActionsEnum.BUY
				? self.quoteCurrencyPrecision
				: self.baseCurrencyPrecision
		},
		getAvailable(side: TradeActionsEnum) {
			return side === TradeActionsEnum.BUY ? self.availableQuote : self.availableBase
		},
		getAvailablePlusBorrowable(side: TradeActionsEnum) {
			return side === TradeActionsEnum.BUY
				? self.availablePlusBorrowableQuote
				: self.availablePlusBorrowableBase
		},
		getDebt(side: TradeActionsEnum) {
			return side === TradeActionsEnum.BUY
				? self.base
					? self.base.borrowed + self.base.interest
					: 0
				: self.quote
				? self.quote.borrowed + self.quote.interest
				: 0
		},
	}))

export interface IMarginCurrency extends Instance<typeof MarginCurrency> {}

import React from "react"
import Footer from "components/Footer"
import Cookies from "components/Cookies"
import Header from "components/NewUI/Header"

interface IProps {
	className?: string
	children?: React.ReactNode
}

const LandingLayout: React.FC<IProps> = ({ children, className }) => (
	<>
		<Header />
		{children}
		<Footer isLanding className={className} />
		<Cookies />
	</>
)

export default LandingLayout

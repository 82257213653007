import React from "react"
import { observer } from "mobx-react-lite"

import useTradeHistory from "hooks/newUI/terminal/useTradeHistory"
import styles from "components/NewUI/Terminal/Bottom/OrderHistory/index.module.scss"
import LimitMarketStopButtons from "../LimitMarketStopButtons"
import SignInSignUp from "../SignInSignUp"
import ListHeadline from "../ListHeadline"
import List from "./List"

const TradeHistory: React.FC<{ isShowAll?: boolean }> = ({ isShowAll = false }) => {
	const {
		isAuthenticated,
		filterTypeTradeHistory,
		setFilterTypeTradeHistory,
		listHeadline,
		listScheme,
		list,
		pairAmountPrecision,
		pairPricePrecision,
		isTradeHistoryLoading,
	} = useTradeHistory({ isShowAll })

	return (
		<div className={styles.tradeHistory}>
			<LimitMarketStopButtons
				id="TradeHistory"
				setFilterType={setFilterTypeTradeHistory}
				filterType={filterTypeTradeHistory}
				isAuthenticated={isAuthenticated}
			/>

			{isAuthenticated ? (
				<List
					list={list}
					listScheme={listScheme}
					pairAmountPrecision={pairAmountPrecision}
					pairPricePrecision={pairPricePrecision}
					isTradeHistoryLoading={isTradeHistoryLoading}
				/>
			) : (
				<>
					<ListHeadline list={listHeadline} listScheme={listScheme} />

					<SignInSignUp />
				</>
			)}
		</div>
	)
}

export default observer(TradeHistory)

/* eslint-disable */
import cn from "classnames";
import React from "react";
import QrCodeComponent from "qrcode.react";
import commonMessages from "messages/common";
import styles from "styles/components/HomePage.module.scss";
import EllipseImg from "assets/images/home_page/ellipse.png";
import Ellipse3Img from "assets/images/home_page/ellipse-3.png";
import PhonePng from "assets/images/home_page/phone.png";
import PhoneWebP from "assets/images/home_page/phone.webp";
import Apple from "assets/images/common/app-store.svg";
import Google from "assets/images/common/google-play.svg";
import useWindowSize from "hooks/useWindowSize";
import { useIntl } from "react-intl";
import messages from "messages/home_page";
import config from "helpers/config";

const HomePageMobile: React.FC = () => {
	const { mobile } = useWindowSize();
	return (
		<>
			{/* {!mobile && <HomePageMobileAppBackground />} */}
			<div className={styles.MobileApp}>
				<HomePageContentMobile />
				<HomePagePhone />
				{/* <Ellipse /> */}
				{/* <Ellipse3 /> */}
			</div>
		</>
	);
};

export default HomePageMobile;

const HomePagePhone = () => (
	<div className={styles.Phone}>
		<picture>
			<source srcSet={PhoneWebP} type="image/webp" />
			<source srcSet={PhonePng} type="image/png" />
			<img src={PhonePng} alt="Mobile App" width="300" height="609" />
		</picture>
	</div>
);

export const HomePageContentMobile: React.FC = () => {
	const { medium } = useWindowSize();
	const { formatMessage } = useIntl();
	return (
		<div className={cn(styles.Content, styles.Mobile)}>
			<h2 className={styles.Title}>{formatMessage(messages.mobile_title)}</h2>
			<div className={styles.Text}>{formatMessage(messages.mobile_content)}</div>
			{medium ? (
				<div className={styles.MobileLinks}>
					<a href={config.mobileAppAppStore} target="_blank" rel="noopener noreferrer">
						<img src={Apple} alt="App Store" width="180" height="60" />
					</a>
					<a href={config.mobileAppGooglePlay} target="_blank" rel="noopener noreferrer">
						<img src={Google} alt="Google Play" width="180" height="60" />
					</a>
				</div>
			) : (
				<div className={styles.Action}>
					<div className={styles.MobileAppDownload}>
						<div className={styles.QRCode}>
							<QrCodeComponent value={config.mobileDownloadLink} size={140} />
						</div>
						<div className={styles.QRCodeDesc}>
							<span>{formatMessage(commonMessages.scan_to_download)}</span>
							<span>{formatMessage(commonMessages.ios_and_android_apps)}</span>
							<div className={styles.MobileLinks}>
								<a href={config.mobileAppAppStore} target="_blank" rel="noopener noreferrer">
									<img src={Apple} alt="App Store" width="180" height="60" />
								</a>
								<a href={config.mobileAppGooglePlay} target="_blank" rel="noopener noreferrer">
									<img src={Google} alt="Google Play" width="180" height="60" />
								</a>
							</div>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

const Ellipse = () => <img className={styles.Ellipse} alt="" src={EllipseImg} />;
const Ellipse3 = () => <img className={styles.Ellipse3} alt="" src={Ellipse3Img} />;

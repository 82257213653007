import config from "./config";

export const getColorVariant = (value: number): string => {
	if (value > 0) {
		return "var(--color-green)";
	}
	if (value < 0) {
		return "var(--color-red)";
	}
	return "var(--color-primary)";
};

export const getPageTitle = (title: string) => `${title} | ${config.department}`;

export const smoothScroll = (element: HTMLElement, target: number, duration: number) => {
	const start = element.scrollLeft;
	const change = target - start;
	let startTime: number | null = null;
	let animationFrameId: number | null = null;

	const animateScroll = (currentTime: number) => {
		if (!startTime) {
			startTime = performance.now();
		}

		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		const progress = currentTime - startTime!;
		const newValue = linear(progress, start, change, duration);
		element.scrollLeft = newValue;
		if (progress < duration) {
			animationFrameId = window.requestAnimationFrame(animateScroll);
		} else if (animationFrameId) {
			window.cancelAnimationFrame(animationFrameId);
		}
	};

	if (animationFrameId) {
		window.cancelAnimationFrame(animationFrameId);
	}
	window.requestAnimationFrame(animateScroll);
};

export const easeInOutQuad = (t: number, b: number, c: number, d: number) => {
	t /= d / 2;
	if (t < 1) return (c / 2) * t * t + b;
	t--;
	return (-c / 2) * (t * (t - 2) - 1) + b;
};

export const linear = (t: number, b: number, c: number, d: number) => (t / d) * c + b;

const colors: Record<string, Record<string, string>> = {
	light: {
		primary: "#030303",
		background: "#fafafa",
		card: "#ffffff",
		blue: "#4E55A2",
		green: "#00b172",
		red: "#ff5f66",
		grid: "#e5e5e5",
		volumeRed: "rgba(255, 95, 102, 0.4)",
		volumeGreen: "rgba(32,178,108,0.4)",
		orderbookRedPrimary: "#ff5f66",
		orderbookGreenPrimary: "#20b26c",
	},
	dark: {
		primary: "#ffffff",
		background: "#000",
		card: "#101014",
		blue: "#1485ff",
		green: "#20b26c",
		red: "#ff5f66",
		grid: "#000",
		volumeRed: "rgba(255, 95, 102, 0.4)",
		volumeGreen: "rgba(32,178,108,0.4)",
		orderbookRedPrimary: "#ff5f66",
		orderbookGreenPrimary: "#20b26c",
	},
}

export default colors

import React, { useCallback } from "react";
import { observer } from "mobx-react-lite";

import styles from "components/NewUI/Terminal/FunctionalPanel/index.module.scss";

const Header: React.FC<{
	label: string;
	onClick: (nextType: string) => void;
	name: string;
	isActive: boolean;
}> = ({ label, onClick, name, isActive }) => {
	const handleClick = useCallback(() => onClick(name), [name]);

	return (
		<li className={styles.functionalPanel__headerItem}>
			<button
				onClick={handleClick}
				type="button"
				className={`${styles.functionalPanel__tabButton} ${
					isActive ? styles.functionalPanel__tabButton___active : ""
				}`}
			>
				<span>{label}</span>
			</button>
		</li>
	);
};

export default observer(Header);

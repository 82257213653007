import React, { memo } from "react"

const ArrowUp = () => (
	<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
		<path
			d="M15 12.5L10 7.5L5 12.5"
			stroke="#4E55A2"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
)

export default memo(ArrowUp)

import { types as t, Instance } from "mobx-state-tree";
import { AccountModelNamesEnum } from "types/models";

const LoanConditionsCurrency = t.model({
	brand_color: t.maybeNull(t.string),
	code: t.maybeNull(t.string),
	image_png: t.maybeNull(t.string),
	image_svg: t.maybeNull(t.string),
	is_fiat: t.maybeNull(t.boolean),
	name: t.maybeNull(t.string),
	precision: t.maybeNull(t.number),
});

export const LoanConditions = t.model(AccountModelNamesEnum.LOAN_CONDITIONS, {
	currency: t.optional(t.maybeNull(LoanConditionsCurrency), null),
	min_loan_amount: t.maybeNull(t.number),
	max_loan_amount: t.maybeNull(t.number),
	interest_rate: t.maybeNull(t.number),
});

export interface ILoanConditions extends Instance<typeof LoanConditions> {}

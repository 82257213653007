import React, { memo } from "react";

const FormBottomArrow = () => (
	<svg width={6} height={10} fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M2.705 8.628a.417.417 0 00.59 0l2.5-2.5a.417.417 0 00-.59-.59l-1.788 1.79V1.666a.417.417 0 10-.834 0v5.66L.794 5.54a.417.417 0 00-.589.589l2.5 2.5z"
			fill="#fff"
		/>
	</svg>
);

export default memo(FormBottomArrow);

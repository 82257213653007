import React, { memo } from "react"

const LanguageCheck = () => (
	<svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M17.256 4.41a.833.833 0 010 1.18l-9.167 9.166a.833.833 0 01-1.178 0l-4.167-4.167a.833.833 0 111.179-1.178L7.5 12.988l8.577-8.577a.833.833 0 011.179 0z"
			fill="#4E55A2"
		/>
	</svg>
)

export default memo(LanguageCheck)

import { useCallback, useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"

import { useMst } from "models/Root"
import { useOnClickOutside } from "hooks/useOnClickOutside"
import useScrollArrows from "hooks/newUI/terminal/useScrollArrows"
import { URL_VARS } from "constants/routing"
import { queryVars } from "constants/query"
import useLocalStorage from "hooks/useLocalStorage"
import { FAVORITE_FILTER_TYPE_CACHE_KEY } from "utils/cacheKeys"
import { FavoriteFilterTypeEnum } from "types/exchange"

const useFavorites = () => {
	const navigate = useNavigate()
	const [isOpenDots, setIsOpenDots] = useState(false)
	const dropdownRef = useRef<HTMLDivElement | null>(null)
	const {
		terminal: { handleOpenSearch },
		tickers: {
			favoriteList,
			isLoading,
			setFavoriteFilterType,
			favoriteFilterType,
			filter: { tradeType },
		},
		global: { locale, isAuthenticated },
	} = useMst()
	const {
		ref,
		handleArrowLeftClick,
		showArrows,
		showLeftArrow,
		handleArrowRightClick,
		showRightArrow,
	} = useScrollArrows([favoriteList.length, favoriteFilterType], true, false)

	const [favotiteFilterTypeState, setFavotiteFilterTypeState] = useLocalStorage(
		FAVORITE_FILTER_TYPE_CACHE_KEY,
		FavoriteFilterTypeEnum.PERCENT,
	)

	const closeOpenDots = () => setIsOpenDots(false)

	useOnClickOutside(dropdownRef, closeOpenDots)

	const handleLinkClick = (symbol: string) => {
		navigate(`/${locale}/${URL_VARS.TRADE}/${symbol}?${queryVars.type}=${tradeType}`)
		window.location.reload()
	}

	const toggleOpenDots = useCallback(() => setIsOpenDots(!isOpenDots), [isOpenDots])

	const openSearchAction = () => {
		handleOpenSearch(true)
	}

	const setFavoriteAction = (favorite: FavoriteFilterTypeEnum) => {
		setFavotiteFilterTypeState(favorite)
		setFavoriteFilterType(favorite)
		closeOpenDots()
	}

	const setFavoriteFilterTypePercentAction = () => setFavoriteAction(FavoriteFilterTypeEnum.PERCENT)

	const setFavoriteFilterTypeValueAction = () => setFavoriteAction(FavoriteFilterTypeEnum.VALUE)

	const setFavoriteFilterTypePercentValueAction = () =>
		setFavoriteAction(FavoriteFilterTypeEnum.PERCENT_VALUE)

	useEffect(() => {
		setFavoriteFilterType(favotiteFilterTypeState)
	}, [])

	return {
		ref,
		handleArrowLeftClick,
		openSearchAction,
		toggleOpenDots,
		dropdownRef,
		isOpenDots,
		showArrows,
		showLeftArrow,
		handleArrowRightClick,
		showRightArrow,
		isAuthenticated,
		favoriteList,
		handleLinkClick,
		isLoading,
		setFavoriteFilterTypePercentValueAction,
		setFavoriteFilterTypeValueAction,
		setFavoriteFilterTypePercentAction,
		favoriteFilterType,
		FavoriteFilterTypeEnum,
	}
}

export default useFavorites

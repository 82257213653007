import React, { useCallback } from "react";
import { observer } from "mobx-react-lite";
import { useIntl } from "react-intl";
import AutoSizer, { Size } from "react-virtualized-auto-sizer";
import { ListChildComponentProps, FixedSizeList as List } from "react-window";
import Skeleton from "react-loading-skeleton";

import { useMst } from "models/Root";
import exchangeMessages from "messages/exchange";
import Item from "./Item";
import styles from "./index.module.scss";
import Headline from "./Headline";

const RecentTrades: React.FC = () => {
	const { formatMessage } = useIntl();
	const {
		terminal: {
			recentTrades,
			isLoaded,
			baseCurrencyCode,
			quoteCurrencyCode,
			pairAmountPrecision,
			pairPricePrecision,
		},
	} = useMst();

	const Row = useCallback(
		({ index, style }: ListChildComponentProps) => (
			<div style={style}>
				<Item
					pricePrecision={pairPricePrecision}
					amountPrecision={pairAmountPrecision}
					price={recentTrades[index].price}
					amount={recentTrades[index].amount}
					time={recentTrades[index].date}
					isBuy={recentTrades[index].type === 2}
				/>
			</div>
		),
		[recentTrades, pairPricePrecision, pairAmountPrecision],
	);

	const SkeletonRow = () => <Skeleton height={28} borderRadius={0} />;

	return (
		<section className={styles.recentTrades}>
			<h2 className="visually-hidden">{formatMessage(exchangeMessages.recent_trades)}</h2>

			<div className={styles.recentTrades__header}>
				<button className={styles.recentTrades__headerTabButton} type="button">
					<span>{formatMessage(exchangeMessages.recent_trades)}</span>
				</button>
			</div>

			<div className={styles.recentTrades__container}>
				<Headline quoteCurrencyCode={quoteCurrencyCode} baseCurrencyCode={baseCurrencyCode} />

				<div className={styles.recentTrades__list}>
					<AutoSizer>
						{({ height, width }: Size) => (
							<List
								height={height}
								itemCount={isLoaded ? recentTrades.length : 30}
								itemSize={28}
								width={width}
							>
								{isLoaded ? Row : SkeletonRow}
							</List>
						)}
					</AutoSizer>
				</div>
			</div>
		</section>
	);
};

export default observer(RecentTrades);

import React, { useEffect } from "react"
import { observer } from "mobx-react-lite"
import { useIntl } from "react-intl"
import { useNavigate, useLocation } from "react-router-dom"

import styles from "components/NewUI/Terminal/FunctionalPanel/index.module.scss"
// import Dots from "assets/icons/terminal/Dots"
import { TradeTypeEnum } from "types/exchange"
import exchangeMessages from "messages/exchange"
import { queryVars } from "constants/query"
import useAccountType from "hooks/useAccountType"
import { useMst } from "models/Root"
import Item from "./Item"

const Header: React.FC<{
	tradeType: TradeTypeEnum
	setTradeType: (nextTradeType: TradeTypeEnum) => void
}> = ({ tradeType, setTradeType }) => {
	const { formatMessage } = useIntl()
	// URL type
	const type = useAccountType()
	const {
		terminal: { marginLeverage, tradeForm },
		history: { setCurrentOpenTab, currentOpenTab },
		render: { margin },
	} = useMst()
	const navigate = useNavigate()
	const location = useLocation()

	// const dotsButtonAction = () => {
	// 	// TODO: action for dots button FUNCTIONAL PANEL TABS
	// }

	const handleTradeTypeChange = (nextType: string): void => {
		// Set new type to URL. We don't need to use the function filter.setTradeType(nextType), because there is a useEffect below that responds to URL changes.
		navigate(`${location.pathname}?${queryVars.type}=${nextType}`)
	}

	useEffect(() => {
		// Opens the required tab, depending on the 'type' specified in the URL.
		setTradeType(type as unknown as TradeTypeEnum)
		if (currentOpenTab !== "OpenOrdersTab") setCurrentOpenTab("OpenOrdersTab")
	}, [type])

	useEffect(() => {
		if (marginLeverage === 0 && location.search !== `?${queryVars.type}=${TradeTypeEnum.SPOT}`) {
			setTradeType(TradeTypeEnum.SPOT)
			navigate(`${location.pathname}?${queryVars.type}=${TradeTypeEnum.SPOT}`)
		}
	}, [marginLeverage])

	return (
		<div className={styles.functionalPanel__header}>
			<ul className={styles.functionalPanel__headerList}>
				<Item
					label={formatMessage(exchangeMessages.spot)}
					onClick={handleTradeTypeChange}
					isActive={tradeType === TradeTypeEnum.SPOT}
					name={TradeTypeEnum.SPOT}
				/>

				{marginLeverage && marginLeverage !== 0 && margin ? (
					<Item
						label={`${formatMessage(exchangeMessages.cross_margin)} ${tradeForm.marginLvl}X`}
						onClick={handleTradeTypeChange}
						isActive={tradeType === TradeTypeEnum.CROSS}
						name={TradeTypeEnum.CROSS}
					/>
				) : null}
			</ul>

			{/*<button*/}
			{/*	type="button"*/}
			{/*	onClick={dotsButtonAction}*/}
			{/*	aria-label="Functional panel dots button"*/}
			{/*	className={styles.functionalPanel__dots}*/}
			{/*>*/}
			{/*	<Dots />*/}
			{/*</button>*/}
		</div>
	)
}

export default observer(Header)

import React, { memo } from "react";

const HiddenEye = () => (
	<svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M13.488 16.14l-2.018-2.018A4.375 4.375 0 015.878 8.53L3.301 5.953C1.173 7.849 0 10 0 10s3.75 6.875 10 6.875c1.27 0 2.435-.284 3.488-.735zM6.512 3.86A8.785 8.785 0 0110 3.125C16.25 3.125 20 10 20 10s-1.173 2.151-3.301 4.047l-2.577-2.577A4.375 4.375 0 008.53 5.878L6.512 3.86z"
			fill="#EBEBF5"
			fillOpacity={0.6}
		/>
		<path
			d="M6.906 9.558a3.125 3.125 0 003.536 3.536L6.906 9.558zM13.094 10.442L9.558 6.906a3.125 3.125 0 013.536 3.536zM17.058 17.942l-15-15 .884-.884 15 15-.884.884z"
			fill="#EBEBF5"
			fillOpacity={0.6}
		/>
	</svg>
);

export default memo(HiddenEye);

import React, { memo } from "react";

const Cross = () => (
	<svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M12.472 4.472a.667.667 0 00-.943-.943L8 7.057 4.472 3.53a.667.667 0 10-.943.943L7.057 8 3.53 11.53a.667.667 0 10.943.943L8 8.943l3.529 3.529a.667.667 0 00.943-.943L8.943 8l3.529-3.528z"
			fill="#EBEBF5"
			fillOpacity={0.6}
		/>
	</svg>
);

export default memo(Cross);

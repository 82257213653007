import React, { useEffect } from "react"
import { observer } from "mobx-react-lite"
import { useIntl } from "react-intl"
import cookies from "js-cookie"

import { routes } from "constants/routing"
import InternalLink from "components/InternalLink"
import { queryVars } from "constants/query"
import useParamQuery from "hooks/useSearchQuery"
import { REFERRAL_CODE_KEY } from "utils/constants"
import commonMessages from "messages/common"
import { useMst } from "models/Root"
import Input from "../Input"
import ThirdParty from "../ThirdParty"
import Header from "../Header"
import Button from "../Button"
import PasswordRules from "../PasswordRules"
import CheckBox from "../../CheckBox"
import styles from "./RegForm.module.scss"

const RegForm: React.FC = () => {
	const { formatMessage } = useIntl()
	const {
		register: {
			setEmail,
			isConditionsAccepted,
			setIsConditionAccepted,
			email,
			setInviteCode,
			setPassword,
			password,
			resetRegisterState,
			setPersonal,
			setJuridical,
			inviteCode,
			onSubmitFirstStep,
			isPersonal,
			isPassErrorLength,
			isPassSpecialChar,
			isPassLowercase,
			isPassUppercase,
			isLoading,
			errors,
		},
	} = useMst()
	const query = useParamQuery()
	const referralCode = query.get(REFERRAL_CODE_KEY) || cookies.get(REFERRAL_CODE_KEY)

	const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault()

		onSubmitFirstStep(formatMessage).then(() => null)
	}

	useEffect(() => {
		setEmail(query.get(queryVars.email) ?? "")

		if (referralCode) {
			setInviteCode(referralCode)
		}

		return () => resetRegisterState()
	}, [])

	return (
		<div className={styles.regForm}>
			<Header
				headline="Create Account"
				description="Already have an account?"
				link={
					<InternalLink className={styles.regForm__descriptionLink} to={routes.login.root}>
						Login
					</InternalLink>
				}
			/>

			<div className={styles.regForm__wrapper}>
				<form onSubmit={onSubmit}>
					<div className={styles.regForm__emailSubWrapper}>
						<div className={styles.regForm__toggle}>
							<button
								type="button"
								onClick={setPersonal}
								className={`${styles.regForm__toggleButton} ${
									isPersonal ? styles.regForm__toggleButton___active : ""
								}`}
							>
								Personal
							</button>

							<button
								type="button"
								onClick={setJuridical}
								className={`${styles.regForm__toggleButton} ${
									!isPersonal ? styles.regForm__toggleButton___active : ""
								}`}
							>
								Juridical
							</button>
						</div>

						<div className={styles.regForm__inputWrapper}>
							<Input
								id="reg-email"
								label="Email"
								handleChange={setEmail}
								error={errors.email}
								value={email}
								autoComplete="on"
								autoFocus
								placeholder="Enter your email"
							/>
						</div>
					</div>

					<div className={styles.regForm__inputWrapper}>
						<Input
							id="reg-password"
							label="Password"
							autoComplete="on"
							value={password}
							handleChange={setPassword}
							error={errors.password}
							type="password"
							placeholder="Enter your password"
						/>
					</div>

					<div
						className={`${styles.regForm__passwordRulesWrapper} ${
							password.length >= 1 ? styles.regForm__passwordRulesWrapper___open : ""
						}`}
					>
						<PasswordRules
							isPassErrorLength={isPassErrorLength}
							isPassSpecialChar={isPassSpecialChar}
							isPassLowercase={isPassLowercase}
							isPassUppercase={isPassUppercase}
						/>
					</div>

					<div className={styles.regForm__inputWrapper}>
						<Input
							id="reg-code"
							label="Referral code"
							placeholder="Enter code"
							value={inviteCode}
							handleChange={setInviteCode}
							error={errors.invite}
							isCollapsible
							collapsibleDefault={false}
						/>
					</div>

					<div className={styles.regForm__agreementWrapper}>
						<CheckBox
							name="agreement"
							checked={isConditionsAccepted}
							errors={errors.isConditionsAccepted}
							onChange={setIsConditionAccepted}
						>
							{formatMessage(commonMessages.terms_conditions, {
								privacy_policy: (
									<InternalLink to={routes.privacyPolicy}>
										{formatMessage(commonMessages.privacy_policy_context)}
									</InternalLink>
								),
								terms_of_use: (
									<InternalLink to={routes.termsOfUse}>
										{formatMessage(commonMessages.terms_of_use_context)}
									</InternalLink>
								),
							})}
						</CheckBox>
					</div>

					<div className={styles.regForm__buttonWrapper}>
						<Button isLoading={isLoading} type="submit">
							Continue
						</Button>
					</div>
				</form>

				<ThirdParty lineText="or continue with" />
			</div>
		</div>
	)
}

export default observer(RegForm)

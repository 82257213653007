import { observable } from "mobx";

export interface IRecentTrade {
	id: number;
	amount: number;
	date: number;
	price: number;
	type: number;
	symbol: string;
}

export const trades = observable({
	list: [] as IRecentTrade[],
	init(trades: IRecentTrade[]) {
		this.list = trades;
	},
	add(trade: IRecentTrade) {
		this.list.unshift(trade);
	},
	get recentTrade() {
		return this.list.length > 0 ? this.list[0] : null;
	},
});

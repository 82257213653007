import React, { memo } from "react";

import styles from "components/NewUI/Terminal/FunctionalPanel/AvailableBalance/index.module.scss";

const SpotBalance: React.FC<{
	currencyCodeBySide: string;
	mainBalance: string | undefined;
}> = ({ currencyCodeBySide, mainBalance }) => (
	<>
		<span>{mainBalance || "--"}</span>

		<span className={styles.availableBalance__currency}>{currencyCodeBySide || "--"}</span>
	</>
);

export default memo(SpotBalance);

import React, { FC, memo } from "react"

import styles from "components/NewUI/Header/Navigation/index.module.scss"
import NavArrow from "assets/icons/header/NavArrow"
import { HeaderNavItemTypes } from "types/header"
import InternalLink from "components/InternalLink"
import InnerItem from "./InnerItem"

const NavItem: FC<HeaderNavItemTypes> = ({
	children,
	ariaLabel,
	onClick,
	Icon,
	formatMessage,
	linkTo,
	id,
	list,
	defaultActive,
	disabled,
	count,
}) => {
	// This element is used for login and registration.
	if (linkTo && !disabled) {
		return (
			<li className={styles.nav__item}>
				<InternalLink
					className={`${styles.nav__buttonSign} ${
						id === "signUp" ? styles.nav__buttonSign___up : ""
					}`}
					to={linkTo}
				>
					{formatMessage || "Item"}
				</InternalLink>
			</li>
		)
	}

	// This element is used for navigation list.
	return (
		<li
			className={`${styles.nav__item} ${
				list?.length === 0 || disabled ? styles.nav__item___disabled : ""
			}`}
		>
			<button
				type="button"
				className={styles.nav__button}
				onClick={onClick}
				// aria-label is currently used only to improve the score in Lighthouse.
				aria-label={ariaLabel || undefined}
			>
				{Icon || (
					<>
						<span className={styles.nav__buttonText}>{formatMessage || "Item"}</span>

						<span className={styles.nav__buttonArrow}>
							<NavArrow />
						</span>
					</>
				)}

				{count && count > 0 ? (
					<span key={count} className={styles.nav__buttonCount}>
						{count > 99 ? ">99" : count}
					</span>
				) : null}
			</button>

			{list ? (
				<ul
					className={`${styles.nav__firstList} ${
						defaultActive ? styles.nav__firstList___active : ""
					}`}
				>
					{list
						.filter(({ isShow }) => isShow === undefined || isShow)
						.map(({ link, title, subtitle, SecondLvl, defaultActive, disabled }) => (
							<InnerItem
								key={`innerItem-${title}`}
								link={link}
								title={title}
								subtitle={subtitle}
								SecondLvl={SecondLvl}
								defaultActive={defaultActive}
								disabled={disabled}
							/>
						))}
				</ul>
			) : (
				<>
					{/* 
						In the desktop version, children will appear when hovering over .nav__item. 
						This is necessary for optimizing list performance, as well as for a smooth animation. 
					*/}
					{children}
				</>
			)}
		</li>
	)
}

export default memo(NavItem)

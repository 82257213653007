import React, { memo } from "react";

const NotificationArrow = () => (
	<svg width={14} height={14} fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M4.837 3.088a.583.583 0 01.825 0l3.5 3.5a.583.583 0 010 .825l-3.5 3.5a.583.583 0 11-.825-.825L7.924 7 4.837 3.913a.583.583 0 010-.825z"
			fill="#fff"
		/>
	</svg>
);

export default memo(NotificationArrow);

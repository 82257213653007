import React from "react"
import { observer } from "mobx-react-lite"

import useOpenOrder from "hooks/newUI/terminal/useOpenOrders"
import styles from "components/NewUI/Terminal/Bottom/OpenOrders/index.module.scss"
import LimitMarketStopButtons from "../LimitMarketStopButtons"
import List from "./List"
import ListHeadline from "../ListHeadline"
import SignInSignUp from "../SignInSignUp"

const OpenOrders: React.FC<{ isShowAll?: boolean; isOpen: boolean }> = ({
	isShowAll = false,
	isOpen,
}) => {
	const {
		isAuthenticated,
		listScheme,
		list,
		listHeadline,
		pairAmountPrecision,
		pairPricePrecision,
		setFilterTypeOpenedOrders,
		filterTypeOpenedOrders,
		isOpenedOrdersLoading,
	} = useOpenOrder({ isShowAll })

	return (
		<div className={`${styles.openOrders} ${!isOpen ? styles.openOrders__disabled : ""}`}>
			<LimitMarketStopButtons
				id="OpenOrders"
				setFilterType={setFilterTypeOpenedOrders}
				filterType={filterTypeOpenedOrders}
				isAuthenticated={isAuthenticated}
			/>

			{isAuthenticated ? (
				<List
					list={list}
					listScheme={listScheme}
					pairAmountPrecision={pairAmountPrecision}
					pairPricePrecision={pairPricePrecision}
					isOpenedOrdersLoading={isOpenedOrdersLoading}
				/>
			) : (
				<>
					<ListHeadline list={listHeadline} listScheme={listScheme} />

					<SignInSignUp />
				</>
			)}
		</div>
	)
}

export default observer(OpenOrders)
